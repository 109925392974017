import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

import ButtonsOrMenu from "../Buttons/ButtonsOrMenu.js";
import RZnavList from "./RZnavList.js";
import PZnavList from "./PZnavList.js";
import MySnackbar from "./MySnackbar.js";
import DeleteConfirm from "../Dialogs/DeleteConfirm.js";

import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Input,
  Snackbar,
} from "@material-ui/core/";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
  ExitToApp as ExitIcon,
  TrendingUp as TrendingIcon,
  TableChart as TableIcon,
  FormatListNumbered as SeznamIcon,
  Receipt as ProtokolIcon,
  Contacts as AdresarIcon,
  SettingsRemote as PrepocetIcon,
  Code as LogsIcon,
  NextWeek as ZZIcon,
} from "@material-ui/icons";
import { fade } from "@material-ui/core/styles/colorManipulator";

import { ROUTE_PATHS } from "../../constants/routePaths";
import { ERROR } from "../../services/redux/constants.js";
import {
  hasErrors,
  getError,
  appDrawerShownFromState,
  getVerzeFromState,
  getLastMessage,
} from "../../services/redux/rootReducer.js";
import { logout } from "../../services/redux/Login/Login.thunks.js";
import { logError } from "../../services/redux/Logging/Logging.thunks.js";
import { showAppDrawer } from "../../services/redux/Settings/Settings.thunks.js";

import buildVersion from "../../constants/buildVersion.json";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 300,
    },
  },
  buttonsMin: {
    minWidth: "48px",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperPersistent: {
    position: "relative",
    width: drawerWidth,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("xs")]: {
      width: theme.spacing.unit * 9,
    },
  },
  drawerPaperHidden: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto",
  },
  contentWithoutPadding: {
    padding: 0,
  },
  contentPersistent: {
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  wrap: {
    whiteSpace: "pre-wrep",
  },
  scrollableDrawer: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 65px)",
    display: "flex",
    flexDirection: "column",
  },
  versionInfo: {
    fontSize: "75%",
    color: theme.palette.text.hint,
  },
});

class AppBarWithDrawer extends Component {
  state = {
    snackbarOpen: false,
    onSearch: null,
    searchTerm: "",
    buttons: [],
    askWhenNavigatingAway: false,
    confirmRedirectDialogOpen: false,
  };

  constructor(props) {
    super(props);
    this.mainRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.HasErrors) {
      if (nextProps.Error.errorType === ERROR.UNAUTHORIZED && nextProps.location.pathname !== "/") {
        this.props.history.push("/");
      }
    }

    if (this.props.LastMessage.timestamp !== nextProps.LastMessage.timestamp) {
      this.setState({ snackbarOpen: true });
    }

    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.scrollContentTo(0, 0);
    }
  }

  handleDrawerOpen = () => {
    this.props.showAppDrawer(true);
  };

  handleDrawerClose = () => {
    this.props.showAppDrawer(false);
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  redirect = (path, removeSearch) => () => {
    if (this.state.askWhenNavigatingAway) {
      this.setState({ confirmRedirectDialogOpen: { path, removeSearch } });
    } else {
      this.doRedirect(path, removeSearch);
    }
  };

  confirmRedirect = (shouldRedirect, extraData) => {
    if (shouldRedirect) {
      this.setState({ askWhenNavigatingAway: false });
      this.doRedirect(extraData.path, extraData.removeSearch);
    }
    this.setState({ confirmRedirectDialogOpen: false });
  };

  doRedirect = (path, removeSearch) => {
    if (!isWidthUp("sm", this.props.width) || this.props.Verze.alwaysCloseSidebar === "1") {
      this.handleDrawerClose();
    }
    if (removeSearch) {
      document.getElementById("searchBar").value = "";
    }
    this.props.history.push(path);
  };

  logout = () => {
    this.props.logout();
  };

  setAppBarOptions = ({ onSearch, searchTerm, buttons, noPadding }) => {
    this.setState({
      onSearch,
      searchTerm: searchTerm || "",
      buttons: buttons || [],
      noPadding: noPadding || false,
    });
  };

  scrollContentTo = (x, y) => {
    if (this.mainRef.current) {
      this.mainRef.current.scrollTo(x, y);
    }
  };

  setAskWhenNavigatingAway = (shouldAsk) => {
    this.setState({ askWhenNavigatingAway: shouldAsk });
  };

  handleSearch = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleSearchKey = (event) => {
    if (event.which === 13) {
      this.state.onSearch(event.target.value);
    }
  };

  render() {
    const { classes } = this.props;

    const drawerAllwaysVisible = isWidthUp("sm", this.props.width);

    if (this.props.location.pathname === "/") {
      return this.props.children;
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, this.props.DrawerOpen && classes.appBarShift)}
          >
            <Toolbar disableGutters={!this.props.DrawerOpen} className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, this.props.DrawerOpen && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                The Chimney Sweeper's App
              </Typography>
              <div className={classes.grow} />
              {!!this.state.onSearch && (
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <Input
                    placeholder="Hledej…"
                    disableUnderline
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onKeyPress={this.handleSearchKey}
                    value={this.state.searchTerm}
                    onChange={this.handleSearch}
                    id="searchBar"
                  />
                </div>
              )}
              <div className={classes.grow} />
              <div className={classes.buttonsMin}>
                <ButtonsOrMenu buttons={this.state.buttons} maxShow={2} />
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant={drawerAllwaysVisible ? "permanent" : "persistent"}
            classes={{
              paper: classNames(
                drawerAllwaysVisible && classes.drawerPaper,
                !this.props.DrawerOpen && drawerAllwaysVisible && classes.drawerPaperClose,
                !drawerAllwaysVisible && classes.drawerPaperPersistent
              ),
            }}
            open={this.props.DrawerOpen}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <div className={classes.scrollableDrawer}>
              <List>
                <RZnavList redirect={this.redirect} pathname={this.props.location.pathname} />
                <PZnavList redirect={this.redirect} pathname={this.props.location.pathname} />
                {this.props.Verze.tabulka === "1" && (
                  <ListItem
                    button
                    onClick={this.redirect("/t/list")}
                    selected={this.props.location.pathname.indexOf(ROUTE_PATHS.T) !== -1}
                  >
                    <ListItemIcon>
                      <TableIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Tabulky" />
                  </ListItem>
                )}
                {this.props.Verze.seznam === "1" && (
                  <ListItem
                    button
                    onClick={this.redirect("/s/list")}
                    selected={this.props.location.pathname.indexOf(ROUTE_PATHS.S) !== -1}
                  >
                    <ListItemIcon>
                      <SeznamIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Seznam" />
                  </ListItem>
                )}
                {this.props.Verze.protokol === "1" && (
                  <ListItem
                    button
                    onClick={this.redirect("/pr/list")}
                    selected={this.props.location.pathname.indexOf(ROUTE_PATHS.PR) !== -1}
                  >
                    <ListItemIcon>
                      <ProtokolIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Protokoly" />
                  </ListItem>
                )}
                {this.props.Verze.adresar === "1" && (
                  <ListItem
                    button
                    onClick={this.redirect("/a/list")}
                    selected={this.props.location.pathname.indexOf(ROUTE_PATHS.A) !== -1}
                  >
                    <ListItemIcon>
                      <AdresarIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Adresář" />
                  </ListItem>
                )}
                {this.props.Verze.zz === "1" && (
                  <ListItem
                    button
                    onClick={this.redirect("/zz/list")}
                    selected={this.props.location.pathname.indexOf(ROUTE_PATHS.ZZ) !== -1}
                  >
                    <ListItemIcon>
                      <ZZIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Inf. o zdrojích znečišťování" />
                  </ListItem>
                )}
              </List>
              <Divider />
              <List>
                <ListItem
                  button
                  onClick={this.redirect("/stats")}
                  selected={this.props.location.pathname.indexOf(ROUTE_PATHS.STATS) !== -1}
                >
                  <ListItemIcon>
                    <TrendingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Statistiky" />
                </ListItem>
                {this.props.Verze.prepocetSpalin === "1" && (
                  <ListItem
                    button
                    onClick={this.redirect("/prepocet")}
                    selected={this.props.location.pathname.indexOf(ROUTE_PATHS.PREPOCET_SPALIN) !== -1}
                  >
                    <ListItemIcon>
                      <PrepocetIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Přepočet spalin" />
                  </ListItem>
                )}
                {this.props.isAdmin && (
                  <ListItem
                    button
                    onClick={this.redirect("/logs")}
                    selected={this.props.location.pathname.indexOf(ROUTE_PATHS.LOGS) !== -1}
                  >
                    <ListItemIcon>
                      <LogsIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Logs" />
                  </ListItem>
                )}
                <ListItem
                  button
                  onClick={this.redirect("/settings")}
                  selected={this.props.location.pathname.indexOf(ROUTE_PATHS.SETTINGS) !== -1}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Nastavení" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button onClick={this.logout}>
                  <ListItemIcon>
                    <ExitIcon />
                  </ListItemIcon>
                  <ListItemText primary="Odhlásit" />
                </ListItem>
              </List>
              {this.props.DrawerOpen && (
                <React.Fragment>
                  <div className={classes.grow} />
                  <List>
                    <ListItem className={classes.versionInfo}>
                      Verze {buildVersion.version}.{buildVersion.buildNumber}, DB {this.props.Verze.verze}
                    </ListItem>
                  </List>
                </React.Fragment>
              )}
            </div>
          </Drawer>
          <main
            className={classNames(
              classes.content,
              !drawerAllwaysVisible && classes.contentPersistent,
              //!drawerAllwaysVisible && this.props.DrawerOpen && classes.contentShift,
              (!drawerAllwaysVisible || this.state.noPadding) && classes.contentWithoutPadding
            )}
            ref={this.mainRef}
          >
            <div className={classes.appBarSpacer} />
            {React.cloneElement(this.props.children, {
              setAppBarOptions: this.setAppBarOptions,
              scrollContentTo: this.scrollContentTo,
              setAskWhenNavigatingAway: this.setAskWhenNavigatingAway,
              askWhenNavigatingAway: this.state.askWhenNavigatingAway,
            })}
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.snackbarOpen}
              autoHideDuration={6000}
              onClose={this.handleSnackbarClose}
            >
              <MySnackbar
                onClose={this.handleSnackbarClose}
                variant={
                  this.props.LastMessage.errorType === ERROR.WARNING
                    ? "warning"
                    : this.props.LastMessage.errorType === ERROR.INFO
                      ? "info"
                      : "error"
                }
                message={
                  <div>
                    <b>{this.props.LastMessage.errorType}</b>
                    {this.props.LastMessage.errorWhen && <span>: {this.props.LastMessage.errorWhen}</span>}
                    {this.props.LastMessage.errors &&
                      this.props.LastMessage.errors.join && (
                        <div className={classes.wrap}>{this.props.LastMessage.errors.join("\n")}</div>
                      )}
                  </div>
                }
              />
            </Snackbar>
          </main>
        </div>
        <DeleteConfirm
          open={!!this.state.confirmRedirectDialogOpen}
          extraData={this.state.confirmRedirectDialogOpen}
          onClose={this.confirmRedirect}
          label="Opravdu chcete odejít z editace bez uložení?"
        />
      </React.Fragment>
    );
  }
}

AppBarWithDrawer.propTypes = {
  buttons: PropTypes.array,
  onSearch: PropTypes.func,
  search: PropTypes.string,
  noPadding: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  HasErrors: hasErrors(state),
  Error: getError(state),
  DrawerOpen: appDrawerShownFromState(state),
  Verze: getVerzeFromState(state),
  LastMessage: getLastMessage(state),
});

const actionCreators = {
  logError,
  logout,
  showAppDrawer,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withWidth()(withStyles(styles)(AppBarWithDrawer))));
