import { PZ_ACTION_TYPES } from "./PZList.actions.js";
import { DATA_STATE } from "../constants.js";
import { getFailStateForReducer } from "../../../utils/defaults.js";

export const getData = (state) => state.data;
export const getListData = (state) => state.dataList;
export const getUpdatedData = (state) => state.updatedData;
export const getRemoveData = (state) => state.removeData;
export const getCopiedData = (state) => state.copiedData;
export const getDataState = (state) => state.dataState;
export const getImageNewData = (state) => state.newImageData;
export const getImageDataState = (state) => state.imageDataState;
export const getDataError = (state) => {
  return { errorType: state.errorType, errors: state.errors, errorWhen: state.errorWhen };
};

export const getPagination = (state) => state.pagination;
export const getOpenPlus = (state) => state.openPlus;

const INITIAL_STATE = {
  dataState: DATA_STATE.NOT_INITIALIZED,
  pagination: {
    page: 0,
    sort: "cislo",
    sortOrder: "desc",
    _sort: ["-rok", "-cislo", "cislo_sec"],
  },
  openPlus: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PZ_ACTION_TYPES.FETCHING_LIST:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case PZ_ACTION_TYPES.FETCH_LIST_FAILED:
      return getFailStateForReducer(state, action, PZ_ACTION_TYPES.FETCHING_LIST);

    case PZ_ACTION_TYPES.FETCH_LIST_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.INITIALIZED,
        dataList: action.data,
      };
    case PZ_ACTION_TYPES.FETCHING:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case PZ_ACTION_TYPES.FETCH_FAILED:
      return getFailStateForReducer(state, action, PZ_ACTION_TYPES.FETCHING);

    case PZ_ACTION_TYPES.FETCH_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.INITIALIZED,
        data: action.data,
      };
    case PZ_ACTION_TYPES.UPDATING:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case PZ_ACTION_TYPES.UPDATE_FAILED:
      return getFailStateForReducer(state, action, PZ_ACTION_TYPES.UPDATING);

    case PZ_ACTION_TYPES.UPDATE_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_SUCCEEDED,
        updatedData: action.data,
      };
    case PZ_ACTION_TYPES.REMOVING:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case PZ_ACTION_TYPES.REMOVE_FAILED:
      return getFailStateForReducer(state, action, PZ_ACTION_TYPES.REMOVING);

    case PZ_ACTION_TYPES.REMOVE_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.REMOVE_SUCCEEDED,
      };
    case PZ_ACTION_TYPES.COPYING:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case PZ_ACTION_TYPES.COPY_FAILED:
      return getFailStateForReducer(state, action, PZ_ACTION_TYPES.COPYING);

    case PZ_ACTION_TYPES.COPY_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.COPY_SUCCEEDED,
        copiedData: action.data,
      };

    case PZ_ACTION_TYPES.PAGINATION:
      let newPagination = {
        page: action.page !== undefined ? action.page : (state.pagination || {}).page || 0,
        searchTerm: action.searchTerm !== undefined ? action.searchTerm : (state.pagination || {}).searchTerm,
        year: action.year !== undefined ? parseInt(action.year, 10) || action.year : (state.pagination || {}).year,
        sort: action.sort !== undefined ? action.sort : (state.pagination || {}).sort,
        sortOrder: action.sortOrder !== undefined ? action.sortOrder : (state.pagination || {}).sortOrder,
      };
      newPagination._sort = (newPagination.sortOrder === "desc" ? "-" : "") + newPagination.sort;
      if (newPagination.sort === "cislo") {
        newPagination._sort = ["-rok", newPagination._sort, "cislo_sec"];
      }
      if (newPagination.sort === "adresa") {
        newPagination._sort = [
          (newPagination.sortOrder === "desc" ? "-" : "") + "adresa",
          (newPagination.sortOrder === "desc" ? "-" : "") + "adresa_misto",
        ];
      }
      return {
        ...state,
        pagination: newPagination,
      };

    case PZ_ACTION_TYPES.OPEN_PLUS:
      let openPlus = state.openPlus;
      (action.data || []).forEach((item) => {
        openPlus[item.key] = item.value;
      });
      return {
        ...state,
        openPlus,
      };

    case PZ_ACTION_TYPES.ADDING_IMAGE:
      return {
        ...state,
        imageDataState: DATA_STATE.IN_PROGRESS,
      };
    case PZ_ACTION_TYPES.ADD_IMAGE_SUCCEEDED:
      return {
        ...state,
        imageDataState: DATA_STATE.UPLOAD_SUCCEEDED,
        newImageData: action.data,
      };
    case PZ_ACTION_TYPES.ADD_IMAGE_FAILED:
      return getFailStateForReducer(state, action, PZ_ACTION_TYPES.ADDING_IMAGE, "imageDataState");

    case PZ_ACTION_TYPES.REMOVING_IMAGE:
      return {
        ...state,
        imageDataState: DATA_STATE.IN_PROGRESS,
      };
    case PZ_ACTION_TYPES.REMOVE_IMAGE_FAILED:
      return getFailStateForReducer(state, action, PZ_ACTION_TYPES.REMOVING_IMAGE, "imageDataState");

    case PZ_ACTION_TYPES.REMOVE_IMAGE_SUCCEEDED:
      return {
        ...state,
        imageDataState: DATA_STATE.REMOVE_SUCCEEDED,
      };
    case PZ_ACTION_TYPES.UPDATING_IMAGE_ORDER:
      return {
        ...state,
        imageDataState: DATA_STATE.IN_PROGRESS,
      };
    case PZ_ACTION_TYPES.UPDATE_IMAGE_ORDER_FAILED:
      return getFailStateForReducer(state, action, PZ_ACTION_TYPES.UPDATING_IMAGE_ORDER, "imageDataState");

    case PZ_ACTION_TYPES.UPDATE_IMAGE_ORDER_SUCCEEDED:
      return {
        ...state,
        imageDataState: DATA_STATE.UPDATE_SUCCEEDED,
      };
    default:
      return state;
  }
}
