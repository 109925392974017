import { combineReducers } from "redux";
import loginReducer, * as fromLogin from "./Login/Login.reducer.js";
import rzReducer, * as fromRZ from "./RZList/RZList.reducer.js";
import pzReducer, * as fromPZ from "./PZList/PZList.reducer.js";
import tReducer, * as fromT from "./TList/TList.reducer.js";
import sReducer, * as fromS from "./SList/SList.reducer.js";
import prReducer, * as fromPR from "./PRList/PRList.reducer.js";
import aReducer, * as fromA from "./AList/AList.reducer.js";
import zzReducer, * as fromZZ from "./ZZList/ZZList.reducer.js";
import settingsReducer, * as fromSettings from "./Settings/Settings.reducer.js";
import loggingReducer, * as fromLogging from "./Logging/Logging.reducer.js";
import { DATA_STATE, ERROR } from "./constants.js";
import { PERSISTENCE_KEYS } from "../persistence/persistenceKeys.js";

export default combineReducers({
  login: loginReducer,
  rz: rzReducer,
  pz: pzReducer,
  t: tReducer,
  s: sReducer,
  pr: prReducer,
  a: aReducer,
  zz: zzReducer,
  settings: settingsReducer,
  logging: loggingReducer,
});

// Error handeling
export const hasErrors = (state) => {
  return (
    getLoginDataStateFromState(state) === DATA_STATE.FAILED ||
    getAllRZStateFromState(state) === DATA_STATE.FAILED ||
    getRZImageStateFromState(state) === DATA_STATE.FAILED ||
    getSettingsStateFromState(state) === DATA_STATE.FAILED ||
    getAllPZStateFromState(state) === DATA_STATE.FAILED ||
    getPZImageStateFromState(state) === DATA_STATE.FAILED ||
    getAllTStateFromState(state) === DATA_STATE.FAILED ||
    getTRowStateFromState(state) === DATA_STATE.FAILED ||
    getAllSStateFromState(state) === DATA_STATE.FAILED ||
    getSRowStateFromState(state) === DATA_STATE.FAILED ||
    getAllPRStateFromState(state) === DATA_STATE.FAILED ||
    getAllAStateFromState(state) === DATA_STATE.FAILED ||
    getAllZZStateFromState(state) === DATA_STATE.FAILED
  );
};

export const getError = (state) => {
  if (getLoginDataStateFromState(state) === DATA_STATE.FAILED) {
    return {
      errorType: ERROR.UNAUTHORIZED,
      errors: [],
    };
  }
  if (getAllRZStateFromState(state) === DATA_STATE.FAILED || getRZImageStateFromState(state) === DATA_STATE.FAILED) {
    return getAllRZErrorFromState(state);
  }
  if (getAllPZStateFromState(state) === DATA_STATE.FAILED || getPZImageStateFromState(state) === DATA_STATE.FAILED) {
    return getAllPZErrorFromState(state);
  }
  if (getAllTStateFromState(state) === DATA_STATE.FAILED || getTRowStateFromState(state) === DATA_STATE.FAILED) {
    return getAllTErrorFromState(state);
  }
  if (getAllSStateFromState(state) === DATA_STATE.FAILED || getSRowStateFromState(state) === DATA_STATE.FAILED) {
    return getAllSErrorFromState(state);
  }
  if (getAllPRStateFromState(state) === DATA_STATE.FAILED) {
    return getAllPRErrorFromState(state);
  }
  if (getAllAStateFromState(state) === DATA_STATE.FAILED) {
    return getAllAErrorFromState(state);
  }
  if (getAllZZStateFromState(state) === DATA_STATE.FAILED) {
    return getAllZZErrorFromState(state);
  }
  if (getSettingsStateFromState(state) === DATA_STATE.FAILED) {
    return getSettingsErrorFromState(state);
  }
};

// logs
export const getAllErrors = (state) => fromLogging.getErrors(state.logging);
export const getLastMessage = (state) => fromLogging.getLastMessage(state.logging);
export const getAllLogsFromState = (state) => fromLogging.getListData(state.logging);
export const getOneLogsFromState = (state) => fromLogging.getData(state.logging);
export const getLogsStateFromState = (state) => fromLogging.getDataState(state.logging);
export const getLogsErrorFromState = (state) => fromLogging.getDataError(state.logging);
export const getLogsPaginationFromState = (state) => fromLogging.getPagination(state.logging);

// login
export const getLoginFromState = (state) => fromLogin.getData(state.login);
export const getUserNameFromState = (state) => fromLogin.getUserName(state.login);
export const getLoginDataStateFromState = (state) => fromLogin.getDataState(state.login);

// rz
export const getAllRZFromState = (state) => fromRZ.getListData(state.rz);
export const getAllRZStateFromState = (state) => fromRZ.getDataState(state.rz);
export const getAllRZErrorFromState = (state) => fromRZ.getDataError(state.rz);
export const getAllRZPaginationFromState = (state) => fromRZ.getPagination(state.rz);

export const getOneRZFromState = (state) => fromRZ.getData(state.rz);
export const getOneRZUpdatedFromState = (state) => fromRZ.getUpdatedData(state.rz);
export const getOneRZRemoveFromState = (state) => fromRZ.getRemoveData(state.rz);
export const getOneRZCopiedFromState = (state) => fromRZ.getCopiedData(state.rz);
export const getOneRZStateFromState = (state) => fromRZ.getDataState(state.rz);
export const getOneRZErrorFromState = (state) => fromRZ.getDataError(state.rz);

export const getRZImageStateFromState = (state) => fromRZ.getImageDataState(state.rz);
export const getRZImageNewDataFromState = (state) => fromRZ.getImageNewData(state.rz);

// pz
export const getAllPZFromState = (state) => fromPZ.getListData(state.pz);
export const getAllPZStateFromState = (state) => fromPZ.getDataState(state.pz);
export const getAllPZErrorFromState = (state) => fromPZ.getDataError(state.pz);
export const getAllPZPaginationFromState = (state) => fromPZ.getPagination(state.pz);
export const getAllPZOpenPlusFromState = (state) => fromPZ.getOpenPlus(state.pz);

export const getOnePZFromState = (state) => fromPZ.getData(state.pz);
export const getOnePZUpdatedFromState = (state) => fromPZ.getUpdatedData(state.pz);
export const getOnePZRemoveFromState = (state) => fromPZ.getRemoveData(state.pz);
export const getOnePZCopiedFromState = (state) => fromPZ.getCopiedData(state.pz);
export const getOnePZStateFromState = (state) => fromPZ.getDataState(state.pz);
export const getOnePZErrorFromState = (state) => fromPZ.getDataError(state.pz);

export const getPZImageStateFromState = (state) => fromPZ.getImageDataState(state.pz);
export const getPZImageNewDataFromState = (state) => fromPZ.getImageNewData(state.pz);

// t
export const getAllTFromState = (state) => fromT.getListData(state.t);
export const getAllTStateFromState = (state) => fromT.getDataState(state.t);
export const getAllTErrorFromState = (state) => fromT.getDataError(state.t);
export const getAllTPaginationFromState = (state) => fromT.getPagination(state.t);

export const getOneTFromState = (state) => fromT.getData(state.t);
export const getOneTUpdatedFromState = (state) => fromT.getUpdatedData(state.t);
export const getOneTRemoveFromState = (state) => fromT.getRemoveData(state.t);
export const getOneTCopiedFromState = (state) => fromT.getCopiedData(state.t);
export const getOneTStateFromState = (state) => fromT.getDataState(state.t);
export const getOneTErrorFromState = (state) => fromT.getDataError(state.t);

export const getTRowStateFromState = (state) => fromT.getRowDataState(state.t);
export const getTRowNewDataFromState = (state) => fromT.getRowNewData(state.t);

// s
export const getAllSFromState = (state) => fromS.getListData(state.s);
export const getAllSStateFromState = (state) => fromS.getDataState(state.s);
export const getAllSErrorFromState = (state) => fromS.getDataError(state.s);
export const getAllSPaginationFromState = (state) => fromS.getPagination(state.s);

export const getOneSFromState = (state) => fromS.getData(state.s);
export const getOneSUpdatedFromState = (state) => fromS.getUpdatedData(state.s);
export const getOneSRemoveFromState = (state) => fromS.getRemoveData(state.s);
export const getOneSCopiedFromState = (state) => fromS.getCopiedData(state.s);
export const getOneSStateFromState = (state) => fromS.getDataState(state.s);
export const getOneSErrorFromState = (state) => fromS.getDataError(state.s);

export const getSRowStateFromState = (state) => fromS.getRowDataState(state.s);
export const getSRowNewDataFromState = (state) => fromS.getRowNewData(state.s);

// pr
export const getAllPRFromState = (state) => fromPR.getListData(state.pr);
export const getAllPRStateFromState = (state) => fromPR.getDataState(state.pr);
export const getAllPRErrorFromState = (state) => fromPR.getDataError(state.pr);
export const getAllPRPaginationFromState = (state) => fromPR.getPagination(state.pr);

export const getOnePRFromState = (state) => fromPR.getData(state.pr);
export const getOnePRUpdatedFromState = (state) => fromPR.getUpdatedData(state.pr);
export const getOnePRRemoveFromState = (state) => fromPR.getRemoveData(state.pr);
export const getOnePRCopiedFromState = (state) => fromPR.getCopiedData(state.pr);
export const getOnePRStateFromState = (state) => fromPR.getDataState(state.pr);
export const getOnePRErrorFromState = (state) => fromPR.getDataError(state.pr);

// a
export const getAllAFromState = (state) => fromA.getListData(state.a);
export const getAllAStateFromState = (state) => fromA.getDataState(state.a);
export const getAllAErrorFromState = (state) => fromA.getDataError(state.a);
export const getAllAPaginationFromState = (state) => fromA.getPagination(state.a);

export const getOneAFromState = (state) => fromA.getData(state.a);
export const getOneAUpdatedFromState = (state) => fromA.getUpdatedData(state.a);
export const getOneARemoveFromState = (state) => fromA.getRemoveData(state.a);
export const getOneACopiedFromState = (state) => fromA.getCopiedData(state.a);
export const getOneAStateFromState = (state) => fromA.getDataState(state.a);
export const getOneAErrorFromState = (state) => fromA.getDataError(state.a);

// zz
export const getAllZZFromState = (state) => fromZZ.getListData(state.zz);
export const getAllZZStateFromState = (state) => fromZZ.getDataState(state.zz);
export const getAllZZErrorFromState = (state) => fromZZ.getDataError(state.zz);
export const getAllZZPaginationFromState = (state) => fromZZ.getPagination(state.zz);

export const getOneZZFromState = (state) => fromZZ.getData(state.zz);
export const getOneZZUpdatedFromState = (state) => fromZZ.getUpdatedData(state.zz);
export const getOneZZRemoveFromState = (state) => fromZZ.getRemoveData(state.zz);
export const getOneZZCopiedFromState = (state) => fromZZ.getCopiedData(state.zz);
export const getOneZZStateFromState = (state) => fromZZ.getDataState(state.zz);
export const getOneZZErrorFromState = (state) => fromZZ.getDataError(state.zz);

//settings
export const appDrawerShownFromState = (state) => fromSettings.appDrawerShown(state.settings);
export const getStrankovaniFromState = (state) => fromSettings.getStrankovani(state.settings);
export const getRZMinYearFromState = (state) => fromSettings.getRZMinYear(state.settings);
export const getPZMinYearFromState = (state) => fromSettings.getPZMinYear(state.settings);
export const getVyrobceFromState = (state) => fromSettings.getVyrobce(state.settings);
export const getShodaVyrobkuFromState = (state) => fromSettings.getShodaVyrobku(state.settings);
export const getImagesSettingsFromState = (state) => fromSettings.getImages(state.settings);
export const getUsersFromState = (state) => fromSettings.getUsers(state.settings);
export const getVerzeFromState = (state) => fromSettings.getVerze(state.settings);
export const getTZFromState = (state) => fromSettings.getTZ(state.settings);
export const getStatsFromState = (state) => fromSettings.getStats(state.settings);
export const isSettingsLoadedFromState = (state) => fromSettings.getDataLoaded(state.settings);
export const getSettingsStateFromState = (state) => fromSettings.getDataState(state.settings);
export const getStatsStateFromState = (state) => fromSettings.getStatsState(state.settings);
export const getSettingsErrorFromState = (state) => fromSettings.getDataError(state.settings);

//persistance
export function createStateFromPersistedValues(values) {
  if (values === undefined) {
    return undefined;
  }

  return {
    settings: values[PERSISTENCE_KEYS.SETTINGS],
  };
}

export function getPersistedValuesFromState(state) {
  let settings = { ...state.settings };
  delete settings.stats;
  delete settings.statsState;
  delete settings.dataLoaded;
  delete settings.dataState;
  delete settings.errorType;
  delete settings.errorWhen;
  delete settings.errors;

  return {
    [PERSISTENCE_KEYS.SETTINGS]: settings,
  };
}
