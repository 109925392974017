import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";

import RZViewPrint from "./components/RZViewPrint";

import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  LinearProgress,
  Typography,
  Button,
  Zoom,
  IconButton,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Edit as EditIcon, Print as PrintIcon } from "@material-ui/icons";

import { getOne, savePagination } from "../../services/redux/RZList/RZList.thunks.js";
import {
  getOneRZFromState,
  getOneRZStateFromState,
  getOneRZErrorFromState,
  getAllRZPaginationFromState,
  getUserNameFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";
import { setAppBarForDetail } from "../../utils/defaults";

const styles = (theme) => ({
  root: {
    width: "21cm",
    overflowX: "auto",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
});

class RZView extends Component {
  state = {
    footerOn: false,
  };

  componentDidMount() {
    this.props.getOne(this.props.match.params.id);

    setAppBarForDetail(this.props, "/rz/list", {
      button: (
        <ReactToPrint
          key="print"
          trigger={() => (
            <IconButton color="inherit">
              <PrintIcon />
            </IconButton>
          )}
          content={() => this.printRef}
        />
      ),
      buttonMenu: null,
    });
  }

  setFooter = () => {
    this.setState({ footerOn: !this.state.footerOn });
  };

  goEdit = () => {
    this.props.history.push("/rz/detail/" + this.props.match.params.id);
  };

  render() {
    const { classes, theme } = this.props;
    const data = this.props.OneData;

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          {data ? `${data.cislo_jednaci}/${data.rok}` : this.props.match.params.id}
        </Typography>
        {["test", "vlastik", "david"].indexOf(this.props.User) !== -1 && (
          <Typography variant="body2" gutterBottom>
            <FormControlLabel
              control={
                <Switch checked={this.state.footerOn} onChange={this.setFooter} name="Přidat podpis a razítko" />
              }
              label="Přidat podpis a razítko"
            />
          </Typography>
        )}
        <Paper className={classes.root}>
          {this.props.OneDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <RZViewPrint data={data} ref={(el) => (this.printRef = el)} footer={this.state.footerOn} />
        </Paper>

        <Zoom in={true} timeout={theme.transitions.duration.enteringScreen} unmountOnExit>
          <Button variant="fab" className={classes.fab} color="secondary" onClick={this.goEdit}>
            <EditIcon />
          </Button>
        </Zoom>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  OneData: getOneRZFromState(state),
  OneDataState: getOneRZStateFromState(state),
  OneDataError: getOneRZErrorFromState(state),
  Pagination: getAllRZPaginationFromState(state),
  User: getUserNameFromState(state),
});

const actionCreators = {
  getOne,
  savePagination,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles, { withTheme: true })(RZView)));
