export const ROUTE_PATHS = {
  ROOT: "/",
  EXAMPLE: "/example",

  RZ: "/rz/",
  RZ_LIST: "/rz/list/:rok?",
  RZ_DETAIL: "/rz/detail/:id",
  RZ_VIEW: "/rz/view/:id",

  PZ: "/pz/",
  PZ_LIST: "/pz/list/:rok?",
  PZ_DETAIL: "/pz/detail/:id",
  PZ_VIEW: "/pz/view/:id",

  T: "/t/",
  T_LIST: "/t/list",
  T_DETAIL: "/t/detail/:id",
  T_VIEW: "/t/view/:id",

  S: "/s/",
  S_LIST: "/s/list",
  S_DETAIL: "/s/detail/:id",
  S_VIEW: "/s/view/:id",

  PR: "/pr/",
  PR_LIST: "/pr/list",
  PR_DETAIL: "/pr/detail/:id",
  PR_VIEW: "/pr/view/:id",

  A: "/a/",
  A_LIST: "/a/list",
  A_DETAIL: "/a/detail/:id",

  ZZ: "/zz/",
  ZZ_LIST: "/zz/list",
  ZZ_DETAIL: "/zz/detail/:id",
  ZZ_VIEW: "/zz/view/:id",

  SETTINGS: "/settings",
  STATS: "/stats",
  PREPOCET_SPALIN: "/prepocet",
  LOGS: "/logs",
};
