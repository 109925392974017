import {
  fetching,
  fetchSucceeded,
  fetchFailed,
  updatePageSizeSucceeded,
  updateUsersSucceeded,
  removeUsersSucceeded,
  updateImagesSucceeded,
  updateVyrobceSucceeded,
  removeVyrobceSucceeded,
  updateShodaSucceeded,
  removeShodaSucceeded,
  saveShowDrawer,
  SETTINGS_ACTION_TYPES,
  updateVerzeSucceeded,
  updateTZSucceeded,
  fetchStatsSucceeded,
} from "./Settings.actions.js";
import { ERROR } from "../constants.js";
import { callApiFromThunks } from "../../../utils/defaults.js";

export function getSettings() {
  return async (dispatch, getState, { api }) => {
    dispatch(fetching());

    // priprava dat
    let resStrankovani;
    let resMinYear;
    let resMinYearPZ;
    let resVyrobce;
    let resImages;
    let resUsers;
    let resShoda;
    let resVerze;
    let resTZ;

    try {
      let promiseStrankovani = api.settings.getStrankovani();
      let promiseMinYear = api.rz.getMinYear();
      let promiseMinYearPZ = api.pz.getMinYear();
      let promiseVyrobce = api.settings.getVyrobce();
      let promiseImages = api.settings.getImages();
      let promiseUsers = api.settings.getUsers();
      let promiseShoda = api.settings.getShodaVyrobku();
      let promiseVerze = api.settings.getVerze();
      let promiseTZ = api.settings.getTZDefault();

      resStrankovani = await promiseStrankovani;
      resMinYear = await promiseMinYear;
      resMinYearPZ = await promiseMinYearPZ;
      resVyrobce = await promiseVyrobce;
      resImages = await promiseImages;
      resUsers = await promiseUsers;
      resShoda = await promiseShoda;
      resVerze = await promiseVerze;
      resTZ = await promiseTZ;
    } catch (e) {
      // odchyceni chyb
      if (e.response && e.response.status === 403) {
        dispatch(fetchFailed(ERROR.UNAUTHORIZED));
      } else {
        dispatch(fetchFailed(ERROR.UNKNOWN));
      }
      return;
    }

    if (resStrankovani.data.ok === false) {
      dispatch(fetchFailed(ERROR.SERVER_ERROR, resStrankovani.data.errs));
      return;
    }

    // vsechno ok - v akci predam data
    dispatch(
      fetchSucceeded(
        resStrankovani.data,
        resMinYear.data,
        resMinYearPZ.data,
        resVyrobce.data,
        resImages.data,
        resUsers.data,
        resShoda.data,
        resVerze.data,
        resTZ.data
      )
    );
  };
}

export function showAppDrawer(shouldShow) {
  return async (dispatch) => {
    dispatch(saveShowDrawer(shouldShow));
  };
}

export function saveStrankovani(pageSize) {
  return async (dispatch, getState, { api }) => {
    let data = { stranky: pageSize };

    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.savePageSize(data),
      SETTINGS_ACTION_TYPES.UPDATING_PAGE_SIZE,
      SETTINGS_ACTION_TYPES.UPDATE_PAGE_SIZE_FAILED
    );

    if (response) {
      dispatch(updatePageSizeSucceeded(data));
    }
  };
}

export function updateVyrobce(vyrobceData) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.updateVyrobce(vyrobceData),
      SETTINGS_ACTION_TYPES.UPDATING_VYROBCE,
      SETTINGS_ACTION_TYPES.UPDATE_VYROBCE_FAILED
    );

    if (response) {
      if (response.newId) {
        vyrobceData.ID = response.newId;
      }

      dispatch(updateVyrobceSucceeded(vyrobceData));
    }
  };
}

export function removeVyrobce(ID) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.removeVyrobce(ID),
      SETTINGS_ACTION_TYPES.REMOVING_VYROBCE,
      SETTINGS_ACTION_TYPES.REMOVE_VYROBCE_FAILED
    );

    if (response) {
      dispatch(removeVyrobceSucceeded(ID));
    }
  };
}

export function updateShodaVyrobku(data) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.updateShodaVyrobku(data),
      SETTINGS_ACTION_TYPES.UPDATING_SHODA,
      SETTINGS_ACTION_TYPES.UPDATE_SHODA_FAILED
    );

    if (response) {
      if (response.newId) {
        data.ID = response.newId;
      }

      dispatch(updateShodaSucceeded(data));
    }
  };
}

export function removeShodaVyrobku(ID) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.removeShodaVyrobku(ID),
      SETTINGS_ACTION_TYPES.REMOVING_SHODA,
      SETTINGS_ACTION_TYPES.REMOVE_SHODA_FAILED
    );

    if (response) {
      dispatch(removeShodaSucceeded(ID));
    }
  };
}

export function updateImages(imagesData) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.updateImages(imagesData),
      SETTINGS_ACTION_TYPES.UPDATING_IMAGES,
      SETTINGS_ACTION_TYPES.UPDATE_IMAGES_FAILED
    );

    if (response) {
      dispatch(updateImagesSucceeded(imagesData));
    }
  };
}

export function updateUser(userData) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.updateUser(userData),
      SETTINGS_ACTION_TYPES.UPDATING_USERS,
      SETTINGS_ACTION_TYPES.UPDATE_USERS_FAILED
    );

    if (response) {
      dispatch(updateUsersSucceeded(userData));
    }
  };
}

export function removeUser(IC) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.removeUser(IC),
      SETTINGS_ACTION_TYPES.REMOVING_USERS,
      SETTINGS_ACTION_TYPES.REMOVE_USERS_FAILED
    );

    if (response) {
      dispatch(removeUsersSucceeded(IC));
    }
  };
}

export function updateVerze(verzeData) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.updateVerze(verzeData),
      SETTINGS_ACTION_TYPES.UPDATING_VERZE,
      SETTINGS_ACTION_TYPES.UPDATE_VERZE_FAILED
    );

    if (response) {
      dispatch(updateVerzeSucceeded(verzeData));
    }
  };
}

export function updateTZ(data) {
  return async (dispatch, getState, { api }) => {
    let response = await callApiFromThunks(
      dispatch,
      () => api.settings.updateTZDefault(data),
      SETTINGS_ACTION_TYPES.UPDATING_TZ,
      SETTINGS_ACTION_TYPES.UPDATE_TZ_FAILED
    );

    if (response) {
      dispatch(updateTZSucceeded(data));
    }
  };
}

export function getStats() {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.settings.getStats(),
      SETTINGS_ACTION_TYPES.FETCHING_STATS,
      SETTINGS_ACTION_TYPES.FETCH_STATS_FAILED,
      fetchStatsSucceeded
    );
  };
}

export function updateStats() {
  return async (dispatch, getState, { api }) => {
    api.settings.updateStats();
  };
}
