import React, { Component } from "react";

class HeaderLadik extends Component {
  render() {
    return (
      <div
        id="hlavicka"
        style={{
          font: "11.5pt/1.5 'arial'",
          textAlign: "center",
          marginTop: "20pt",
        }}
      >
        Vladimír Valchař &#9830; 747 75 Velké Heraltice, Sádek 14 &#9830; tel. 553 663 324, 721 383 559
      </div>
    );
  }
}

export default HeaderLadik;
