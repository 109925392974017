import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {} from "@material-ui/core";

import { print as printStyles } from "../../../constants/commonStyles.js";
import { getUserNameFromState } from "../../../services/redux/rootReducer.js";

const styles = {
  root: printStyles.root,
  page: printStyles.page,
  headerH1: printStyles.headerH1,
  headerH2: printStyles.headerH2,
  headerH3: printStyles.headerH3,
  gap: printStyles.gap,
  table: printStyles.tableWithBorders,
  pcenter: {
    textAlign: "center",
  },
};

class SViewPrint extends Component {
  render() {
    const { classes, data } = this.props;

    return (
      <React.Fragment>
        {data && (
          <div className={classes.root}>
            <div className={classes.page}>
              <h1 className={classes.headerH1}>{data.nazev}</h1>
              <h2 className={classes.headerH2}>
                {data.ulice ? data.ulice + ", " : ""}
                {data.obec}
              </h2>
              <p className={classes.pcenter}>{data.poznamka}</p>
              <div className={classes.gap} />

              <table cellPadding="0px" cellSpacing="0px" className={classes.table}>
                <thead>
                  <tr>
                    <td>&nbsp;</td>
                    <td>datum</td>
                    <td>autorizované měření</td>
                    <td>průběžné zprávy</td>
                    <td>poznámka</td>
                  </tr>
                </thead>
                <tbody>
                  {(data.radky || []).map((rowData, idx) => {
                    if (!rowData) return null;
                    return (
                      <tr key={idx}>
                        <td>{rowData.datum.substr(0, 4)}</td>
                        <td>{new Date(rowData.datum).toLocaleDateString()}</td>
                        <td>{rowData.AM}</td>
                        <td>{rowData.PZ}</td>
                        <td>{rowData.poznamky}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  User: getUserNameFromState(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(SViewPrint));
