export const RZ_ACTION_TYPES = {
  FETCHING_LIST: "USER/RZ/FETCHING_LIST",
  FETCH_LIST_SUCCEEDED: "USER/RZ/FETCH_SUCCEEDED_LIST",
  FETCH_LIST_FAILED: "USER/RZ/FETCH_FAILED_LIST",
  FETCHING: "USER/RZ/FETCHING",
  FETCH_SUCCEEDED: "USER/RZ/FETCH_SUCCEEDED",
  FETCH_FAILED: "USER/RZ/FETCH_FAILED",
  UPDATING: "USER/RZ/UPDATING",
  UPDATE_SUCCEEDED: "USER/RZ/UPDATE_SUCCEEDED",
  UPDATE_FAILED: "USER/RZ/UPDATE_FAILED",
  REMOVING: "USER/RZ/REMOVING",
  REMOVE_SUCCEEDED: "USER/RZ/REMOVE_SUCCEEDED",
  REMOVE_FAILED: "USER/RZ/REMOVE_FAILED",
  COPYING: "USER/RZ/COPYING",
  COPY_SUCCEEDED: "USER/RZ/COPY_SUCCEEDED",
  COPY_FAILED: "USER/RZ/COPY_FAILED",

  PAGINATION: "USER/RZ/PAGINATION",

  ADDING_IMAGE: "USER/RZ/ADDING_IMAGE",
  ADD_IMAGE_SUCCEEDED: "USER/RZ/ADD_IMAGE_SUCCEEDED",
  ADD_IMAGE_FAILED: "USER/RZ/ADD_IMAGE_FAILED",
  REMOVING_IMAGE: "USER/RZ/REMOVING_IMAGE",
  REMOVE_IMAGE_SUCCEEDED: "USER/RZ/REMOVE_IMAGE_SUCCEEDED",
  REMOVE_IMAGE_FAILED: "USER/RZ/REMOVE_IMAGE_FAILED",
  UPDATING_IMAGE_ORDER: "USER/RZ/UPDATING_IMAGE_ORDER",
  UPDATE_IMAGE_ORDER_SUCCEEDED: "USER/RZ/UPDATE_SUCCEEDED_IMAGE_ORDER",
  UPDATE_IMAGE_ORDER_FAILED: "USER/RZ/UPDATE_FAILED_IMAGE_ORDER",
};

export function savePaginationAction(page, searchTerm, year, sort, sortOrder) {
  return {
    type: RZ_ACTION_TYPES.PAGINATION,
    page,
    searchTerm,
    year,
    sort,
    sortOrder,
  };
}
export function addRZImageSucceeded(data) {
  return {
    type: RZ_ACTION_TYPES.ADD_IMAGE_SUCCEEDED,
    data: data.data,
  };
}
