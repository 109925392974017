import React, { Component } from "react";
import { connect } from "react-redux";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import { withStyles, Typography, Paper, LinearProgress, Grid } from "@material-ui/core";

import { getStatsFromState, getStatsStateFromState, getVerzeFromState } from "../../services/redux/rootReducer";
import { getStats } from "../../services/redux/Settings/Settings.thunks";
import { DATA_STATE } from "../../services/redux/constants";
import { getDurationFromSec } from "../../utils/date";

import { LineChart } from "react-easy-chart";
import { setClearAppBar } from "../../utils/defaults";

const styles = (theme) => ({
  root: {
    //width: "100%",
    overflowX: "auto",
    padding: theme.spacing.unit * 2,
    margin: theme.spacing.unit + "px 0",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing.unit,
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginTop: -theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2 - 5,
  },
});

const chartMargin = { top: 10, right: 10, bottom: 25, left: 40 };
let chartWidth = window.innerWidth - 180;

function getMaxFromArray(arr) {
  return arr.reduce(function(sum, val) {
    return sum > val.y ? sum : val.y;
  }, 0);
}

class Stats extends Component {
  state = {
    byMinutes: [],
    byMinutesMax: 0,
    byWeekday: [],
    byWeekdayMax: 0,
    byDay: [],
    byDayMax: 0,
  };
  async componentDidMount() {
    setClearAppBar(this.props);

    await this.props.getStats();

    if (this.props.Stats) {
      this.prepareData();
    }
  }

  prepareData = () => {
    let byMinutes = this.props.Stats.byMinutes.map((y, i) => {
      return { x: i / 6, y: y };
    });
    let byMinutesMax = getMaxFromArray(byMinutes) * 1.1;

    let byWeekday = this.props.Stats.byWeekday.map((e) => {
      return { x: ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"][e.x], y: e.y };
    });
    let byWeekdayMax = getMaxFromArray(byWeekday) * 1.1;

    let byDay = [];
    let today = new Date();
    let date = new Date();
    date.setMonth(0);
    date.setDate(1);
    for (let i = 0; i < 366 && date.getFullYear() === today.getFullYear(); i++) {
      byDay[i] = {
        x: date.toISOString().substr(0, 10),
        y: this.props.Stats.byDay[date.getMonth() + 1 + "-" + date.getDate()] || 0,
      };
      date.setDate(date.getDate() + 1);
    }
    let byDayMax = getMaxFromArray(byDay) * 1.1;

    this.setState({ byDay, byDayMax, byWeekday, byWeekdayMax, byMinutes, byMinutesMax });
  };

  render() {
    const { classes, theme } = this.props;

    const { allStats, currStats } = this.props.Stats || {};

    const isXsUp = isWidthUp("sm", this.props.width);

    chartWidth =
      (document.getElementsByTagName("main")[0] &&
        document.getElementsByTagName("main")[0].offsetWidth -
          (isXsUp ? theme.spacing.unit * 12 + 5 : theme.spacing.unit * 4)) ||
      window.innerWidth - 180;

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          Statistiky
        </Typography>
        <Grid container>
          <Grid item md={6}>
            <Paper className={classes.root}>
              {this.props.StatsState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
              {allStats && (
                <Grid container>
                  <Grid item xs={12}>
                    Od počátku věků:
                  </Grid>

                  <Grid item xs={7}>
                    Program suštěn již
                  </Grid>
                  <Grid item xs={5}>
                    {getDurationFromSec(allStats.totalUptime)}
                  </Grid>

                  <Grid item xs={7}>
                    Průměrně spuštěn
                  </Grid>
                  <Grid item xs={5}>
                    {getDurationFromSec(allStats.avgUptime)}
                  </Grid>

                  <Grid item xs={7}>
                    Počet revizních zpráv
                  </Grid>
                  <Grid item xs={5}>
                    {allStats.RZ}
                  </Grid>

                  <Grid item xs={7}>
                    Počet průchozích zpráv
                  </Grid>
                  <Grid item xs={5}>
                    {allStats.PZ}
                  </Grid>

                  {this.props.Verze.tabulka === "1" && (
                    <Grid item xs={7}>
                      Počet tabulek
                    </Grid>
                  )}
                  {this.props.Verze.tabulka === "1" && (
                    <Grid item xs={5}>
                      {allStats.T}
                    </Grid>
                  )}
                  {this.props.Verze.tabulka === "1" && (
                    <Grid item xs={7}>
                      Počet řádků v tabulkách
                    </Grid>
                  )}
                  {this.props.Verze.tabulka === "1" && (
                    <Grid item xs={5}>
                      {allStats.Tr}
                    </Grid>
                  )}

                  {this.props.Verze.seznam === "1" && (
                    <Grid item xs={7}>
                      Počet seznamů
                    </Grid>
                  )}
                  {this.props.Verze.seznam === "1" && (
                    <Grid item xs={5}>
                      {allStats.S}
                    </Grid>
                  )}
                  {this.props.Verze.seznam === "1" && (
                    <Grid item xs={7}>
                      Počet položek v seznamu
                    </Grid>
                  )}
                  {this.props.Verze.seznam === "1" && (
                    <Grid item xs={5}>
                      {allStats.Sr}
                    </Grid>
                  )}

                  {this.props.Verze.protokol === "1" && (
                    <Grid item xs={7}>
                      Počet protokolů
                    </Grid>
                  )}
                  {this.props.Verze.protokol === "1" && (
                    <Grid item xs={5}>
                      {allStats.PR}
                    </Grid>
                  )}

                  {this.props.Verze.adresar === "1" && (
                    <Grid item xs={7}>
                      Počet adres
                    </Grid>
                  )}
                  {this.props.Verze.adresar === "1" && (
                    <Grid item xs={5}>
                      {allStats.A}
                    </Grid>
                  )}

                  {this.props.Verze.zz === "1" && (
                    <Grid item xs={7}>
                      Počet Inf. o zdrojích znečištění
                    </Grid>
                  )}
                  {this.props.Verze.zz === "1" && (
                    <Grid item xs={5}>
                      {allStats.ZZ}
                    </Grid>
                  )}
                </Grid>
              )}
            </Paper>
          </Grid>
          {currStats && (
            <Grid item md={6}>
              <Paper className={classes.root}>
                <Grid container>
                  <Grid item xs={12}>
                    V tomto spuštění programu:
                  </Grid>

                  <Grid item xs={7}>
                    Program suštěn již
                  </Grid>
                  <Grid item xs={5}>
                    {getDurationFromSec(currStats.uptime)}
                  </Grid>

                  <Grid item xs={7}>
                    Počet revizních zpráv
                  </Grid>
                  <Grid item xs={5}>
                    {currStats.RZ}
                  </Grid>

                  <Grid item xs={7}>
                    Počet průchozích zpráv
                  </Grid>
                  <Grid item xs={5}>
                    {currStats.PZ}
                  </Grid>

                  {this.props.Verze.tabulka === "1" && (
                    <Grid item xs={7}>
                      Počet tabulek
                    </Grid>
                  )}
                  {this.props.Verze.tabulka === "1" && (
                    <Grid item xs={5}>
                      {currStats.T}
                    </Grid>
                  )}
                  {this.props.Verze.tabulka === "1" && (
                    <Grid item xs={7}>
                      Počet řádků v tabulkách
                    </Grid>
                  )}
                  {this.props.Verze.tabulka === "1" && (
                    <Grid item xs={5}>
                      {currStats.Tr}
                    </Grid>
                  )}

                  {this.props.Verze.seznam === "1" && (
                    <Grid item xs={7}>
                      Počet seznamů
                    </Grid>
                  )}
                  {this.props.Verze.seznam === "1" && (
                    <Grid item xs={5}>
                      {currStats.S}
                    </Grid>
                  )}
                  {this.props.Verze.seznam === "1" && (
                    <Grid item xs={7}>
                      Počet položek v seznamu
                    </Grid>
                  )}
                  {this.props.Verze.seznam === "1" && (
                    <Grid item xs={5}>
                      {currStats.Sr}
                    </Grid>
                  )}

                  {this.props.Verze.protokol === "1" && (
                    <Grid item xs={7}>
                      Počet protokolů
                    </Grid>
                  )}
                  {this.props.Verze.protokol === "1" && (
                    <Grid item xs={5}>
                      {currStats.PR}
                    </Grid>
                  )}

                  {this.props.Verze.adresar === "1" && (
                    <Grid item xs={7}>
                      Počet adres
                    </Grid>
                  )}
                  {this.props.Verze.adresar === "1" && (
                    <Grid item xs={5}>
                      {currStats.A}
                    </Grid>
                  )}

                  {this.props.Verze.zz === "1" && (
                    <Grid item xs={7}>
                      Počet Inf. o zdrojích znečištění
                    </Grid>
                  )}
                  {this.props.Verze.zz === "1" && (
                    <Grid item xs={5}>
                      {currStats.ZZ}
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          )}
          {this.state.byMinutes &&
            this.state.byMinutes.length && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  Četnost používání programu
                  <LineChart
                    margin={chartMargin}
                    axes
                    xTicks={24}
                    yTicks={5}
                    grid
                    verticalGrid
                    lineColors={["red"]}
                    width={chartWidth}
                    height={250}
                    yDomainRange={[0, this.state.byMinutesMax]}
                    xDomainRange={[0, 24]}
                    data={[this.state.byMinutes]}
                  />
                </Paper>
              </Grid>
            )}
          {this.state.byWeekday &&
            this.state.byWeekday.length && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  Četnost používání programu dle dnů v týdnu
                  <LineChart
                    margin={chartMargin}
                    axes
                    yTicks={5}
                    grid
                    verticalGrid
                    lineColors={["red"]}
                    width={chartWidth}
                    height={250}
                    yDomainRange={[0, this.state.byWeekdayMax]}
                    xType={"text"}
                    data={[this.state.byWeekday]}
                  />
                </Paper>
              </Grid>
            )}
          {this.state.byDay &&
            this.state.byDay.length && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  Četnost používání programu dle dnů v roce
                  <LineChart
                    margin={chartMargin}
                    axes
                    yTicks={5}
                    xTicks={12}
                    grid
                    verticalGrid
                    lineColors={["red"]}
                    width={chartWidth}
                    height={250}
                    yDomainRange={[0, this.state.byDayMax]}
                    xType={"time"}
                    tickTimeDisplayFormat={"%d %b"}
                    data={[this.state.byDay]}
                    datePattern={"%Y-%m-%d"}
                  />
                </Paper>
              </Grid>
            )}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  Stats: getStatsFromState(state),
  StatsState: getStatsStateFromState(state),
  Verze: getVerzeFromState(state),
});

const actionCreators = {
  getStats,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withWidth()(withStyles(styles, { withTheme: true })(Stats)));
