export const SETTINGS_ACTION_TYPES = {
  FETCHING: "USER/SETTINGS/FETCHING",
  FETCH_SUCCEEDED: "USER/SETTINGS/FETCH_SUCCEEDED",
  FETCH_FAILED: "USER/SETTINGS/FETCH_FAILED",
  UPDATING_PAGE_SIZE: "USER/SETTINGS/UPDATING_PAGE_SIZE",
  UPDATE_PAGE_SIZE_SUCCEEDED: "USER/SETTINGS/UPDATE_PAGE_SIZE_SUCCEEDED",
  UPDATE_PAGE_SIZE_FAILED: "USER/SETTINGS/UPDATE_PAGE_SIZE_FAILED",

  SHOW_APP_DRAWER: "USER/SETTINGS/SHOW_APP_DRAWER",

  UPDATING_USERS: "USER/SETTINGS/UPDATING_USERS",
  UPDATE_USERS_SUCCEEDED: "USER/SETTINGS/UPDATE_USERS_SUCCEEDED",
  UPDATE_USERS_FAILED: "USER/SETTINGS/UPDATE_USERS_FAILED",
  REMOVING_USERS: "USER/SETTINGS/REMOVING_USERS",
  REMOVE_USERS_SUCCEEDED: "USER/SETTINGS/REMOVE_USERS_SUCCEEDED",
  REMOVE_USERS_FAILED: "USER/SETTINGS/REMOVE_USERS_FAILED",

  UPDATING_IMAGES: "USER/SETTINGS/UPDATING_IMAGES",
  UPDATE_IMAGES_SUCCEEDED: "USER/SETTINGS/UPDATE_IMAGES_SUCCEEDED",
  UPDATE_IMAGES_FAILED: "USER/SETTINGS/UPDATE_IMAGES_FAILED",

  UPDATING_VYROBCE: "USER/SETTINGS/UPDATING_VYROBCE",
  UPDATE_VYROBCE_SUCCEEDED: "USER/SETTINGS/UPDATE_VYROBCE_SUCCEEDED",
  UPDATE_VYROBCE_FAILED: "USER/SETTINGS/UPDATE_VYROBCE_FAILED",
  REMOVING_VYROBCE: "USER/SETTINGS/REMOVING_VYROBCE",
  REMOVE_VYROBCE_SUCCEEDED: "USER/SETTINGS/REMOVE_VYROBCE_SUCCEEDED",
  REMOVE_VYROBCE_FAILED: "USER/SETTINGS/REMOVE_VYROBCE_FAILED",

  UPDATING_SHODA: "USER/SETTINGS/UPDATING_SHODA",
  UPDATE_SHODA_SUCCEEDED: "USER/SETTINGS/UPDATE_SHODA_SUCCEEDED",
  UPDATE_SHODA_FAILED: "USER/SETTINGS/UPDATE_SHODA_FAILED",
  REMOVING_SHODA: "USER/SETTINGS/REMOVING_SHODA",
  REMOVE_SHODA_SUCCEEDED: "USER/SETTINGS/REMOVE_SHODA_SUCCEEDED",
  REMOVE_SHODA_FAILED: "USER/SETTINGS/REMOVE_SHODA_FAILED",

  UPDATING_VERZE: "USER/SETTINGS/UPDATING_VERZE",
  UPDATE_VERZE_SUCCEEDED: "USER/SETTINGS/UPDATE_VERZE_SUCCEEDED",
  UPDATE_VERZE_FAILED: "USER/SETTINGS/UPDATE_VERZE_FAILED",

  UPDATING_TZ: "USER/SETTINGS/UPDATING_TZ",
  UPDATE_TZ_SUCCEEDED: "USER/SETTINGS/UPDATE_TZ_SUCCEEDED",
  UPDATE_TZ_FAILED: "USER/SETTINGS/UPDATE_TZ_FAILED",

  FETCHING_STATS: "USER/SETTINGS/FETCHING_STATS",
  FETCH_STATS_SUCCEEDED: "USER/SETTINGS/FETCH_STATS_SUCCEEDED",
  FETCH_STATS_FAILED: "USER/SETTINGS/FETCH_STATS_FAILED",
};

export function fetching() {
  return {
    type: SETTINGS_ACTION_TYPES.FETCHING,
  };
}

export function fetchSucceeded(strankovani, rzMinYear, pzMinYear, vyrobce, images, users, shoda, verze, tz) {
  return {
    type: SETTINGS_ACTION_TYPES.FETCH_SUCCEEDED,
    strankovani,
    rzMinYear,
    pzMinYear,
    vyrobce,
    images,
    users,
    shoda,
    verze,
    tz,
  };
}

export function fetchStatsSucceeded(stats) {
  return {
    type: SETTINGS_ACTION_TYPES.FETCH_STATS_SUCCEEDED,
    stats,
  };
}

export function fetchFailed(errorType, errors) {
  return {
    type: SETTINGS_ACTION_TYPES.FETCH_FAILED,
    errorType,
    errors,
  };
}

export function saveShowDrawer(shouldShow) {
  return {
    type: SETTINGS_ACTION_TYPES.SHOW_APP_DRAWER,
    showAppDrawer: shouldShow,
  };
}

export function updatePageSizeSucceeded(strankovani) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_PAGE_SIZE_SUCCEEDED,
    strankovani,
  };
}

// update vyrobce
export function updateVyrobceSucceeded(vyrobce) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_VYROBCE_SUCCEEDED,
    vyrobce,
  };
}

// remove vyrobce
export function removeVyrobceSucceeded(ID) {
  return {
    type: SETTINGS_ACTION_TYPES.REMOVE_VYROBCE_SUCCEEDED,
    ID,
  };
}

// update shodaVyrobku
export function updateShodaSucceeded(shoda) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_SHODA_SUCCEEDED,
    shoda,
  };
}

// remove shoda vyrobku

export function removeShodaSucceeded(ID) {
  return {
    type: SETTINGS_ACTION_TYPES.REMOVE_SHODA_SUCCEEDED,
    ID,
  };
}

// update images
export function updateImagesSucceeded(images) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_IMAGES_SUCCEEDED,
    images,
  };
}

// update users
export function updateUsersSucceeded(users) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_USERS_SUCCEEDED,
    users,
  };
}

// remove users
export function removeUsersSucceeded(IC) {
  return {
    type: SETTINGS_ACTION_TYPES.REMOVE_USERS_SUCCEEDED,
    IC,
  };
}

// update verze
export function updateVerzeSucceeded(verze) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_VERZE_SUCCEEDED,
    verze,
  };
}

// update verze
export function updateTZSucceeded(tz) {
  return {
    type: SETTINGS_ACTION_TYPES.UPDATE_TZ_SUCCEEDED,
    tz,
  };
}
