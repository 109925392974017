import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSQLFormatDate } from "../../utils/date.js";

import DeleteConfirm from "../../components/Dialogs/DeleteConfirm.js";

import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  CircularProgress,
  LinearProgress,
  Typography,
  Grid,
  TextField,
  Button,
  Zoom,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";
import { Save as SaveIcon, Check as CheckIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { getOne, update, remove, savePagination } from "../../services/redux/PRList/PRList.thunks.js";
import {
  getOnePRFromState,
  getOnePRUpdatedFromState,
  getOnePRStateFromState,
  getAllPRPaginationFromState,
  getUsersFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";
import { setAppBarForDetail } from "../../utils/defaults.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  gridCell: {},
  dateField: {
    width: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
});

const mereniRows = [
  "Teplota vody",
  "Teplota spalin",
  <span>
    0<sub>2</sub>
  </span>,
  "CO",
  "NO",
  "Teplota okolí",
  <span>
    C0
    <sub>2</sub>
  </span>,
  "Účinnost",
  "Komínové ztráty",
  "Přebytek vzduchu",
  "Tah",
  <span>
    CO 3% O<sub>2</sub>
  </span>,
  <span>
    NO 3% O<sub>2</sub>
  </span>,
];

class PRDetail extends Component {
  state = {
    data: {
      datum: getSQLFormatDate(),
      jmeno: "",
      adresa: "",
      typ_kotle: "",
      horak: "",
      palivo: "",
      vykon: "",
      rok_vyroby: "",
      vyrobni_cislo: "",
      mistni_cislo: "",
      osoba: "",
      mereni0: "",
      mereni1: "",
      mereni2: "",
      mereni3: "",
      mereni4: "",
      mereni5: "",
      mereni6: "",
      mereni7: "",
      mereni8: "",
      mereni9: "",
      mereni10: "",
      mereni13: "",
      mereni12: "",
      poznamka: "",
      IC: "",
    },
    saved: true,
    removeConfirmOpen: false,
  };

  componentDidMount() {
    this.props.match.params.id !== "new" && this.props.getOne(this.props.match.params.id);

    setAppBarForDetail(this.props, "/pr/list", {
      icon: <DeleteIcon />,
      label: "Smaž",
      onClick: this.handleRemoveClick,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.OneDataState === DATA_STATE.REMOVE_SUCCEEDED) {
      this.props.history.push("/pr/list");
    }
    // set data to state after fetch
    if (this.props.OneData && this.props.OneData !== prevProps.OneData) {
      let data = this.props.OneData;
      let selectedUser = this.props.Users.find(function(us) {
        return us.jmeno + " " + us.prijmeni === data.osoba;
      });
      if (selectedUser) {
        data.IC = selectedUser.IC;
      } else {
        data.IC = this.props.Users[0].IC;
      }
      this.setState({ data: data });
    }
    // set saved flag after save and reload after new has been added
    if (
      this.props.OneDataState === DATA_STATE.UPDATE_SUCCEEDED &&
      prevProps.OneDataState !== DATA_STATE.UPDATE_SUCCEEDED
    ) {
      this.setState({ saved: true });

      if (this.props.askWhenNavigatingAway && this.props.setAskWhenNavigatingAway) {
        this.props.setAskWhenNavigatingAway(false);
      }
      if (this.props.match.params.id === "new" && this.props.UpdatedData) {
        this.props.history.push(`/pr/detail/${this.props.UpdatedData.newId}`);
        this.props.getOne(this.props.UpdatedData.newId);
      }
    }
  }

  handleChange = (name) => (event) => {
    let newData = this.state.data;
    newData[name] = event.target.value;
    if (name === "IC") {
      let osoba =
        this.props.Users.find(function(us) {
          return us.IC === newData.IC;
        }) || {};
      newData.osoba = osoba.jmeno + " " + osoba.prijmeni;
    }
    this.setState({ data: newData, saved: false });

    if (this.props.setAskWhenNavigatingAway) {
      this.props.setAskWhenNavigatingAway(true);
    }
  };

  save = () => {
    if (this.state.saved) {
      this.props.history.push("/pr/view/" + this.props.match.params.id);
    } else if (this.props.OneDataState !== DATA_STATE.UPDATE_IN_PROGRESS) {
      this.props.update(this.props.match.params.id, this.state.data);
    }
  };

  handleRemoveClick = () => {
    this.setState({ removeConfirmOpen: true });
  };
  remove = (shouldRemove) => {
    if (shouldRemove) {
      this.props.remove(this.props.match.params.id);
    }
    this.setState({ removeConfirmOpen: false });
  };

  render() {
    const { classes, theme } = this.props;
    const data = this.props.OneData;
    const ID = this.props.match.params.id;

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          {data && ID !== "new" && `${data.jmeno}, ${data.adresa}`}
          {ID === "new" && "Nový"}
        </Typography>
        <Paper className={classes.root}>
          {this.props.OneDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container direction="row">
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="datum"
                  label="Datum"
                  type="date"
                  margin="normal"
                  className={classes.dateField}
                  value={this.state.data.datum}
                  onChange={this.handleChange("datum")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="jmeno"
                  label="Jméno"
                  margin="normal"
                  fullWidth
                  value={this.state.data.jmeno}
                  onChange={this.handleChange("jmeno")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="adresa"
                  label="Adresa"
                  margin="normal"
                  fullWidth
                  value={this.state.data.adresa}
                  onChange={this.handleChange("adresa")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <FormControl className={classes.formControl} margin="normal">
                  <InputLabel shrink htmlFor={"uzivatel-placeholder"}>
                    Revizní technik
                  </InputLabel>
                  <Select
                    value={this.state.data.IC}
                    onChange={this.handleChange("IC")}
                    input={<Input name="uzivatel" id={"uzivatel-placeholder"} />}
                    name="uzivatel"
                    className={classes.selectEmpty}
                  >
                    {this.props.Users.map((user) => (
                      <MenuItem value={user.IC} key={user.IC}>
                        {user.jmeno} {user.prijmeni}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="typ_kotle"
                  label="Typ kotle"
                  margin="normal"
                  fullWidth
                  value={this.state.data.typ_kotle}
                  onChange={this.handleChange("typ_kotle")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="horak"
                  label="Druh hořáku"
                  margin="normal"
                  fullWidth
                  value={this.state.data.horak}
                  onChange={this.handleChange("horak")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="palivo"
                  label="Druh paliva"
                  margin="normal"
                  fullWidth
                  value={this.state.data.palivo}
                  onChange={this.handleChange("palivo")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="vykon"
                  label="Štítkový výkon"
                  margin="normal"
                  fullWidth
                  value={this.state.data.vykon}
                  onChange={this.handleChange("vykon")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="rok_vyroby"
                  label="Rok výroby"
                  margin="normal"
                  fullWidth
                  value={this.state.data.rok_vyroby}
                  onChange={this.handleChange("rok_vyroby")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="vyrobni_cislo"
                  label="Výrobní číslo"
                  margin="normal"
                  fullWidth
                  value={this.state.data.vyrobni_cislo}
                  onChange={this.handleChange("vyrobni_cislo")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="mistni_cislo"
                  label="Místní číslo"
                  margin="normal"
                  fullWidth
                  value={this.state.data.mistni_cislo}
                  onChange={this.handleChange("mistni_cislo")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                &nbsp;
              </Grid>
              {mereniRows.map((i, idx) => {
                if (idx === 11) {
                  idx = 13;
                }
                return (
                  <Grid item xs={12} sm={6} className={classes.gridCell} key={idx}>
                    <TextField
                      id={"mereni" + idx}
                      label={i}
                      margin="normal"
                      type="number"
                      className={classes.dateField}
                      value={this.state.data["mereni" + idx]}
                      onChange={this.handleChange("mereni" + idx)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} className={classes.gridCell}>
                &nbsp;
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="poznamka"
                  label="Poznámka"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="5"
                  value={this.state.data.poznamka}
                  onChange={this.handleChange("poznamka")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>

        <div className={classes.fab}>
          <Zoom in={true} timeout={theme.transitions.duration.enteringScreen} unmountOnExit>
            <Button variant="fab" color="secondary" onClick={this.save}>
              {this.state.saved ? <CheckIcon /> : <SaveIcon />}
            </Button>
          </Zoom>
          {this.props.OneDataState === DATA_STATE.UPDATE_IN_PROGRESS && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
        <DeleteConfirm
          open={this.state.removeConfirmOpen}
          onClose={this.remove}
          label="Opravdu chcete smazat tuto zprávu?"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  OneData: getOnePRFromState(state),
  UpdatedData: getOnePRUpdatedFromState(state),
  OneDataState: getOnePRStateFromState(state),
  Pagination: getAllPRPaginationFromState(state),
  Users: getUsersFromState(state),
});

const actionCreators = {
  getOne,
  update,
  remove,
  savePagination,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles, { withTheme: true })(PRDetail)));
