import { savePaginationAction, PR_ACTION_TYPES } from "./PRList.actions.js";
import { callApiFromThunks } from "../../../utils/defaults.js";

export function getAll(params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pr.getPage(params),
      PR_ACTION_TYPES.FETCHING_LIST,
      PR_ACTION_TYPES.FETCH_LIST_FAILED,
      PR_ACTION_TYPES.FETCH_LIST_SUCCEEDED
    );
  };
}

export function getOne(id, params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pr.getOne(id, params),
      PR_ACTION_TYPES.FETCHING,
      PR_ACTION_TYPES.FETCH_FAILED,
      PR_ACTION_TYPES.FETCH_SUCCEEDED
    );
  };
}

export function update(id, data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pr.update(id, data),
      PR_ACTION_TYPES.UPDATING,
      PR_ACTION_TYPES.UPDATE_FAILED,
      PR_ACTION_TYPES.UPDATE_SUCCEEDED
    );
  };
}

export function remove(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pr.remove(id),
      PR_ACTION_TYPES.REMOVING,
      PR_ACTION_TYPES.REMOVE_FAILED,
      PR_ACTION_TYPES.REMOVE_SUCCEEDED
    );
  };
}

export function copy(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pr.copy(id),
      PR_ACTION_TYPES.COPYING,
      PR_ACTION_TYPES.COPY_FAILED,
      PR_ACTION_TYPES.COPY_SUCCEEDED
    );
  };
}

export function savePagination(data) {
  return async (dispatch) => {
    dispatch(savePaginationAction(data.page, data.searchTerm, data.sort, data.sortOrder));
  };
}
