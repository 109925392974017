import { ERROR, DATA_STATE } from "../services/redux/constants";
import { logError } from "../services/redux/Logging/Logging.thunks";

export function getIfUnd(value, def) {
  return value === undefined ? def : value;
}

export function getIfUndOrNull(value, def) {
  return value === undefined || value === null ? def : value;
}

export function showCisloSec(data) {
  if (!data) return false;

  let cisloSec = parseInt(data.cislo_sec, 10);
  return cisloSec > 1 || (cisloSec === 1 && data.isSingle === "0");
}

function getMakeSeznamText(pathTaken) {
  switch (pathTaken) {
    case 5:
      return "Nalezen existující seznam s řádkem pro tuto zprávu, změněn počet zpráv.";
    case 9:
      return "Nalezen existující seznam, vytvořen nový řádek.";
    case 10:
      return "Seznam pro tuto právu nenalezen a proto byl vytvořen nový.";
    default:
      return "";
  }
}

export async function callApiFromThunks(dispatch, call, onInitiation, onFail, onSuccess) {
  dispatch(initiateAction(onInitiation));

  let failFunc;
  if (typeof onFail === "string") {
    failFunc = (errorType, errors, response) => {
      let error = {
        type: onFail,
        errorWhen: onInitiation,
        errorType,
        errors,
      };
      dispatch(logError(error, response));
      dispatch(error);
    };
  } else if (typeof onFail === "function") {
    failFunc = function(errorType, errors, response) {
      dispatch(logError({ errorWhen: onInitiation, errorType, errors }, response));
      dispatch(onFail(errorType, errors));
    };
  } else {
    failFunc = (errorType, errors, response) => {
      dispatch(logError({ errorWhen: onInitiation, errorType, errors }, response));
    };
  }

  let succFunc;
  if (typeof onSuccess === "string") {
    succFunc = (data) => {
      dispatch({
        type: onSuccess,
        data,
      });
    };
  } else if (typeof onSuccess === "function") {
    succFunc = (data) => dispatch(onSuccess(data));
  }

  let response;
  try {
    response = await call();
  } catch (e) {
    // odchyceni chyb
    if (e.response && e.response.status === 403) {
      failFunc(ERROR.UNAUTHORIZED, undefined, e.response);
    } else {
      failFunc(ERROR.UNKNOWN, e.response);
    }
    return;
  }

  if (response.data.ok === false) {
    failFunc(
      response.data.errs[0] === "not_permitted" ? ERROR.NOT_PERMITTED : ERROR.SERVER_ERROR,
      response.data.errs,
      response
    );
    return;
  } else if (response.data.errs && response.data.errs.length) {
    dispatch(
      logError(
        {
          type: onInitiation,
          errorType: ERROR.WARNING,
          errors: response.data.errs,
        },
        response
      )
    );
  }

  if (response.data.makeSeznamRes) {
    dispatch(
      logError({
        errorType: ERROR.INFO,
        errors: [getMakeSeznamText(response.data.makeSeznamRes)],
      })
    );
  }

  if (succFunc) {
    succFunc(response.data);
  }

  return response.data;
}

export function initiateAction(action) {
  return {
    type: action,
  };
}

export function getFailStateForReducer(state, action, when, dataStateName) {
  dataStateName = dataStateName || "dataState";
  return {
    ...state,
    [dataStateName]: DATA_STATE.FAILED,
    errorWhen: when,
    errorType: action.errorType,
    errors: action.errors,
  };
}

// setAppBar
export function setClearAppBar(props) {
  if (props && props.setAppBarOptions) {
    props.setAppBarOptions({});
  }
}

export function setAppBarForList(props) {
  if (props && props.setAppBarOptions && props.Pagination && props.savePagination) {
    props.setAppBarOptions({
      onSearch: (val) => props.savePagination({ searchTerm: val, page: 0 }),
      searchTerm: props.Pagination.searchTerm,
    });
  }
}

export function setAppBarForDetail(props, path, removeButton, secondaryButton) {
  if (props && props.setAppBarOptions && props.Pagination && props.savePagination) {
    if (props.setAppBarOptions) {
      let buttons = [];
      if (secondaryButton) {
        buttons.push(secondaryButton);
      }
      if (props.match.params.id !== "new" && removeButton) {
        buttons.push(removeButton);
      }

      props.setAppBarOptions({
        onSearch: (val) => {
          props.savePagination({ searchTerm: val, page: 0 });
          props.history && path && props.history.push(path);
        },
        searchTerm: props.Pagination.searchTerm,
        buttons: buttons,
      });
    }
  }
}
