import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import { print as printStyles } from "../../constants/commonStyles.js";
import { getUserNameFromState } from "../../services/redux/rootReducer.js";

import HeaderValch from "./HeaderValch.js";
import HeaderCech from "./HeaderCech.js";
import HeaderDavid from "./HeaderDavid.js";
import HeaderLadik from "./HeaderLadik";

const styles = {
  header: printStyles.header,
};

class Header extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.header}>
        {(this.props.User === "test" || this.props.User === "vlastik") && <HeaderValch />}
        {this.props.User === "mcech" && <HeaderCech />}
        {this.props.User === "david" && <HeaderDavid />}
        {this.props.User === "ladik" && <HeaderLadik />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  User: getUserNameFromState(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(Header));
