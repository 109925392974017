const print = {
  root: {
    font: "12.5pt/1.1 'Times new roman'",
  },
  page: {
    height: "29.6cm",
    padding: "1cm 1.4cm 1.4cm",
    overflow: "hidden",
    pageBreakAfter: "always",
    position: "relative",
  },
  breakablePage: {
    padding: "1cm 1.4cm 1.4cm",
    pageBreakAfter: "always",
  },
  header: {
    width: "100%",
    height: "2.55cm",
    overflowX: "hidden",
    position: "relative",
  },
  headerH1: {
    fontSize: "150%",
    textAlign: "center",
    fontWeight: "bold",
    margin: "0",
    padding: "0",
  },
  headerH2: {
    fontSize: "100%",
    textAlign: "center",
    fontWeight: "bold",
    margin: "0",
    padding: "0",
  },
  headerH3: {
    fontSize: "120%",
    textAlign: "center",
    fontWeight: "bold",
    fontVariant: "small-caps",
    margin: "0",
    padding: "0",
  },
  gap: {
    height: "0.5cm",
    width: "100%",
  },
  smallGap: {
    fontSize: "50%",
  },
  table: {
    fontSize: "100%",
    width: "100%",
  },
  tableWithPadding: {
    fontSize: "100%",
    width: "100%",
    padding: "0 0.5cm",
  },
  tableWithBorders: {
    fontSize: "100%",
    width: "100%",
    borderTop: "1px solid",
    borderLeft: "1px solid",
    margin: "5px",
    pageBreakInside: "auto",
    "& td": {
      verticalAlign: "top",
      borderBottom: "1px solid",
      borderRight: "1px solid",
      padding: "4px",
      whiteSpace: "pre-wrap",
    },
  },
  label: {
    fontSize: "90%",
    width: "48.5%",
    verticalAlign: "top",
  },
  label100: {
    fontSize: "90%",
    width: "13%",
    verticalAlign: "top",
  },
  value: {
    verticalAlign: "top",
    whiteSpace: "pre-wrap",
  },
  valueRight270: {
    verticalAlign: "top",
    whiteSpace: "pre-wrap",
    textAlign: "right",
    width: "35%",
  },
  alignBottom: {
    verticalAlign: "bottom",
  },
  signature: {
    textAlign: "right",
    fontSize: "80%",
  },
  image: {
    marginRight: "5px",
    marginTop: "5px",
    border: "1px solid grey",
  },
  footer: {
    position: "absolute",
    right: "1cm",
    bottom: "1cm",
  },
};

export { print };
