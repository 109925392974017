import React, { Component } from "react";

import HlavickaImage from "./hlavicka1.jpg";

class HeaderValch extends Component {
  render() {
    return (
      <div
        id="hlavicka"
        style={{
          font: "11pt/1.5 'arial'",
        }}
      >
        <table
          style={{
            position: "absolute",
            top: "44px",
            left: "165px",
            borderTop: "1px solid",
            marginRight: "5px",
            width: "200px",
          }}
        >
          <tbody>
            <tr>
              <td>&emsp;</td>
            </tr>
          </tbody>
        </table>
        <table
          style={{
            textAlign: "right",
            position: "absolute",
            top: "24px",
            left: "330px",
            width: "340px",
          }}
        >
          <tbody>
            <tr>
              <td>
                <b>
                  <i>Vlastimil Valchář, Ing. Jana Valchářová</i>
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style={{
                    fontSize: "89%",
                    position: "relative",
                    top: "-5px",
                  }}
                >
                  Palackého 13, 746 01 OPAVA, tel: 553 711 161
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            position: "absolute",
            top: "16px",
            left: "10px",
          }}
        >
          <img src={HlavickaImage} width="125" alt="Eko komín" />
        </div>
      </div>
    );
  }
}

export default HeaderValch;
