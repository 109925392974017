import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField, IconButton, Hidden } from "@material-ui/core";
import {
  Delete as DeleteIcon,
  ArrowUpward as ArrowUp,
  ArrowDownward as ArrowDown,
  Add as AddIcon,
} from "@material-ui/icons";

import {} from "../../../services/redux/TList/TList.thunks.js";
import {} from "../../../services/redux/rootReducer.js";

const styles = (theme) => ({
  gridRowCell: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },
  rowButton: {
    marginTop: "20px",
  },
});

const emptyRow = {
  poschodi: "",
  jmeno: "",
  kotel: "",
  tuha: "",
  plyn: "",
};

class TDetailRow extends Component {
  state = {
    row: emptyRow,
    removeRadekConfirmOpenIdx: false,
  };

  componentDidMount() {
    this.setState({ row: this.props.row || Object.assign({ ID_hl: this.props.IDhl }, emptyRow) });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.row !== this.props.row) {
      this.setState({ row: nextProps.row || Object.assign({ ID_hl: this.props.IDhl }, emptyRow) });
    }
  }

  handleChangeRow = (name) => (event) => {
    let row = this.state.row;
    row[name] = event.target.value;
    this.setState({ row: row }, () => this.props.handleChangeRow(row));
  };

  render() {
    const { classes } = this.props;

    const row = this.state.row;

    return (
      <React.Fragment>
        <Grid container direction="row" key={row.ID || "new"}>
          {row.ID ? (
            <Grid item xs={12} sm={2} className={classes.gridRowCell}>
              <IconButton
                color="secondary"
                className={classes.rowButton}
                aria-label="Přesuň řádek nahoru"
                onClick={this.props.onOrderChanged(-1)}
              >
                <ArrowUp />
              </IconButton>
              <IconButton
                color="secondary"
                className={classes.rowButton}
                aria-label="Přesuň řádek dolů"
                onClick={this.props.onOrderChanged(1)}
              >
                <ArrowDown />
              </IconButton>
            </Grid>
          ) : (
            <Hidden xsDown>
              <Grid item sm={2} />
            </Hidden>
          )}

          <Grid item xs={12} sm={1} className={classes.gridRowCell}>
            <TextField
              id="poschodi"
              label="Poschodí"
              margin="normal"
              fullWidth
              value={row.poschodi}
              onChange={this.handleChangeRow("poschodi")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.gridRowCell}>
            <TextField
              id="jmeno"
              label="Jméno"
              margin="normal"
              fullWidth
              value={row.jmeno}
              onChange={this.handleChangeRow("jmeno")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.gridRowCell}>
            <TextField
              id="kotel"
              label="Kotel"
              margin="normal"
              fullWidth
              multiline
              rowsMax="3"
              value={row.kotel}
              onChange={this.handleChangeRow("kotel")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={1} className={classes.gridRowCell}>
            <TextField
              id="plyn"
              label="Plyn"
              margin="normal"
              fullWidth
              multiline
              rowsMax="3"
              value={row.plyn}
              onChange={this.handleChangeRow("plyn")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={1} className={classes.gridRowCell}>
            <TextField
              id="tuha"
              label="Tuhá"
              margin="normal"
              fullWidth
              multiline
              rowsMax="3"
              value={row.tuha}
              onChange={this.handleChangeRow("tuha")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={1} className={classes.gridRowCell}>
            {row.ID ? (
              <IconButton
                color="default"
                className={classes.rowButton}
                aria-label="Smaž řádek"
                onClick={this.props.handleRowRemoveClick}
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              <IconButton
                color="secondary"
                className={classes.rowButton}
                aria-label="Přidej řádek"
                onClick={this.props.handleAddRowClick}
              >
                <AddIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

TDetailRow.propTypes = {
  row: PropTypes.object,
  IDhl: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(TDetailRow);
