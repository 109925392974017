import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";
import {} from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  gridCell: {
    "& > div": {
      width: "200px",
      minWidth: "60%",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const paliva = [
  { label: "Zemní plyn", A: 0.009, B: 0.37, CO2max: 11.7, RefO2: 3 },
  { label: "Svítiplyn", A: 0.011, B: 0.29, CO2max: 12.3, RefO2: 3 },
  { label: "Propan-butan", A: 0.008, B: 0.42, CO2max: 13.8, RefO2: 3 },
  { label: "Lehký topný olej", A: 0.007, B: 0.5, CO2max: 15.7, RefO2: 3 },
  { label: "Těžký topný olej", A: 0.007, B: 0.5, CO2max: 16.0, RefO2: 3 },
  { label: "Koks", A: 0.0, B: 0.75, CO2max: 20.7, RefO2: 6 },
  { label: "Černé uhlí", A: 0.005, B: 0.63, CO2max: 18.5, RefO2: 6 },
  { label: "Hnědé uhlí (10%)", A: 0.007, B: 0.62, CO2max: 18.5, RefO2: 6 },
  { label: "Hnědé uhlí (30%)", A: 0.009, B: 0.64, CO2max: 18.5, RefO2: 6 },
  { label: "Hnědé uhlí (50%)", A: 0.015, B: 0.68, CO2max: 18.5, RefO2: 6 },
  { label: "Dřevo", A: 0.0, B: 0.0, CO2max: 0, RefO2: 11 },
];

function prebytek_vz(O2) {
  var n;
  if (O2 >= 21) O2 = 20;
  n = 21 / (21 - O2);
  return n;
}

function prepocet_sp(O2R, O2M, COM) {
  var COV, k;
  if (O2M >= 21) O2M = 20.99;
  k = COM / (21 - O2M);
  COV = (O2M - O2R) * k + COM;
  return COV;
}

function vypocti_CO2(O2, palivo) {
  var CO2;
  CO2 = paliva[palivo].CO2max / prebytek_vz(O2);
  return CO2;
}

function kom_ztrata(Ts, Tm, O2, palivo) {
  var Q, CO2;
  CO2 = vypocti_CO2(O2, palivo);
  Q = (Ts - Tm) * (paliva[palivo].A + paliva[palivo].B / CO2);
  return Q;
}

class Prepocet extends Component {
  state = {
    palivo: 0,
    O2: 10,
    CO: 10,
    NO: 10,
    Ts: 40,
    Tm: 20,
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: parseFloat(event.target.value) });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          Přepočet spalin
        </Typography>
        <Paper className={classes.root}>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container direction="row">
              <Grid item xs={12} md={6} className={classes.gridCell}>
                <FormControl className={classes.formControl} margin="normal">
                  <InputLabel shrink htmlFor="palivo">
                    Palivo
                  </InputLabel>
                  <Select
                    value={this.state.palivo}
                    onChange={this.handleChange("palivo")}
                    displayEmpty
                    input={<Input name="palivo" id="palivo" />}
                    name="palivo"
                    className={classes.selectEmpty}
                  >
                    {paliva.map((i, idx) => {
                      return (
                        <MenuItem value={idx} key={idx}>
                          {i.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="O2"
                  label="O2"
                  margin="normal"
                  type="number"
                  fullWidth
                  value={this.state.O2}
                  onChange={this.handleChange("O2")}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="CO"
                  label="CO"
                  margin="normal"
                  type="number"
                  fullWidth
                  value={this.state.CO}
                  onChange={this.handleChange("CO")}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="NO"
                  label="NO"
                  margin="normal"
                  type="number"
                  fullWidth
                  value={this.state.NO}
                  onChange={this.handleChange("NO")}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="Ts"
                  label="Teplota spalin"
                  margin="normal"
                  type="number"
                  fullWidth
                  value={this.state.Ts}
                  onChange={this.handleChange("Ts")}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="Tm"
                  label="Teplota místnosti"
                  margin="normal"
                  type="number"
                  fullWidth
                  value={this.state.Tm}
                  onChange={this.handleChange("Tm")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridCell}>
                <TextField
                  id="prebytek_vzduchu"
                  label="Přebytek vzduchu"
                  margin="normal"
                  fullWidth
                  readOnly
                  value={prebytek_vz(this.state.O2).toFixed(2)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="refO2"
                  label="Referenční obsah kyslíku"
                  margin="normal"
                  fullWidth
                  readOnly
                  value={paliva[this.state.palivo].RefO2}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="prepocitane_CO"
                  label="Přepočítané CO"
                  margin="normal"
                  fullWidth
                  readOnly
                  value={prepocet_sp(paliva[this.state.palivo].RefO2, this.state.O2, this.state.CO).toFixed(0)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="prepocitane_NO"
                  label="Přepočítané NO"
                  margin="normal"
                  fullWidth
                  readOnly
                  value={prepocet_sp(paliva[this.state.palivo].RefO2, this.state.O2, this.state.NO).toFixed(0)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="CO2"
                  label="CO2"
                  margin="normal"
                  fullWidth
                  readOnly
                  value={vypocti_CO2(this.state.O2, this.state.palivo).toFixed(1)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="ETA"
                  label="ETA"
                  margin="normal"
                  fullWidth
                  readOnly
                  value={(
                    100 - kom_ztrata(this.state.Ts, this.state.Tm, this.state.O2, this.state.palivo).toFixed(1)
                  ).toString()}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  id="ztrata"
                  label="Komínová ztráta"
                  margin="normal"
                  fullWidth
                  readOnly
                  value={kom_ztrata(this.state.Ts, this.state.Tm, this.state.O2, this.state.palivo)
                    .toFixed(1)
                    .toString()}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Prepocet);
