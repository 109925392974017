import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, Paper, TextField } from "@material-ui/core";

import { getTZFromState } from "../../../services/redux/rootReducer.js";
import { updateTZ } from "../../../services/redux/Settings/Settings.thunks.js";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
  },
});

class TZ extends Component {
  state = {
    data: {
      value: "",
    },
  };

  componentDidMount() {
    this.setState({ data: Object.assign({}, this.props.TZ) });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldSave && !this.props.shouldSave) {
      this.props.updateTZ(this.state.data);
    }
  }

  handleChange = (what, type) => (evt) => {
    var data = this.state.data;
    data[what] = evt.target.value;
    this.setState({ data: data });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.container}>
        <TextField
          id="vse"
          margin="normal"
          value={this.state.data.value}
          onChange={this.handleChange("value")}
          fullWidth
          multiline
        />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  TZ: getTZFromState(state),
});

const actionCreators = {
  updateTZ,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(TZ));
