import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";

import TViewPrint from "./components/TViewPrint";

import { withStyles } from "@material-ui/core/styles";
import { Paper, LinearProgress, Typography, Button, Zoom, IconButton } from "@material-ui/core";
import { Edit as EditIcon, Print as PrintIcon } from "@material-ui/icons";

import { getOne, savePagination } from "../../services/redux/TList/TList.thunks.js";
import {
  getOneTFromState,
  getOneTStateFromState,
  getOneTErrorFromState,
  getAllTPaginationFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";
import { setAppBarForDetail } from "../../utils/defaults";

const styles = (theme) => ({
  root: {
    width: "21cm",
    overflowX: "auto",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
});

class TView extends Component {
  componentDidMount() {
    this.props.getOne(this.props.match.params.id);

    setAppBarForDetail(this.props, "/t/list", {
      button: (
        <ReactToPrint
          key="print"
          trigger={() => (
            <IconButton color="inherit">
              <PrintIcon />
            </IconButton>
          )}
          content={() => this.printRef}
        />
      ),
      buttonMenu: null,
    });
  }

  goEdit = () => {
    this.props.history.push("/t/detail/" + this.props.match.params.id);
  };

  render() {
    const { classes, theme } = this.props;
    const data = this.props.OneData;

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          {data ? `${data.adresa}, ${data.rok}` : this.props.match.params.id}
        </Typography>
        <Paper className={classes.root}>
          {this.props.OneDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <TViewPrint data={data} ref={(el) => (this.printRef = el)} />
        </Paper>

        <Zoom in={true} timeout={theme.transitions.duration.enteringScreen} unmountOnExit>
          <Button variant="fab" className={classes.fab} color="secondary" onClick={this.goEdit}>
            <EditIcon />
          </Button>
        </Zoom>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  OneData: getOneTFromState(state),
  OneDataState: getOneTStateFromState(state),
  OneDataError: getOneTErrorFromState(state),
  Pagination: getAllTPaginationFromState(state),
});

const actionCreators = {
  getOne,
  savePagination,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles, { withTheme: true })(TView)));
