import React, { Component } from "react";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, DialogActions, Button, DialogContent, TextField } from "@material-ui/core";

class ShodaVyrobkuDetail extends Component {
  state = {
    data: {
      cislo: "",
      popis: "",
    },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open === true) {
      this.setState({
        data: {
          cislo: "",
          popis: "",
        },
      });
    }
  }

  handleCancel = () => {
    this.props.onClose(false);
  };

  handleOk = () => {
    this.props.onClose(this.state.data);
  };

  handleChange = (name) => (event) => {
    let newData = this.state.data;
    newData[name] = event.target.value;
    this.setState({ data: newData, saved: false });
  };

  render() {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        {...this.props}
      >
        <DialogTitle id="confirmation-dialog-title">Shoda Výrobku</DialogTitle>
        <DialogContent>
          <TextField
            id="cislo"
            label="Číslo"
            margin="normal"
            value={this.state.data.cislo}
            onChange={this.handleChange("cislo")}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            id="popis"
            label="Popis"
            margin="normal"
            value={this.state.data.popis}
            onChange={this.handleChange("popis")}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="default">
            Zrušit
          </Button>
          <Button onClick={this.handleOk} color="secondary" variant="contained">
            Ulož
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ShodaVyrobkuDetail.propTypes = {
  onClose: PropTypes.func,
};

export default ShodaVyrobkuDetail;
