import React /*, { lazy, Suspense }*/ from "react";
import { Route, Switch } from "react-router-dom";
import AppBarWithDrawer from "./components/AppBarWithDrawer/AppBarWithDrawer";

import EmptyLoading from "./scenes/EmptyLoading/EmptyLoading";
import { ROUTE_PATHS } from "./constants/routePaths";
import Login from "./scenes/Login/Login";

import RZList from "./scenes/RZ/RZList";
import RZDetail from "./scenes/RZ/RZDetail";
import RZView from "./scenes/RZ/RZView";

import PZList from "./scenes/PZ/PZList";
import PZDetail from "./scenes/PZ/PZDetail";
import PZView from "./scenes/PZ/PZView";

import TList from "./scenes/T/TList";
import TDetail from "./scenes/T/TDetail";
import TView from "./scenes/T/TView";

import SList from "./scenes/S/SList";
import SDetail from "./scenes/S/SDetail";
import SView from "./scenes/S/SView";

import PRList from "./scenes/PR/PRList";
import PRDetail from "./scenes/PR/PRDetail";
import PRView from "./scenes/PR/PRView";

import AList from "./scenes/A/AList";
import ADetail from "./scenes/A/ADetail";

import ZZList from "./scenes/ZZ/ZZList";
import ZZDetail from "./scenes/ZZ/ZZDetail";
import ZZView from "./scenes/ZZ/ZZView";

import Settings from "./scenes/Settings/Settings";
import Prepocet from "./scenes/Prepocet/Prepocet";
import Stats from "./scenes/Stats/Stats";
import Logs from "./scenes/Logs/LogsList";

//const Settings = lazy(() => import("./scenes/Settings/Settings"));
//const Stats = lazy(() => import("./scenes/Stats/Stats"));

/*function WaitingComponent(Component, appBarProps) {
  return (props) => (
    <Suspense fallback={<EmptyLoading />}>
      <Component {...props} {...appBarProps} />
    </Suspense>
  );
}*/

const AppRoutes = (props) => (
  <AppBarWithDrawer {...props}>
    <Routes />
  </AppBarWithDrawer>
);

const Routes = (appBarProps) => (
  <Switch>
    <Route exact path={ROUTE_PATHS.ROOT} component={Login} />
    <Route exact path={ROUTE_PATHS.EXAMPLE} component={EmptyLoading} />

    <Route exact path={ROUTE_PATHS.RZ_DETAIL} render={(p) => <RZDetail {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.RZ_VIEW} render={(p) => <RZView {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.RZ_LIST} render={(p) => <RZList {...p} {...appBarProps} />} />

    <Route exact path={ROUTE_PATHS.PZ_DETAIL} render={(p) => <PZDetail {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.PZ_VIEW} render={(p) => <PZView {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.PZ_LIST} render={(p) => <PZList {...p} {...appBarProps} />} />

    <Route exact path={ROUTE_PATHS.T_DETAIL} render={(p) => <TDetail {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.T_VIEW} render={(p) => <TView {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.T_LIST} render={(p) => <TList {...p} {...appBarProps} />} />

    <Route exact path={ROUTE_PATHS.S_DETAIL} render={(p) => <SDetail {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.S_VIEW} render={(p) => <SView {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.S_LIST} render={(p) => <SList {...p} {...appBarProps} />} />

    <Route exact path={ROUTE_PATHS.PR_DETAIL} render={(p) => <PRDetail {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.PR_VIEW} render={(p) => <PRView {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.PR_LIST} render={(p) => <PRList {...p} {...appBarProps} />} />

    <Route exact path={ROUTE_PATHS.A_DETAIL} render={(p) => <ADetail {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.A_LIST} render={(p) => <AList {...p} {...appBarProps} />} />

    <Route exact path={ROUTE_PATHS.ZZ_DETAIL} render={(p) => <ZZDetail {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.ZZ_VIEW} render={(p) => <ZZView {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.ZZ_LIST} render={(p) => <ZZList {...p} {...appBarProps} />} />

    <Route exact path={ROUTE_PATHS.SETTINGS} render={(p) => <Settings {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.PREPOCET_SPALIN} render={(p) => <Prepocet {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.STATS} render={(p) => <Stats {...p} {...appBarProps} />} />
    <Route exact path={ROUTE_PATHS.LOGS} render={(p) => <Logs {...p} {...appBarProps} />} />
  </Switch>
);

export default AppRoutes;
