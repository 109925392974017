import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { TextField, LinearProgress, Typography, Paper } from "@material-ui/core/";
import {} from "@material-ui/icons";
import Image from "./Image.js";

import {
  getImagesSettingsFromState,
  getRZImageStateFromState,
  getRZImageNewDataFromState,
  getPZImageStateFromState,
  getPZImageNewDataFromState,
  getOnePZFromState,
  getOneRZFromState,
} from "../../services/redux/rootReducer.js";
import {
  addImage as addImageRZ,
  removeImage as removeImageRZ,
  updateImageOrder as updateImageOrderRZ,
} from "../../services/redux/RZList/RZList.thunks.js";
import {
  addImage as addImagePZ,
  removeImage as removeImagePZ,
  updateImageOrder as updateImageOrderPZ,
} from "../../services/redux/PZList/PZList.thunks.js";
import { DATA_STATE } from "../../services/redux/constants.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  margin: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  headerMargin: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
});

class Images extends Component {
  state = {
    images: [],
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.images && nextProps.images.length !== (this.props.images || []).length) {
      this.setState({ images: nextProps.images });
    }
    if (nextProps.ImageState === DATA_STATE.UPLOAD_SUCCEEDED && nextProps.NewImageData.ID === nextProps.OneData.ID) {
      let images = this.state.images;
      images.push(nextProps.NewImageData);
      this.setState({ images });

      document.getElementById("file").value = "";
    }
  }

  onAdd = () => {
    let data = new FormData();
    data.append("files", document.getElementById("file").files[0]);

    this.props.addImage(this.props.ID, data);
  };

  onRemoveHandler = (idx) => () => {
    let images = this.state.images;
    let removed = images.splice(idx, 1);

    this.setState({ images: images }, () => {
      this.props.removeImage(removed[0].cesta);
    });
  };

  onOrderChanged = (idx) => (dir) => () => {
    if (idx + dir < 0 || idx + dir >= this.state.images.length) {
      return;
    }
    let images = this.state.images;
    images.splice(idx + dir, 0, images.splice(idx, 1)[0]);

    this.setState({ images: images }, () => {
      var data = {};
      this.state.images.forEach((item, idx) => {
        data[item.cesta] = idx + 1;
      });

      this.props.updateImageOrder(data);
    });
  };

  render() {
    const { classes } = this.props;

    const images = (this.state.images || []).map((item, idx) => {
      return (
        <Image
          data={item}
          key={item.cesta}
          onRemove={this.onRemoveHandler(idx)}
          onOrderChanged={this.onOrderChanged(idx)}
        />
      );
    });

    return (
      <React.Fragment>
        <Typography variant="h5" className={classes.headerMargin}>
          Obrázky
        </Typography>
        <Paper className={classes.root}>
          {this.props.ImageState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          {this.props.ID === "new" ? (
            <Typography className={classes.headerMargin}>Nejdříve je třeba uložit zprávu</Typography>
          ) : (
            <TextField id="file" type="file" margin="normal" fullWidth onChange={this.onAdd} />
          )}
          {images}
        </Paper>
      </React.Fragment>
    );
  }
}

Images.propTypes = {
  images: PropTypes.array,
  ID: PropTypes.string.isRequired,
};

const mapStateToPropsRZ = (state) => ({
  ImagesSettings: getImagesSettingsFromState(state),
  ImageState: getRZImageStateFromState(state),
  NewImageData: getRZImageNewDataFromState(state),
  OneData: getOneRZFromState(state),
});

const actionCreatorsRZ = {
  addImage: addImageRZ,
  removeImage: removeImageRZ,
  updateImageOrder: updateImageOrderRZ,
};

const mapStateToPropsPZ = (state) => ({
  ImagesSettings: getImagesSettingsFromState(state),
  ImageState: getPZImageStateFromState(state),
  NewImageData: getPZImageNewDataFromState(state),
  OneData: getOnePZFromState(state),
});

const actionCreatorsPZ = {
  addImage: addImagePZ,
  removeImage: removeImagePZ,
  updateImageOrder: updateImageOrderPZ,
};

const ImagesRZ = connect(
  mapStateToPropsRZ,
  actionCreatorsRZ
)(withStyles(styles)(Images));

const ImagesPZ = connect(
  mapStateToPropsPZ,
  actionCreatorsPZ
)(withStyles(styles)(Images));

export { ImagesRZ, ImagesPZ };
