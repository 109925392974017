import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core/";
import { ArrowBack, ArrowForward, Delete as DeleteIcon } from "@material-ui/icons";
import DeleteConfirm from "../Dialogs/DeleteConfirm.js";

import { getImagesSettingsFromState } from "../../services/redux/rootReducer.js";
import { SERVER_URL } from "../../services/redux/constants.js";

const styles = (theme) => ({
  imageContainer: {
    position: "relative",
    display: "inline-block",
  },
  image: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    border: "1px solid grey",
  },
  buttonsContainer: {
    position: "absolute",
    top: theme.spacing.unit + 1,
    left: 1,
    background: "rgba(255,255,255,0.8)",
    width: `calc(100% - ${theme.spacing.unit + 2}px)`,
    boxShadow: theme.shadows[1],
    display: "flex",
  },
  button: {
    margin: `${theme.spacing.unit}px ${theme.spacing.unit / 4}px`,
    "&:first-of-type": {
      marginLeft: theme.spacing.unit,
    },
    "&:last-of-type": {
      marginRight: theme.spacing.unit,
    },
  },
  flex: {
    flex: 1,
  },
});

class Image extends Component {
  state = {
    removeConfirmOpen: false,
  };

  handleRemoveClick = () => {
    this.setState({ removeConfirmOpen: true });
  };

  remove = (shouldRemove) => {
    if (shouldRemove) {
      this.props.onRemove();
    }
    this.setState({ removeConfirmOpen: false });
  };

  render() {
    const { classes } = this.props;

    const imgProp = {};
    if (this.props.ImagesSettings.height !== "0") {
      imgProp.height = this.props.ImagesSettings.height;
    }
    if (this.props.ImagesSettings.width !== "0") {
      imgProp.width = this.props.ImagesSettings.width;
    }

    return (
      <div className={classes.imageContainer}>
        <div className={classes.buttonsContainer}>
          <IconButton
            color="secondary"
            className={classes.button}
            aria-label="Přesuň obrázek doleva"
            onClick={this.props.onOrderChanged(-1)}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            color="secondary"
            className={classes.button}
            aria-label="Přesuň obrázek doprava"
            onClick={this.props.onOrderChanged(1)}
          >
            <ArrowForward />
          </IconButton>
          <div className={classes.flex} />
          <IconButton
            color="default"
            className={classes.button}
            aria-label="Smaž obrázek"
            onClick={this.handleRemoveClick}
          >
            <DeleteIcon />
          </IconButton>
        </div>
        <img
          src={SERVER_URL + this.props.ImagesSettings.cesta + this.props.data.cesta}
          key={this.props.data.cesta}
          alt={this.props.data.nazev}
          {...imgProp}
          className={classes.image}
        />
        <DeleteConfirm
          open={this.state.removeConfirmOpen}
          onClose={this.remove}
          label="Opravdu chcete smazat tento obrázek?"
        />
      </div>
    );
  }
}

Image.propTypes = {
  data: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onOrderChanged: PropTypes.func,
};

const mapStateToProps = (state) => ({
  ImagesSettings: getImagesSettingsFromState(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(Image));
