import React, { Component } from "react";
import PropTypes from "prop-types";
import { getSQLFormatDate } from "../../../utils/date.js";

import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon, Add as AddIcon } from "@material-ui/icons";

const styles = (theme) => ({
  gridRowCell: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
  },
  rowButton: {
    marginTop: "20px",
  },
});

const emptyRow = {
  datum: getSQLFormatDate(),
  AM: "",
  PZ: "",
  poznamky: "",
};

class SDetailRow extends Component {
  state = {
    row: emptyRow,
    removeRadekConfirmOpenIdx: false,
  };

  componentDidMount() {
    this.setState({ row: this.props.row || Object.assign({ ID_hl: this.props.IDhl }, emptyRow) });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.row !== this.props.row) {
      this.setState({ row: nextProps.row || Object.assign({ ID_hl: this.props.IDhl }, emptyRow) });
    }
  }

  handleChangeRow = (name) => (event) => {
    let row = this.state.row;
    row[name] = event.target.value;
    this.setState({ row: row }, () => this.props.handleChangeRow(row));
  };

  render() {
    const { classes } = this.props;

    const row = this.state.row;

    return (
      <React.Fragment>
        <Grid container direction="row" key={row.ID || "new"}>
          <Grid item xs={12} sm={3} className={classes.gridRowCell}>
            <TextField
              id="datum"
              label="Datum"
              type="date"
              margin="normal"
              fullWidth
              value={row.datum}
              onChange={this.handleChangeRow("datum")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.gridRowCell}>
            <TextField
              id="AM"
              label="AM"
              margin="normal"
              fullWidth
              value={row.AM}
              onChange={this.handleChangeRow("AM")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.gridRowCell}>
            <TextField
              id="PZ"
              label="PZ"
              margin="normal"
              fullWidth
              multiline
              rowsMax="3"
              value={row.PZ}
              onChange={this.handleChangeRow("PZ")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridRowCell}>
            <TextField
              id="poznamky"
              label="Poznámky"
              margin="normal"
              fullWidth
              multiline
              rowsMax="5"
              value={row.poznamky}
              onChange={this.handleChangeRow("poznamky")}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={1} className={classes.gridRowCell}>
            {row.ID ? (
              <IconButton
                color="default"
                className={classes.rowButton}
                aria-label="Smaž řádek"
                onClick={this.props.handleRowRemoveClick}
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              <IconButton
                color="secondary"
                className={classes.rowButton}
                aria-label="Přidej řádek"
                onClick={this.props.handleAddRowClick}
              >
                <AddIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

SDetailRow.propTypes = {
  row: PropTypes.object,
  IDhl: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(SDetailRow);
