export const T_ACTION_TYPES = {
  FETCHING_LIST: "USER/T/FETCHING_LIST",
  FETCH_LIST_SUCCEEDED: "USER/T/FETCH_SUCCEEDED_LIST",
  FETCH_LIST_FAILED: "USER/T/FETCH_FAILED_LIST",
  FETCHING: "USER/T/FETCHING",
  FETCH_SUCCEEDED: "USER/T/FETCH_SUCCEEDED",
  FETCH_FAILED: "USER/T/FETCH_FAILED",
  UPDATING: "USER/T/UPDATING",
  UPDATE_SUCCEEDED: "USER/T/UPDATE_SUCCEEDED",
  UPDATE_FAILED: "USER/T/UPDATE_FAILED",
  REMOVING: "USER/T/REMOVING",
  REMOVE_SUCCEEDED: "USER/T/REMOVE_SUCCEEDED",
  REMOVE_FAILED: "USER/T/REMOVE_FAILED",
  COPYING: "USER/T/COPYING",
  COPY_SUCCEEDED: "USER/T/COPY_SUCCEEDED",
  COPY_FAILED: "USER/T/COPY_FAILED",

  PAGINATION: "USER/T/PAGINATION",

  ADDING_ROW: "USER/T/ADDING_ROW",
  ADD_ROW_SUCCEEDED: "USER/T/ADD_ROW_SUCCEEDED",
  ADD_ROW_FAILED: "USER/T/ADD_ROW_FAILED",
  REMOVING_ROW: "USER/T/REMOVING_ROW",
  REMOVE_ROW_SUCCEEDED: "USER/T/REMOVE_ROW_SUCCEEDED",
  REMOVE_ROW_FAILED: "USER/T/REMOVE_ROW_FAILED",
  UPDATING_ROW_ORDER: "USER/T/UPDATING_ROW_ORDER",
  UPDATE_ROW_ORDER_SUCCEEDED: "USER/T/UPDATE_SUCCEEDED_ROW_ORDER",
  UPDATE_ROW_ORDER_FAILED: "USER/T/UPDATE_FAILED_ROW_ORDER",
};

export function savePaginationAction(page, searchTerm, sort, sortOrder) {
  return {
    type: T_ACTION_TYPES.PAGINATION,
    page,
    searchTerm,
    sort,
    sortOrder,
  };
}
export function addTRowSucceeded(data) {
  return {
    type: T_ACTION_TYPES.ADD_ROW_SUCCEEDED,
    data: data.data,
  };
}
