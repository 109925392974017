import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, Input, MenuItem, TextField } from "@material-ui/core/";
import {} from "@material-ui/icons";

import { getVyrobceFromState, getShodaVyrobkuFromState } from "../../services/redux/rootReducer.js";

const styles = (theme) => ({
    formControl: {
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    denseMargin: {
        marginBottom: theme.spacing.unit,
    },
});

class PickOrType extends Component {
    state = {
        value: "",
        value2: "",
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.defaultValue) {
            if (
                (this.props.type === "vyrobce" &&
                    this.props.Vyrobce.some((item) => item.vyrobce === nextProps.defaultValue)) ||
                (this.props.type === "shoda" && this.props.Shoda.some((item) => item.cislo === nextProps.defaultValue))
            ) {
                this.setState({ value: nextProps.defaultValue });
            } else {
                this.setState({ value: "-", value2: nextProps.defaultValue });
            }
        }
    }

    onChange = (event) => {
        this.setState({ value: event.target.value }, this.handlePropagation);
    };

    onChange2 = (event) => {
        this.setState({ value2: event.target.value }, this.handlePropagation);
    };

    handlePropagation = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.value === "-" ? this.state.value2 : this.state.value);
        }
    };

    render() {
        const { classes } = this.props;

        let items = [];
        if (this.props.type === "vyrobce") {
            items = (this.props.Vyrobce || []).map((item, idx) => {
                return (
                    <MenuItem key={idx} value={item.vyrobce}>
                        {item.vyrobce}
                    </MenuItem>
                );
            });
        } else if (this.props.type === "shoda") {
            items = (this.props.Shoda || []).map((item, idx) => {
                return (
                    <MenuItem key={idx} value={item.cislo}>
                        {item.cislo} - {item.popis}
                    </MenuItem>
                );
            });
        }

        return (
            <React.Fragment>
                <FormControl className={classes.formControl} margin="normal">
                    <InputLabel shrink htmlFor={this.props.id + "-placeholder"}>
                        {this.props.label}
                    </InputLabel>
                    <Select
                        value={this.state.value}
                        onChange={this.onChange}
                        displayEmpty
                        input={<Input name={this.props.id} id={this.props.id + "-placeholder"} />}
                        name={this.props.id}
                        className={classes.selectEmpty}
                    >
                        <MenuItem value="">
                            <em>Nic</em>
                        </MenuItem>
                        <MenuItem value="-">
                            <em>Vlastní</em>
                        </MenuItem>
                        {items}
                    </Select>
                </FormControl>
                {this.state.value === "-" && (
                    <TextField
                        value={this.state.value2}
                        onChange={this.onChange2}
                        className={classes.denseMargin}
                        fullWidth
                    />
                )}
            </React.Fragment>
        );
    }
}

PickOrType.propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    Vyrobce: getVyrobceFromState(state),
    Shoda: getShodaVyrobkuFromState(state),
});

const actionCreators = {};

export default connect(
    mapStateToProps,
    actionCreators
)(withStyles(styles)(PickOrType));
