import axios from "axios";

export function getPage(params) {
  return axios.get("pruchozi/index.php", { params: params });
}

export function getOne(id, params) {
  params = params || {};
  params.ID = id;
  return axios.get("pruchozi/index.php", { params: params });
}

export function getMinYear() {
  return axios.get("pruchozi/years.php");
}

export function update(id, data) {
  let params = { ID: id };
  return axios.post("pruchozi/index.php", data, { params: params });
}

export function remove(id) {
  let params = { ID: id };
  return axios.delete("pruchozi/index.php", { params: params });
}

export function copy(id) {
  let params = { ID: id };
  return axios.post("pruchozi/copy.php", null, { params: params });
}

export function addImage(id, data) {
  return axios.post("pruchozi/images.php", data, { params: { ID: id } });
}

export function removeImage(cesta) {
  return axios.delete("pruchozi/images.php", { params: { cesta: cesta } });
}

export function updateImageOrder(data) {
  return axios.patch("pruchozi/images.php", data);
}
