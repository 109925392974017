import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, Paper, FormControlLabel, Switch, TextField, Grid, Typography } from "@material-ui/core";

import { getImagesSettingsFromState } from "../../../services/redux/rootReducer.js";
import { updateImages } from "../../../services/redux/Settings/Settings.thunks.js";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
  },
});

class Images extends Component {
  state = {
    data: {
      imgadding: "1",
      width: 0,
      height: 230,
    },
  };

  componentDidMount() {
    this.setState({ data: Object.assign({}, this.props.ImagesSettings) });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldSave && !this.props.shouldSave) {
      this.props.updateImages(this.state.data);
    }
  }

  handleChange = (what, type) => (evt) => {
    var data = this.state.data;
    if (type === "check") {
      data[what] = evt.target.checked ? "1" : "0";
    } else {
      data[what] = evt.target.value;
    }
    this.setState({ data: data });
  };

  render() {
    const { classes } = this.props;

    let imgadding = this.state.data.imgadding === "1";

    return (
      <Paper className={classes.container}>
        <Grid container>
          <Grid item>
            <FormControlLabel
              control={<Switch checked={imgadding} onChange={this.handleChange("imgadding", "check")} value="1" />}
              label="Povolit přidávání obrázků k Revizním a Průchozím zprávám"
            />
          </Grid>
          <Grid item>
            <Typography variant="body1">Rozlišení obrázků</Typography>
            <TextField
              id="width"
              label="Šířka"
              margin="normal"
              value={this.state.data.width}
              onChange={this.handleChange("width")}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              id="height"
              label="Výška"
              margin="normal"
              value={this.state.data.height}
              onChange={this.handleChange("height")}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <Typography variant="caption">(v px; 0 ... dopočítat nebo původní velikost)</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  ImagesSettings: getImagesSettingsFromState(state),
});

const actionCreators = {
  updateImages,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(Images));
