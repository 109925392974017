import React, { Component } from "react";
import { connect } from "react-redux";
import { Provider } from "react-redux";
import store from "./store";
import Routes from "./Routes";
import axios from "axios";

import { HashRouter as Router } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { indigo, blue, red } from "@material-ui/core/colors";

import { getSettings } from "./services/redux/Settings/Settings.thunks.js";
import { getUserName } from "./services/redux/Login/Login.thunks.js";
import {
  getSettingsStateFromState,
  getLoginDataStateFromState,
  isSettingsLoadedFromState,
  getUserNameFromState,
} from "./services/redux/rootReducer.js";
import { DATA_STATE, SERVER_URL } from "./services/redux/constants.js";

axios.defaults.baseURL = SERVER_URL + "api/";
axios.defaults.withCredentials = true;

class App2 extends Component {
  state = {
    isAdmin: false,
  };
  componentDidMount() {
    this.props.getUserName();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.User !== this.props.User) {
      if (nextProps.User && nextProps.User.indexOf("a_") === 0) {
        this.setState({ isAdmin: true });
      } else {
        this.setState({ isAdmin: false });
      }
    }
  }

  render() {
    if (
      (this.props.SettingsState === DATA_STATE.NOT_INITIALIZED &&
        [DATA_STATE.IN_PROGRESS, DATA_STATE.INITIALIZED].indexOf(this.props.LoginState) !== -1) ||
      (this.props.SettingsState === DATA_STATE.IN_PROGRESS && !this.props.IsSettings)
    ) {
      return <CircularProgress />;
    }

    const theme = createMuiTheme({
      palette: {
        primary: { main: this.state.isAdmin ? blue[50] : indigo[500] },
        secondary: { main: red.A400 },
      },
      typography: {
        useNextVariants: true,
      },
    });

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Routes isAdmin={this.state.isAdmin} />
        </Router>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  SettingsState: getSettingsStateFromState(state),
  IsSettings: isSettingsLoadedFromState(state),
  LoginState: getLoginDataStateFromState(state),
  User: getUserNameFromState(state),
});

const actionCreators = {
  getUserName,
  getSettings,
};

var WrappedApp = connect(
  mapStateToProps,
  actionCreators
)(App2);

class App extends Component {
  componentDidMount() {
    navigator.serviceWorker.addEventListener("controllerchange", function() {
      window.location.reload();
    });
  }
  render() {
    return (
      <Provider store={store}>
        <WrappedApp />
      </Provider>
    );
  }
}

export default App;
