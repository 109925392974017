function persist(key, value) {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (e) {
    // ignore error
  }
}

function load(key) {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue == null) {
      return undefined;
    }
    return JSON.parse(serializedValue);
  } catch (e) {
    return undefined;
  }
}

const LOCAL_STORAGE_KEY = "state";

export const saveState = (data) => persist(LOCAL_STORAGE_KEY, data);

export const loadState = () => load(LOCAL_STORAGE_KEY);
