import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core/";
import { Description as DescriptionIcon, ExpandLess, ExpandMore } from "@material-ui/icons";

import { ROUTE_PATHS } from "../../constants/routePaths";
import { savePagination } from "../../services/redux/RZList/RZList.thunks.js";
import {
  getRZMinYearFromState,
  getAllRZPaginationFromState,
  appDrawerShownFromState,
  getOneRZFromState,
} from "../../services/redux/rootReducer.js";

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

class RZnavList extends Component {
  state = {
    open: false,
  };

  componentDidMount() {
    this.defaultOpen(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.defaultOpen(nextProps);
  }

  defaultOpen = (props) => {
    this.setState({
      open: props.pathname.indexOf(ROUTE_PATHS.RZ) !== -1,
    });
  };

  handleSubMenu = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  switchToYear = (year) => () => {
    this.props.savePagination({ page: 0, searchTerm: "", year: year });
    this.props.redirect("/rz/list/" + year, true)();
  };

  render() {
    const { classes } = this.props;

    let years = {};
    const thisYear = new Date().getFullYear();
    let yearSelected = false;
    if (this.props.Pagination && this.props.Pagination.year) {
      yearSelected = this.props.Pagination.year;
    } else if (this.props.OneData && this.props.OneData.rok) {
      yearSelected = parseInt(this.props.OneData.rok, 10);
    }

    for (let year = this.props.RZMinYear; year <= thisYear; year++) {
      years[year] = (
        <ListItem
          button
          className={classes.nested}
          onClick={this.switchToYear(year)}
          selected={
            this.props.pathname.indexOf(ROUTE_PATHS.RZ) !== -1 &&
            yearSelected === year &&
            !this.props.Pagination.searchTerm
          }
          key={year}
        >
          {this.props.DrawerOpen ? (
            <ListItemText inset primary={year} />
          ) : (
            <ListItemText primary={(year + "").substr(2)} />
          )}
        </ListItem>
      );
    }

    return (
      <React.Fragment>
        <ListItem
          button
          onClick={this.props.redirect("/rz/list")}
          selected={this.props.pathname.indexOf(ROUTE_PATHS.RZ) !== -1}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText inset primary="Revizní zprávy" />
          {this.props.DrawerOpen && (
            <ListItemSecondaryAction>
              <IconButton onClick={this.handleSubMenu}>{this.state.open ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {this.props.DrawerOpen
              ? Object.values(years)
              : [yearSelected !== thisYear ? years[yearSelected] : years[thisYear - 1], years[thisYear]]}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
}

RZnavList.propTypes = {
  redirect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  RZMinYear: getRZMinYearFromState(state),
  Pagination: getAllRZPaginationFromState(state),
  DrawerOpen: appDrawerShownFromState(state),
  OneData: getOneRZFromState(state),
});

const actionCreators = {
  savePagination,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles)(RZnavList)));
