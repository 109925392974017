import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Avatar,
  FormControl,
  InputLabel,
  Input,
  CssBaseline,
  LinearProgress,
} from "@material-ui/core";
import { LockOutlined as LockIcon } from "@material-ui/icons";

import { login } from "../../services/redux/Login/Login.thunks.js";
import { getLoginDataStateFromState, getLoginFromState, getError } from "../../services/redux/rootReducer.js";
import { DATA_STATE, ERROR } from "../../services/redux/constants.js";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    margin: theme.spacing.unit * 3,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
});

class Login extends Component {
  state = {
    userName: "", //test
    userPasswd: "", //4u8ugcEc
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.loginDataState === DATA_STATE.INITIALIZED &&
      (!nextProps.Error || nextProps.Error.errorType !== ERROR.UNAUTHORIZED)
    ) {
      this.props.history.push("/rz/list");
    }
  }

  login = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.login(this.state.userName, this.state.userPasswd);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Grid container className={classes.root} direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Paper className={classes.paper}>
              {this.props.loginDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
              <Avatar className={classes.avatar}>
                <LockIcon />
              </Avatar>
              <Typography variant="h5">Log in</Typography>
              <form className={classes.container} onSubmit={this.login}>
                <FormControl margin="normal" required fullWidth error={this.props.loginDataState === DATA_STATE.FAILED}>
                  <InputLabel htmlFor="userName">User Name</InputLabel>
                  <Input
                    id="userName"
                    name="userName"
                    autoFocus
                    value={this.state.userName}
                    onChange={this.handleChange("userName")}
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth error={this.props.loginDataState === DATA_STATE.FAILED}>
                  <InputLabel htmlFor="userPasswd">Password</InputLabel>
                  <Input
                    name="userPasswd"
                    type="password"
                    id="userPasswd"
                    autoComplete="current-password"
                    value={this.state.userPasswd}
                    onChange={this.handleChange("userPasswd")}
                  />
                </FormControl>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.button}>
                  Log In
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginData: getLoginFromState(state),
  loginDataState: getLoginDataStateFromState(state),
  Error: getError(state),
});

const actionCreators = {
  login,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles)(Login)));
