import React, { Component } from "react";

class HeaderCech extends Component {
  render() {
    return (
      <div
        id="hlavicka"
        style={{
          font: "13.5pt/1.5 'arial'",
          borderBottom: "2pt solid black",
          textAlign: "center",
          marginTop: "20pt",
        }}
      >
        David Sedlařík, Na Bahně 403/20, 747 05 Opava 5, tel: 731 715 620
      </div>
    );
  }
}

export default HeaderCech;
