import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

import { getVyrobceFromState } from "../../../services/redux/rootReducer.js";
import { removeVyrobce } from "../../../services/redux/Settings/Settings.thunks.js";
import ButtonsOrMenu from "../../../components/Buttons/ButtonsOrMenu.js";
import DeleteConfirm from "../../../components/Dialogs/DeleteConfirm.js";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing.unit * 3,
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing.unit * 2,
  },
  floatRight: {
    float: "right",
  },
});

class Vyrobce extends Component {
  state = {
    removeConfirmOpen: false,
    removeID: null,
  };

  handleRemoveClick = (id) => () => {
    this.setState({ removeConfirmOpen: true, removeID: id });
  };
  remove = (shouldRemove) => {
    if (shouldRemove) {
      this.props.removeVyrobce(this.state.removeID);
    }
    this.setState({ removeConfirmOpen: false });
  };

  generateButtons = (id) => [
    {
      icon: <DeleteIcon />,
      label: "Smaž",
      onClick: this.handleRemoveClick(id),
    },
  ];

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.container}>
        <Table className={classes.table} padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>Výrobce</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.Vyrobce &&
              this.props.Vyrobce.map((i) => {
                return (
                  <TableRow key={i.ID}>
                    <TableCell>{i.vyrobce}</TableCell>
                    <TableCell>
                      <ButtonsOrMenu buttons={this.generateButtons(i.ID)} up="md" className={classes.floatRight} />
                    </TableCell>
                  </TableRow>
                );
              })}
            {null}
          </TableBody>
        </Table>
        <DeleteConfirm
          open={this.state.removeConfirmOpen}
          onClose={this.remove}
          label="Opravdu chcete smazat výrobce?"
        />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  Vyrobce: getVyrobceFromState(state),
});

const actionCreators = {
  removeVyrobce,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(Vyrobce));
