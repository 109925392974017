import { loggingIn, loggedIn, loginFailed, fetchingUser, userFetched, userFetchFailed } from "./Login.actions.js";
import { getSettings } from "../Settings/Settings.thunks.js";
import { ERROR } from "../constants.js";

export function login(userName, userPasswd) {
  return async (dispatch, getState, { api }) => {
    dispatch(loggingIn()); // loading

    // priprava dat
    let response;

    try {
      response = await api.login.login(userName, userPasswd);
    } catch (e) {
      // odchyceni chyb
      if (e.response && e.response.status === 403) {
        dispatch(loginFailed(ERROR.UNAUTHORIZED));
      } else {
        dispatch(loginFailed(ERROR.UNKNOWN));
      }
      return;
    }

    if (response.data.ok === false) {
      dispatch(loginFailed(ERROR.UNAUTHORIZED));
      return;
    }

    // vsechno ok - v akci predam data
    dispatch(loggedIn(response.data));

    getSettings()(dispatch, getState, { api });
  };
}

export function logout() {
  return async (dispatch, getState, { api }) => {
    await api.login.logout();
    dispatch(loginFailed(ERROR.UNAUTHORIZED));
  };
}

export function getUserName() {
  return async (dispatch, getState, { api }) => {
    dispatch(fetchingUser()); // loading

    // priprava dat
    let response;

    try {
      response = await api.login.getUserName();
    } catch (e) {
      if (e.response && e.response.status === 403) {
        dispatch(userFetchFailed(ERROR.UNAUTHORIZED));
      } else {
        dispatch(userFetchFailed(ERROR.UNKNOWN));
      }
      return;
    }

    if (response.data.ok === false) {
      dispatch(userFetchFailed(ERROR.UNAUTHORIZED));
      return;
    }

    dispatch(userFetched(response.data));

    getSettings()(dispatch, getState, { api });
  };
}
