import React, { Component } from "react";
import { connect } from "react-redux";
import { DATA_STATE } from "../../services/redux/constants.js";

import { withStyles, AppBar, Tabs, Tab, Zoom, Button, CircularProgress } from "@material-ui/core";
import { Add as AddIcon, Save as SaveIcon } from "@material-ui/icons";
import SwipeableViews from "react-swipeable-views";

import { updateUser, updateVyrobce, updateShodaVyrobku } from "../../services/redux/Settings/Settings.thunks.js";
import { getSettingsStateFromState } from "../../services/redux/rootReducer.js";

import Uzivatele from "./components/Uzivatele.js";
import UzivateleDetail from "./components/UzivateleDetail.js";
import Images from "./components/Images.js";
import Vyrobce from "./components/Vyrobce.js";
import VyrobceDetail from "./components/VyrobceDetail.js";
import ShodaVyrobku from "./components/ShodaVyrobku.js";
import ShodaVyrobkuDetail from "./components/ShodaVyrobkuDetail.js";
import Options from "./components/Options.js";
import TZ from "./components/TZ.js";
import LoggingList from "./components/LoggingList.js";

const styles = (theme) => ({
  tabRoot: {
    minWidth: "160px",
    textTransform: "initial",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
});

class Settings extends Component {
  state = {
    value: 0,
    showUserDetail: false,
    saveImages: false,
    showVyrobceDetail: false,
    showShodaDetail: false,
    saveOptions: false,
    saveTZ: false,
  };

  setAppBar = (props) => {
    props = props || this.props;
    if (props.setAppBarOptions) {
      props.setAppBarOptions({
        noPadding: true,
      });
    }
  };

  componentDidMount() {
    this.setAppBar();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  // users
  onUzivateleDetailClick = () => {
    this.setState({ showUserDetail: true });
  };

  onUzivateleDetailClose = (result) => {
    if (result !== false) {
      this.props.updateUser(result);
    }
    this.setState({ showUserDetail: false });
  };

  // images
  onImagesSaveClick = () => {
    this.setState({ saveImages: true }, () => {
      this.setState({ saveImages: false });
    });
  };

  // vyrobce
  onVyrobceDetailClick = () => {
    this.setState({ showVyrobceDetail: true });
  };

  onVyrobceDetailClose = (result) => {
    if (result !== false) {
      this.props.updateVyrobce(result);
    }
    this.setState({ showVyrobceDetail: false });
  };

  // shoda vyrobku
  onShodaDetailClick = () => {
    this.setState({ showShodaDetail: true });
  };

  onShodaDetailClose = (result) => {
    if (result !== false) {
      this.props.updateShodaVyrobku(result);
    }
    this.setState({ showShodaDetail: false });
  };

  // options/ostatni nastaveni
  onOptionsSaveClick = () => {
    this.setState({ saveOptions: true }, () => {
      this.setState({ saveOptions: false });
    });
  };

  // tz vychozi nastaveni
  onTZSaveClick = () => {
    this.setState({ saveTZ: true }, () => {
      this.setState({ saveOTZ: false });
    });
  };

  render() {
    const { classes, theme } = this.props;

    const addButton = [
      {
        onClick: this.onUzivateleDetailClick,
        icon: <AddIcon />,
      },
      {
        onClick: this.onImagesSaveClick,
        inProgress: this.props.SettingsState === DATA_STATE.UPDATE_IN_PROGRESS,
        icon: <SaveIcon />,
      },
      {
        onClick: this.onVyrobceDetailClick,
        icon: <AddIcon />,
      },
      {
        onClick: this.onShodaDetailClick,
        icon: <AddIcon />,
      },
      {
        onClick: this.onTZSaveClick,
        inProgress: this.props.SettingsState === DATA_STATE.UPDATE_IN_PROGRESS,
        icon: <SaveIcon />,
      },
      null,
      {
        onClick: this.onOptionsSaveClick,
        inProgress: this.props.SettingsState === DATA_STATE.UPDATE_IN_PROGRESS,
        icon: <SaveIcon />,
      },
    ];

    return (
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
            scrollable
            scrollButtons="auto"
          >
            <Tab disableRipple classes={{ root: classes.tabRoot }} label="Osobní informace" />
            <Tab disableRipple classes={{ root: classes.tabRoot }} label="Nastavení obrázků" />
            <Tab disableRipple classes={{ root: classes.tabRoot }} label="Výrobce" />
            <Tab disableRipple classes={{ root: classes.tabRoot }} label="Doklad o shodě výrobku" />
            <Tab disableRipple classes={{ root: classes.tabRoot }} label="Technická zpráva" />
            <Tab disableRipple classes={{ root: classes.tabRoot }} label="Export databáze" />
            <Tab disableRipple classes={{ root: classes.tabRoot }} label="Ostatní" />
            <Tab disableRipple classes={{ root: classes.tabRoot }} label="Log chyb" />
          </Tabs>
        </AppBar>
        <SwipeableViews index={this.state.value} onChangeIndex={this.handleChangeIndex}>
          <Uzivatele />
          <Images shouldSave={this.state.saveImages} />
          <Vyrobce />
          <ShodaVyrobku />
          <TZ shouldSave={this.state.saveTZ} />
          <div>export db</div>
          <Options shouldSave={this.state.saveOptions} />
          <LoggingList />
        </SwipeableViews>

        {addButton.map(
          (fab, index) =>
            fab && (
              <div className={classes.fab} key={index}>
                <Zoom
                  in={this.state.value === index}
                  timeout={theme.transitions.duration.enteringScreen}
                  style={{
                    transitionDelay: `${this.state.value === index ? theme.transitions.duration.leavingScreen : 0}ms`,
                  }}
                  unmountOnExit
                >
                  <Button variant="fab" color="secondary" onClick={fab.onClick}>
                    {fab.icon}
                  </Button>
                </Zoom>
                {this.state.value === index &&
                  fab.inProgress && <CircularProgress size={68} className={classes.fabProgress} />}
              </div>
            )
        )}
        <UzivateleDetail onClose={this.onUzivateleDetailClose} open={this.state.showUserDetail} />
        <VyrobceDetail onClose={this.onVyrobceDetailClose} open={this.state.showVyrobceDetail} />
        <ShodaVyrobkuDetail onClose={this.onShodaDetailClose} open={this.state.showShodaDetail} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  SettingsState: getSettingsStateFromState(state),
});

const actionCreators = {
  updateUser,
  updateVyrobce,
  updateShodaVyrobku,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles, { withTheme: true })(Settings));
