import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import { print as printStyles } from "../../constants/commonStyles.js";
import { getUserNameFromState } from "../../services/redux/rootReducer.js";

import FooterValch from "./FooterValch.js";
import FooterDavid from "./FooterDavid.js";

const styles = {
  footer: printStyles.footer,
};

class Header extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.footer}>
        {(this.props.User === "test" || this.props.User === "vlastik") && <FooterValch />}
        {this.props.User === "david" && <FooterDavid />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  User: getUserNameFromState(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(Header));
