import React, { Component } from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Select, Input, MenuItem } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends Component {
  handleChangePageClick = (event) => {
    this.props.onChangePage(event, event.target.value);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1));
  };

  render() {
    const { classes, count, page, rowsPerPage } = this.props;

    const maxPage = Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1);
    let pages = [];
    for (let i = 0; i <= maxPage; i++) {
      pages.push(i);
    }

    return (
      <div className={classes.root}>
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
          <KeyboardArrowLeft />
        </IconButton>
        <Select
          value={this.props.page}
          onChange={this.handleChangePageClick}
          input={<Input name="uzivatel" id={"uzivatel-placeholder"} />}
          name="uzivatel"
          className={classes.selectEmpty}
        >
          {pages.map((page) => (
            <MenuItem value={page} key={page}>
              {page + 1}
            </MenuItem>
          ))}
        </Select>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(actionsStyles, { withTheme: true })(TablePaginationActions);
