import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ButtonsOrMenu from "../../components/Buttons/ButtonsOrMenu.js";
import { getIfUnd, getIfUndOrNull, setAppBarForList } from "../../utils/defaults.js";

import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
  Button,
  Zoom,
  TableSortLabel,
  Grid,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Edit as EditIcon,
  FileCopyOutlined as CopyIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import amber from "@material-ui/core/colors/amber";

import { getAll, savePagination, copy as copyRZ } from "../../services/redux/RZList/RZList.thunks.js";
import { saveStrankovani } from "../../services/redux/Settings/Settings.thunks.js";
import {
  getAllRZFromState,
  getAllRZStateFromState,
  getAllRZErrorFromState,
  getAllRZPaginationFromState,
  getStrankovaniFromState,
  getOneRZCopiedFromState,
  getOneRZFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";
import TablePaginationActions from "../../components/Layout/TablePaginationActions.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    //minWidth: 700,
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  gridContainer: {
    paddingTop: theme.spacing.unit / 2,
  },
  grid: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
  endPadding: {
    height: "100px",
  },
  wrap: {
    whiteSpace: "pre-wrap",
    fontSize: theme.typography.fontSize,
  },
  warningCell: {
    paddingRight: 0,
  },
  warning: {
    color: amber[700],
  },
  floatRight: {
    float: "right",
  },
  selectedRow: {
    backgroundColor: "rgba(0,0,100,0.1) !important",
  },
});

const headerConfig = [
  {
    id: "cislo_jednaci",
    label: "Číslo jednací",
  },
  {
    id: "datum",
    label: "Datum",
  },
];

const headerConfigAdresaMd = [
  {
    id: "adresa_misto",
    label: "Jméno",
  },
  {
    id: "adresa_ulice",
    label: "Ulice",
  },
  {
    id: "adresa_obec",
    label: "Obec",
  },
];

const headerConfigAdresaSm = [
  {
    id: "adresa",
    label: "Adresa",
  },
];

class RZList extends Component {
  constructor(props) {
    super(props);
    this.rowRefs = {};
  }

  async componentDidMount() {
    if (this.props.Pagination.year) {
      await this.loadData();
    } else {
      this.props.savePagination({
        year: this.props.match.params.rok || this.props.Pagination.year || new Date().getFullYear(),
      });
    }

    setAppBarForList(this.props);

    if (this.props.OneData && this.rowRefs[this.props.OneData.ID]) {
      this.rowRefs[this.props.OneData.ID].scrollIntoView({ behavior: "smooth" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.Pagination.page !== this.props.Pagination.page ||
      nextProps.Pagination.searchTerm !== this.props.Pagination.searchTerm ||
      nextProps.Strankovani !== this.props.Strankovani ||
      nextProps.Pagination.year !== this.props.Pagination.year ||
      nextProps.Pagination._sort !== this.props.Pagination._sort
    ) {
      this.loadData({
        ...nextProps.Pagination,
        pageSize: nextProps.Strankovani,
      });
      setAppBarForList(nextProps);
    }
    if (nextProps.AllDataState === DATA_STATE.COPY_SUCCEEDED && nextProps.CopiedData.newId) {
      this.goDetail(nextProps.CopiedData.newId)();
    }
  }

  loadData = async (pagination) => {
    pagination = pagination || {};
    let params = {
      page: getIfUnd(pagination.page, this.props.Pagination.page),
      pageSize: getIfUnd(pagination.pageSize, this.props.Strankovani),
      order: getIfUnd(pagination._sort, this.props.Pagination._sort),
    };

    if (getIfUndOrNull(pagination.searchTerm, this.props.Pagination.searchTerm)) {
      params["searchTerm"] = pagination.searchTerm || this.props.Pagination.searchTerm;
    } else if (getIfUnd(pagination.year, this.props.Pagination.year)) {
      params["f-rok"] = getIfUnd(pagination.year, this.props.Pagination.year);
    }

    await this.props.getAll(params);
  };

  handleChangePage = (event, page) => {
    this.props.savePagination({ page: page });
  };

  handleChangeRowsPerPage = (event) => {
    this.props.saveStrankovani(event.target.value);
  };

  createSortHandler = (property) => (event) => {
    this.props.savePagination({
      sort: property,
      sortOrder:
        this.props.Pagination.sort === property ? (this.props.Pagination.sortOrder === "asc" ? "desc" : "asc") : "asc",
    });
  };

  goDetail = (id) => () => {
    this.props.history.push("/rz/detail/" + id);
  };

  copy = (id) => () => {
    this.props.copyRZ(id);
  };

  goView = (id) => () => {
    this.props.history.push("/rz/view/" + id);
  };

  addNew = () => {
    this.props.history.push("/rz/detail/new");
  };

  generateButtons = (id) => [
    {
      icon: <EditIcon />,
      onClick: this.goDetail(id),
      label: "Uprav",
    },
    {
      icon: <CopyIcon />,
      onClick: this.copy(id),
      label: "Namnož",
    },
  ];

  render() {
    const { classes, theme } = this.props;

    const isSmUp = isWidthUp("md", this.props.width);
    const isXsUp = isWidthUp("sm", this.props.width);

    const header = headerConfig.concat(isSmUp ? headerConfigAdresaMd : headerConfigAdresaSm).map((item, idx) => {
      return (
        <TableCell key={idx}>
          <TableSortLabel
            active={this.props.Pagination.sort === item.id}
            direction={this.props.Pagination.sortOrder}
            onClick={this.createSortHandler(item.id)}
          >
            {item.label}
          </TableSortLabel>
        </TableCell>
      );
    });

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          {this.props.AllDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <Table className={classes.table} padding="dense">
            <TableHead>
              <TableRow>
                {this.props.AllData &&
                  this.props.Strankovani && (
                    <TablePagination
                      colSpan={7}
                      count={this.props.AllData.totalCount}
                      rowsPerPage={this.props.Strankovani}
                      page={this.props.Pagination.page}
                      rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
              </TableRow>
              {isXsUp && (
                <TableRow>
                  <TableCell className={classes.warning} />
                  {header}
                  <TableCell />
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {isXsUp &&
                this.props.AllData &&
                (this.props.AllData.items || []).map((i) => {
                  return (
                    <TableRow
                      key={i.ID}
                      hover
                      selected={this.props.OneData && this.props.OneData.ID === i.ID}
                      classes={{ selected: classes.selectedRow }}
                    >
                      <TableCell className={classes.warningCell}>
                        {i.nedostatky_c === "1" && (
                          <Tooltip
                            disableTouchListener
                            title={
                              <div className={classes.wrap}>
                                <b>opravené:</b> {i.nedostatky_o}
                                <br />
                                <b>neopravené:</b> {i.nedostatky_n}
                                <br />
                                <b>termín:</b> {i.termin_o}
                              </div>
                            }
                          >
                            <WarningIcon className={classes.warning} />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell onClick={this.goView(i.ID)}>
                        {i.cislo_jednaci}/{i.rok}
                      </TableCell>
                      <TableCell onClick={this.goView(i.ID)}>{new Date(i.datum).toLocaleDateString()}</TableCell>

                      {isSmUp && <TableCell onClick={this.goView(i.ID)}>{i.adresa_misto}</TableCell>}
                      {isSmUp && <TableCell onClick={this.goView(i.ID)}>{i.adresa_ulice}</TableCell>}
                      {isSmUp && <TableCell onClick={this.goView(i.ID)}>{i.adresa_obec}</TableCell>}

                      {!isSmUp && (
                        <TableCell onClick={this.goView(i.ID)}>
                          {i.adresa_misto}, {i.adresa_ulice}, {i.adresa_obec}
                        </TableCell>
                      )}
                      <TableCell>
                        <span ref={(r) => (this.rowRefs[i.ID] = r)} />
                        <ButtonsOrMenu buttons={this.generateButtons(i.ID)} up="md" className={classes.floatRight} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!isXsUp &&
                this.props.AllData &&
                (this.props.AllData.items || []).map((i) => {
                  return (
                    <TableRow key={i.ID} hover>
                      <TableCell>
                        <Grid container direction="row" className={classes.gridContainer}>
                          <Grid item xs={5} className={classes.grid} onClick={this.goView(i.ID)}>
                            Číslo jednací
                          </Grid>
                          <Grid item xs={7} className={classes.grid} onClick={this.goView(i.ID)}>
                            {i.cislo_jednaci}/{i.rok}
                          </Grid>
                          <Grid item xs={5} className={classes.grid} onClick={this.goView(i.ID)}>
                            Datum
                          </Grid>
                          <Grid item xs={7} className={classes.grid} onClick={this.goView(i.ID)}>
                            {new Date(i.datum).toLocaleDateString()}
                          </Grid>
                          <Grid item xs={5} className={classes.grid} onClick={this.goView(i.ID)}>
                            Adresa
                          </Grid>
                          <Grid item xs={7} className={classes.grid} onClick={this.goView(i.ID)}>
                            {i.adresa_misto}, {i.adresa_ulice}, {i.adresa_obec}
                          </Grid>
                          <Grid item xs={5}>
                            {i.nedostatky_c === "1" && (
                              <Tooltip
                                disableTouchListener
                                title={
                                  <div className={classes.wrap}>
                                    <b>opravené:</b> {i.nedostatky_o}
                                    <br />
                                    <b>neopravené:</b> {i.nedostatky_n}
                                    <br />
                                    <b>termín:</b> {i.termin_o}
                                  </div>
                                }
                              >
                                <IconButton>
                                  <WarningIcon className={classes.warning} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid>
                          <Grid item xs={7}>
                            <ButtonsOrMenu buttons={this.generateButtons(i.ID)} up="xs" />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {null}
            </TableBody>
            <TableFooter>
              <TableRow>
                {this.props.AllData &&
                  this.props.Strankovani && (
                    <TablePagination
                      colSpan={7}
                      count={this.props.AllData.totalCount}
                      rowsPerPage={this.props.Strankovani}
                      page={this.props.Pagination.page}
                      rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
        <div className={classes.endPadding} />
        <Zoom in={true} timeout={theme.transitions.duration.enteringScreen} unmountOnExit>
          <Button variant="fab" className={classes.fab} color="secondary" onClick={this.addNew}>
            <AddIcon />
          </Button>
        </Zoom>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  AllData: getAllRZFromState(state),
  AllDataState: getAllRZStateFromState(state),
  AllDataError: getAllRZErrorFromState(state),
  Pagination: getAllRZPaginationFromState(state),
  Strankovani: getStrankovaniFromState(state),
  CopiedData: getOneRZCopiedFromState(state),
  OneData: getOneRZFromState(state),
});

const actionCreators = {
  getAll,
  savePagination,
  saveStrankovani,
  copyRZ,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withWidth()(withStyles(styles, { withTheme: true })(RZList))));
