import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, Paper, Table, TableHead, TableRow, TableCell, TableBody, Grid } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import { getUsersFromState } from "../../../services/redux/rootReducer.js";
import { removeUser } from "../../../services/redux/Settings/Settings.thunks.js";
import ButtonsOrMenu from "../../../components/Buttons/ButtonsOrMenu.js";
import DeleteConfirm from "../../../components/Dialogs/DeleteConfirm.js";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing.unit * 3,
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing.unit * 2,
  },
  floatRight: {
    float: "right",
  },
  gridContainer: {
    paddingTop: theme.spacing.unit / 2,
  },
  grid: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
});

class Uzivatele extends Component {
  state = {
    removeConfirmOpen: false,
    removeIC: null,
  };

  handleRemoveClick = (ic) => () => {
    this.setState({ removeConfirmOpen: true, removeIC: ic });
  };
  remove = (shouldRemove) => {
    if (shouldRemove) {
      this.props.removeUser(this.state.removeIC);
    }
    this.setState({ removeConfirmOpen: false });
  };

  generateButtons = (ic) => [
    {
      icon: <DeleteIcon />,
      label: "Smaž",
      onClick: this.handleRemoveClick(ic),
    },
  ];

  render() {
    const { classes } = this.props;

    const isXsUp = isWidthUp("sm", this.props.width);

    return (
      <Paper className={classes.container}>
        <Table className={classes.table} padding="dense">
          {isXsUp && (
            <TableHead>
              <TableRow>
                <TableCell>Jméno</TableCell>
                <TableCell>Příjmení</TableCell>
                <TableCell>Firma</TableCell>
                <TableCell>Ev. č. osv.</TableCell>
                <TableCell>IČ</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isXsUp &&
              this.props.Users &&
              this.props.Users.map((i) => {
                return (
                  <TableRow key={i.IC}>
                    <TableCell>{i.jmeno}</TableCell>
                    <TableCell>{i.prijmeni}</TableCell>
                    <TableCell>{i.firma}</TableCell>
                    <TableCell>{i.osv}</TableCell>
                    <TableCell>{i.IC}</TableCell>
                    <TableCell>
                      <ButtonsOrMenu buttons={this.generateButtons(i.IC)} up="md" className={classes.floatRight} />
                    </TableCell>
                  </TableRow>
                );
              })}
            {!isXsUp &&
              this.props.Users &&
              this.props.Users.map((i) => {
                return (
                  <TableRow key={i.IC}>
                    <TableCell>
                      <Grid container direction="row" className={classes.gridContainer}>
                        <Grid item xs={5} className={classes.grid}>
                          Jméno a firma
                        </Grid>
                        <Grid item xs={7} className={classes.grid}>
                          {i.jmeno} {i.prijmeni} {i.firma && "," + i.firma}
                        </Grid>
                        <Grid item xs={5} className={classes.grid}>
                          Ev. č. osv.
                        </Grid>
                        <Grid item xs={7} className={classes.grid}>
                          {i.osv}
                        </Grid>
                        <Grid item xs={5} className={classes.grid}>
                          IČ
                        </Grid>
                        <Grid item xs={7} className={classes.grid}>
                          {i.IC}
                        </Grid>
                        <Grid item xs={5} />
                        <Grid item xs={7}>
                          <ButtonsOrMenu buttons={this.generateButtons(i.IC)} up="xs" className={classes.floatRight} />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            {null}
          </TableBody>
        </Table>
        <DeleteConfirm
          open={this.state.removeConfirmOpen}
          onClose={this.remove}
          label="Opravdu chcete smazat uživatele?"
        />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  Users: getUsersFromState(state),
});

const actionCreators = {
  removeUser,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withWidth()(withStyles(styles)(Uzivatele)));
