import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import { getAllErrors } from "../../../services/redux/rootReducer.js";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing.unit * 3,
  },
});

class LoggingList extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.container}>
        <Table className={classes.table} padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>When</TableCell>
              <TableCell>Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.Errors &&
              this.props.Errors.map((i) => {
                return (
                  <TableRow key={i.timestamp}>
                    <TableCell>
                      {new Date(i.timestamp).toLocaleDateString()} {new Date(i.timestamp).toLocaleTimeString()}
                    </TableCell>
                    <TableCell>{i.errorType}</TableCell>
                    <TableCell>{i.errorWhen}</TableCell>
                    <TableCell>{i.errors && i.errors.join(",")}</TableCell>
                  </TableRow>
                );
              })}
            {null}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  Errors: getAllErrors(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(LoggingList));
