import React, { Component } from "react";
import { connect } from "react-redux";
//import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { getSQLFormatDate } from "../../utils/date.js";

import DeleteConfirm from "../../components/Dialogs/DeleteConfirm.js";
import PickOrType from "../../components/Inputs/PickOrType.js";
import { ImagesRZ } from "../../components/Inputs/Images.js";

import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  CircularProgress,
  LinearProgress,
  Typography,
  Grid,
  TextField,
  Button,
  Zoom,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { Save as SaveIcon, Check as CheckIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { getOne, update, remove, savePagination } from "../../services/redux/RZList/RZList.thunks.js";
import {
  getOneRZFromState,
  getOneRZUpdatedFromState,
  getOneRZStateFromState,
  getOneRZErrorFromState,
  getAllRZPaginationFromState,
  getUsersFromState,
  getImagesSettingsFromState,
  getTZFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";
import { setAppBarForDetail } from "../../utils/defaults.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  gridCell: {},
  group: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  dateField: {
    width: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  headerMargin: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
});

class RZDetail extends Component {
  state = {
    data: {
      datum: getSQLFormatDate(),
      datum_v: getSQLFormatDate(),
      adresa_misto: "",
      adresa_ulice: "",
      adresa_obec: "",
      IC: this.props.Users[0].IC,
      spotrebic: "",
      umisteni: "",
      spec_cesty: "",
      shoda_vyrobku: "",
      vyrobce: "",
      nedostatky_o: "",
      nedostatky_n: "",
      termin_o: "",
      vyhovuje: "1",
      technicka_zpr: this.props.TZ.value || "",
    },
    saved: true,
    removeConfirmOpen: false,
  };

  componentDidMount() {
    this.props.match.params.id !== "new" && this.props.getOne(this.props.match.params.id);

    setAppBarForDetail(this.props, "/rz/list", {
      icon: <DeleteIcon />,
      label: "Smaž",
      onClick: this.handleRemoveClick,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.OneDataState === DATA_STATE.REMOVE_SUCCEEDED) {
      this.props.history.push("/rz/list");
    }
    // set data to state after fetch
    if (this.props.OneData && this.props.OneData !== prevProps.OneData) {
      this.setState({ data: this.props.OneData });
    }
    // set saved flag after save and reload after new has been added
    if (
      this.props.OneDataState === DATA_STATE.UPDATE_SUCCEEDED &&
      prevProps.OneDataState !== DATA_STATE.UPDATE_SUCCEEDED
    ) {
      this.setState({ saved: true });

      if (this.props.askWhenNavigatingAway && this.props.setAskWhenNavigatingAway) {
        this.props.setAskWhenNavigatingAway(false);
      }
      if (this.props.match.params.id === "new" && this.props.UpdatedData) {
        this.props.history.push(`/rz/detail/${this.props.UpdatedData.newId}`);
        this.props.getOne(this.props.UpdatedData.newId);
      }
    }
  }

  handleChange = (name) => (event) => {
    let newData = this.state.data;
    newData[name] = event.target.value;
    this.setState({ data: newData, saved: false });

    if (this.props.setAskWhenNavigatingAway) {
      this.props.setAskWhenNavigatingAway(true);
    }
  };

  handleKeyDown = (name) => (event) => {
    let key = event.key;
    if (event.key === " " && event.ctrlKey) {
      key = "\u00A0";
    }
    if (event.key === "o" && event.ctrlKey && event.altKey) {
      key = "\u00F8";
    }
    if (key !== event.key) {
      event.stopPropagation();
      let newData = this.state.data;
      document.execCommand("insertText", false, key);
      newData[name] = event.target.value;
      this.setState({ data: newData, saved: false });

      if (this.props.setAskWhenNavigatingAway) {
        this.props.setAskWhenNavigatingAway(true);
      }
    }
  };

  handleChangeValue = (name) => (value) => {
    let newData = this.state.data;
    newData[name] = value;
    this.setState({ data: newData, saved: false });

    if (this.props.setAskWhenNavigatingAway) {
      this.props.setAskWhenNavigatingAway(true);
    }
  };

  save = () => {
    if (this.state.saved) {
      this.props.history.push("/rz/view/" + this.props.match.params.id);
    } else if (this.props.OneDataState !== DATA_STATE.UPDATE_IN_PROGRESS) {
      this.props.update(this.props.match.params.id, this.state.data);
    }
  };

  handleRemoveClick = () => {
    this.setState({ removeConfirmOpen: true });
  };
  remove = (shouldRemove) => {
    if (shouldRemove) {
      this.props.remove(this.props.match.params.id);
    }
    this.setState({ removeConfirmOpen: false });
  };

  render() {
    const { classes, theme } = this.props;
    const data = this.props.OneData;
    const ID = this.props.match.params.id;

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          {data && ID !== "new" && `${data.cislo_jednaci}/${data.rok}`}
          {ID === "new" && "Nový"}
        </Typography>
        <Paper className={classes.root}>
          {this.props.OneDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container direction="row">
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="datum"
                  label="Datum kontroly"
                  type="date"
                  margin="normal"
                  className={classes.dateField}
                  value={this.state.data.datum}
                  onChange={this.handleChange("datum")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="datum_v"
                  label="Datum Vystavení"
                  type="date"
                  margin="normal"
                  className={classes.dateField}
                  value={this.state.data.datum_v}
                  onChange={this.handleChange("datum_v")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="adresa_misto"
                  label="Jméno"
                  margin="normal"
                  fullWidth
                  value={this.state.data.adresa_misto}
                  onChange={this.handleChange("adresa_misto")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="adresa_ulice"
                  label="Ulice"
                  margin="normal"
                  fullWidth
                  value={this.state.data.adresa_ulice}
                  onChange={this.handleChange("adresa_ulice")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="adresa_obec"
                  label="Obec"
                  margin="normal"
                  fullWidth
                  value={this.state.data.adresa_obec}
                  onChange={this.handleChange("adresa_obec")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <FormControl className={classes.formControl} margin="normal">
                  <InputLabel shrink htmlFor={"uzivatel-placeholder"}>
                    Revizní technik
                  </InputLabel>
                  <Select
                    value={this.state.data.IC}
                    onChange={this.handleChange("IC")}
                    input={<Input name="uzivatel" id={"uzivatel-placeholder"} />}
                    name="uzivatel"
                    className={classes.selectEmpty}
                  >
                    {this.props.Users.map((user) => (
                      <MenuItem value={user.IC} key={user.IC}>
                        {user.jmeno} {user.prijmeni}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="spotrebic"
                  label="Spotřebič"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="3"
                  value={this.state.data.spotrebic}
                  onChange={this.handleChange("spotrebic")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="umisteni"
                  label="Umístění"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="3"
                  value={this.state.data.umisteni}
                  onChange={this.handleChange("umisteni")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="spec_cesty"
                  label="Specifikace cesty"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="6"
                  value={this.state.data.spec_cesty}
                  onChange={this.handleChange("spec_cesty")}
                  onKeyUp={this.handleKeyDown("spec_cesty")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <PickOrType
                  id="shoda_vyrobku"
                  label="Doklad o shodě výrobku"
                  defaultValue={this.state.data.shoda_vyrobku}
                  onChange={this.handleChangeValue("shoda_vyrobku")}
                  type="shoda"
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <PickOrType
                  id="vyrobce"
                  label="Výrobce"
                  defaultValue={this.state.data.vyrobce}
                  onChange={this.handleChangeValue("vyrobce")}
                  type="vyrobce"
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="nedostatky_o"
                  label="Opravené nedostatky"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="3"
                  value={this.state.data.nedostatky_o}
                  onChange={this.handleChange("nedostatky_o")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="nedostatky_n"
                  label="Neopravené nedostatky"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="3"
                  value={this.state.data.nedostatky_n}
                  onChange={this.handleChange("nedostatky_n")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="termin_o"
                  label="Termín odstranění"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="3"
                  value={this.state.data.termin_o}
                  onChange={this.handleChange("termin_o")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <FormControl component="fieldset" className={classes.formControl} margin="normal">
                  <FormLabel component="legend">Vyhovuje</FormLabel>
                  <RadioGroup name="vyhovuje" value={this.state.data.vyhovuje} onChange={this.handleChange("vyhovuje")}>
                    <FormControlLabel value="1" control={<Radio />} label="Ano" />
                    <FormControlLabel value="0" control={<Radio />} label="Ne" />
                    <FormControlLabel value="2" control={<Radio />} label="Po odstranění závad" />
                    <FormControlLabel value="3" control={<Radio />} label="Nic" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Typography variant="h4" className={classes.headerMargin}>
          Technická zpráva
        </Typography>
        <Paper className={classes.root}>
          <TextField
            id="technicka_zpr"
            margin="normal"
            fullWidth
            multiline
            rowsMax="40"
            value={this.state.data.technicka_zpr}
            onChange={this.handleChange("technicka_zpr")}
            onKeyUp={this.handleKeyDown("technicka_zpr")}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Paper>
        {this.props.ImagesSettings &&
          this.props.ImagesSettings.imgadding === "1" && <ImagesRZ ID={ID} images={this.state.data.images} />}
        <div className={classes.fab}>
          <Zoom in={true} timeout={theme.transitions.duration.enteringScreen} unmountOnExit>
            <Button variant="fab" color="secondary" onClick={this.save}>
              {this.state.saved ? <CheckIcon /> : <SaveIcon />}
            </Button>
          </Zoom>
          {this.props.OneDataState === DATA_STATE.UPDATE_IN_PROGRESS && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
        <DeleteConfirm
          open={this.state.removeConfirmOpen}
          onClose={this.remove}
          label="Opravdu chcete smazat tuto zprávu?"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  OneData: getOneRZFromState(state),
  UpdatedData: getOneRZUpdatedFromState(state),
  OneDataState: getOneRZStateFromState(state),
  OneDataError: getOneRZErrorFromState(state),
  Pagination: getAllRZPaginationFromState(state),
  Users: getUsersFromState(state),
  ImagesSettings: getImagesSettingsFromState(state),
  TZ: getTZFromState(state),
});

const actionCreators = {
  getOne,
  update,
  remove,
  savePagination,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles, { withTheme: true })(RZDetail)));
