import { SETTINGS_ACTION_TYPES } from "./Settings.actions.js";
import { DATA_STATE } from "../constants.js";
import { getFailStateForReducer } from "../../../utils/defaults.js";

export const appDrawerShown = (state) => state.showAppDrawer;
export const getStrankovani = (state) => state.strankovani;
export const getRZMinYear = (state) => state.rzMinYear;
export const getPZMinYear = (state) => state.pzMinYear;
export const getVyrobce = (state) => state.vyrobce;
export const getShodaVyrobku = (state) => state.shoda;
export const getImages = (state) => state.images;
export const getUsers = (state) => state.users;
export const getVerze = (state) => state.verze;
export const getTZ = (state) => state.tz;
export const getStats = (state) => state.stats;
export const getDataLoaded = (state) => state.dataLoaded;
export const getDataState = (state) => state.dataState;
export const getStatsState = (state) => state.statsState;
export const getDataError = (state) => {
  return { errorType: state.errorType, errors: state.errors, errorWhen: state.errorWhen };
};

const INITIAL_STATE = {
  dataState: DATA_STATE.NOT_INITIALIZED,
  statsState: DATA_STATE.NOT_INITIALIZED,
  showAppDrawer: false,
  verze: {
    plus: "0",
    tabulka: "0",
    seznam: "0",
    protokol: "0",
    makeSeznam: "0",
    adresar: "0",
    prepocetSpalin: "0",
    alwaysCloseSidebar: "1",
  },
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SETTINGS_ACTION_TYPES.FETCHING:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.FETCH_FAILED:
      return {
        ...state,
        dataState: DATA_STATE.FAILED,
        errorWhen: SETTINGS_ACTION_TYPES.FETCHING,
        errorType: action.errorType,
        errors: action.errors,
        strankovani: 25,
        rzMinYear: new Date().getFullYear(),
        pzMinYear: new Date().getFullYear(),
      };
    case SETTINGS_ACTION_TYPES.FETCH_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.INITIALIZED,
        strankovani: parseInt(action.strankovani.stranky, 10),
        rzMinYear: parseInt(action.rzMinYear.minYear, 10),
        pzMinYear: parseInt(action.pzMinYear.minYear, 10),
        vyrobce: action.vyrobce,
        images: action.images,
        users: action.users,
        shoda: action.shoda,
        verze: action.verze,
        tz: action.tz,
        dataLoaded: true,
      };
    // fetch stats
    case SETTINGS_ACTION_TYPES.FETCHING_STATS:
      return {
        ...state,
        statsState: DATA_STATE.IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.FETCH_STATS_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.FETCHING_STATS, "statsState");

    case SETTINGS_ACTION_TYPES.FETCH_STATS_SUCCEEDED:
      return {
        ...state,
        statsState: DATA_STATE.INITIALIZED,
        stats: action.stats,
      };
    case SETTINGS_ACTION_TYPES.SHOW_APP_DRAWER:
      return {
        ...state,
        showAppDrawer: action.showAppDrawer,
      };
    // update page size
    case SETTINGS_ACTION_TYPES.UPDATING_PAGE_SIZE:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.UPDATE_PAGE_SIZE_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.UPDATING_PAGE_SIZE);

    case SETTINGS_ACTION_TYPES.UPDATE_PAGE_SIZE_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_SUCCEEDED,
        strankovani: parseInt(action.strankovani.stranky, 10),
      };
    // update vyrobce
    case SETTINGS_ACTION_TYPES.UPDATING_VYROBCE:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.UPDATE_VYROBCE_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.UPDATING_VYROBCE);

    case SETTINGS_ACTION_TYPES.UPDATE_VYROBCE_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_SUCCEEDED,
        vyrobce: state.vyrobce.concat([action.vyrobce]),
      };
    // remove vyrobce
    case SETTINGS_ACTION_TYPES.REMOVING_VYROBCE:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.REMOVE_VYROBCE_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.REMOVING_VYROBCE);

    case SETTINGS_ACTION_TYPES.REMOVE_VYROBCE_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.REMOVE_SUCCEEDED,
        vyrobce: state.vyrobce.filter((vyrobce) => vyrobce.ID !== action.ID),
      };
    // update shoda vyrobku
    case SETTINGS_ACTION_TYPES.UPDATING_SHODA:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.UPDATE_SHODA_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.UPDATING_SHODA);

    case SETTINGS_ACTION_TYPES.UPDATE_SHODA_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_SUCCEEDED,
        shoda: state.shoda.concat([action.shoda]),
      };
    // remove shoda vyrobku
    case SETTINGS_ACTION_TYPES.REMOVING_SHODA:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.REMOVE_SHODA_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.REMOVING_SHODA);

    case SETTINGS_ACTION_TYPES.REMOVE_SHODA_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.REMOVE_SUCCEEDED,
        shoda: state.shoda.filter((shoda) => shoda.ID !== action.ID),
      };
    // update images
    case SETTINGS_ACTION_TYPES.UPDATING_IMAGES:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.UPDATE_IMAGES_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.UPDATING_IMAGES);

    case SETTINGS_ACTION_TYPES.UPDATE_IMAGES_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_SUCCEEDED,
        images: action.images,
      };
    // update users
    case SETTINGS_ACTION_TYPES.UPDATING_USERS:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.UPDATE_USERS_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.UPDATING_USERS);

    case SETTINGS_ACTION_TYPES.UPDATE_USERS_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_SUCCEEDED,
        users: state.users.concat([action.users]),
      };
    // remove users
    case SETTINGS_ACTION_TYPES.REMOVING_USERS:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.REMOVE_USERS_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.REMOVING_USERS);

    case SETTINGS_ACTION_TYPES.REMOVE_USERS_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.REMOVE_SUCCEEDED,
        users: state.users.filter((user) => user.IC !== action.IC),
      };
    // update verze
    case SETTINGS_ACTION_TYPES.UPDATING_VERZE:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.UPDATE_VERZE_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.UPDATING_VERZE);

    case SETTINGS_ACTION_TYPES.UPDATE_VERZE_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_SUCCEEDED,
        verze: action.verze,
      };
    // update tz default
    case SETTINGS_ACTION_TYPES.UPDATING_TZ:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_IN_PROGRESS,
      };
    case SETTINGS_ACTION_TYPES.UPDATE_TZ_FAILED:
      return getFailStateForReducer(state, action, SETTINGS_ACTION_TYPES.UPDATING_TZ);

    case SETTINGS_ACTION_TYPES.UPDATE_TZ_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.UPDATE_SUCCEEDED,
        tz: action.tz,
      };
    default:
      return state;
  }
}
