import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, Paper, FormControlLabel, Switch, Grid } from "@material-ui/core";

import { getVerzeFromState } from "../../../services/redux/rootReducer.js";
import { updateVerze } from "../../../services/redux/Settings/Settings.thunks.js";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
  },
});

class Options extends Component {
  state = {
    data: {
      alwaysCloseSidebar: "1",
    },
  };

  componentDidMount() {
    this.setState({ data: Object.assign({}, this.props.Verze) });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.shouldSave && !this.props.shouldSave) {
      this.props.updateVerze(this.state.data);
    }
  }

  handleChange = (what, type) => (evt) => {
    var data = this.state.data;
    if (type === "check") {
      data[what] = evt.target.checked ? "1" : "0";
    } else {
      data[what] = evt.target.value;
    }
    this.setState({ data: data });
  };

  render() {
    const { classes } = this.props;

    let alwaysCloseSidebar = this.state.data.alwaysCloseSidebar === "1";

    return (
      <Paper className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={alwaysCloseSidebar}
                  onChange={this.handleChange("alwaysCloseSidebar", "check")}
                  value="1"
                />
              }
              label="Vždy zavřít levý panel po kliknutí (po vypnutí zůstane panel stále otevřen)"
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  Verze: getVerzeFromState(state),
});

const actionCreators = {
  updateVerze,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(Options));
