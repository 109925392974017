import { LOGIN_ACTION_TYPES } from "./Login.actions.js";
import { DATA_STATE } from "../constants.js";

export const getData = (state) => state.data;
export const getUserName = (state) => state.userName;
export const getDataState = (state) => state.dataState;

const INITIAL_STATE = {
  dataState: DATA_STATE.NOT_INITIALIZED,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_ACTION_TYPES.LOGGING_IN:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case LOGIN_ACTION_TYPES.LOGIN_FAILED:
      return {
        ...state,
        dataState: DATA_STATE.FAILED,
        errorType: action.errorType,
      };
    case LOGIN_ACTION_TYPES.LOGGED_IN:
      return {
        dataState: DATA_STATE.INITIALIZED,
        data: action.data,
        userName: action.data.userName,
      };
    case LOGIN_ACTION_TYPES.FETCHING_USER:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case LOGIN_ACTION_TYPES.USER_FETCH_FAILED:
      return {
        ...state,
        dataState: DATA_STATE.FAILED,
        errorType: action.errorType,
      };
    case LOGIN_ACTION_TYPES.USER_FETCHED:
      return {
        dataState: DATA_STATE.INITIALIZED,
        userName: action.data.userName,
      };
    default:
      return state;
  }
}
