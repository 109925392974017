import React, { Component } from "react";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, DialogActions, Button, DialogContent, TextField } from "@material-ui/core";

class UzivateleDetail extends Component {
  state = {
    data: {
      jmeno: "",
      prijmeni: "",
      IC: "",
      osv: "",
      firma: "",
    },
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open === true) {
      this.setState({
        data: {
          jmeno: "",
          prijmeni: "",
          IC: "",
          osv: "",
          firma: "",
        },
      });
    }
  }

  handleCancel = () => {
    this.props.onClose(false);
  };

  handleOk = () => {
    if (this.state.data.IC) {
      this.props.onClose(this.state.data);
    }
  };

  handleChange = (name) => (event) => {
    let newData = this.state.data;
    newData[name] = event.target.value;
    this.setState({ data: newData, saved: false });
  };

  render() {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        {...this.props}
      >
        <DialogTitle id="confirmation-dialog-title">Uživatel</DialogTitle>
        <DialogContent>
          <TextField
            id="IC"
            label="IČ"
            margin="normal"
            value={this.state.data.IC}
            onChange={this.handleChange("IC")}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            id="jmeno"
            label="Jméno"
            margin="normal"
            value={this.state.data.jmeno}
            onChange={this.handleChange("jmeno")}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            id="prijmeni"
            label="Příjmení"
            margin="normal"
            value={this.state.data.prijmeni}
            onChange={this.handleChange("prijmeni")}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            id="firma"
            label="Firma"
            margin="normal"
            value={this.state.data.firma}
            onChange={this.handleChange("firma")}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            id="osv"
            label="Ev. č. osv."
            margin="normal"
            value={this.state.data.osv}
            onChange={this.handleChange("osv")}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="default">
            Zrušit
          </Button>
          <Button onClick={this.handleOk} color="secondary" variant="contained">
            Ulož
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

UzivateleDetail.propTypes = {
  onClose: PropTypes.func,
};

export default UzivateleDetail;
