import React, { Component } from "react";

import FooterImage from "./footer2.png";

class FooterDavid extends Component {
  render() {
    return (
      <img
        src={FooterImage}
        alt="Podpis"
        style={{
          width: "4.5cm",
          marginRight: "1cm",
          marginBottom: "1cm",
        }}
      />
    );
  }
}

export default FooterDavid;
