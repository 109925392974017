import axios from "axios";

export function getPage(params) {
  return axios.get("seznam/index.php", { params: params });
}

export function getOne(id, params) {
  params = params || {};
  params.ID = id;
  return axios.get("seznam/index.php", { params: params });
}

export function update(id, data) {
  let params = { ID: id };
  return axios.post("seznam/index.php", data, { params: params });
}

export function remove(id) {
  let params = { ID: id };
  return axios.delete("seznam/index.php", { params: params });
}

export function copy(id) {
  let params = { ID: id };
  return axios.post("seznam/copy.php", null, { params: params });
}

export function addRow(id, data) {
  return axios.post("seznam/radky.php", data, { params: { ID: id } });
}

export function removeRow(id) {
  return axios.delete("seznam/radky.php", { params: { ID: id } });
}
