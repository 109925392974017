import { savePaginationAction, addPZImageSucceeded, PZ_ACTION_TYPES, saveOpenPlusAction } from "./PZList.actions.js";
import { callApiFromThunks } from "../../../utils/defaults.js";

export function getAll(params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pz.getPage(params),
      PZ_ACTION_TYPES.FETCHING_LIST,
      PZ_ACTION_TYPES.FETCH_LIST_FAILED,
      PZ_ACTION_TYPES.FETCH_LIST_SUCCEEDED
    );
  };
}

export function getOne(id, params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pz.getOne(id, params),
      PZ_ACTION_TYPES.FETCHING,
      PZ_ACTION_TYPES.FETCH_FAILED,
      PZ_ACTION_TYPES.FETCH_SUCCEEDED
    );
  };
}

export function update(id, data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pz.update(id, data),
      PZ_ACTION_TYPES.UPDATING,
      PZ_ACTION_TYPES.UPDATE_FAILED,
      PZ_ACTION_TYPES.UPDATE_SUCCEEDED
    );
  };
}

export function remove(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pz.remove(id),
      PZ_ACTION_TYPES.REMOVING,
      PZ_ACTION_TYPES.REMOVE_FAILED,
      PZ_ACTION_TYPES.REMOVE_SUCCEEDED
    );
  };
}

export function copy(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pz.copy(id),
      PZ_ACTION_TYPES.COPYING,
      PZ_ACTION_TYPES.COPY_FAILED,
      PZ_ACTION_TYPES.COPY_SUCCEEDED
    );
  };
}

export function savePagination(data) {
  return async (dispatch) => {
    dispatch(savePaginationAction(data.page, data.searchTerm, data.year, data.sort, data.sortOrder));
  };
}

export function saveOpenPlus(data) {
  return async (dispatch) => {
    dispatch(saveOpenPlusAction(data));
  };
}

export function addImage(id, data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pz.addImage(id, data),
      PZ_ACTION_TYPES.ADDING_IMAGE,
      PZ_ACTION_TYPES.ADD_IMAGE_FAILED,
      addPZImageSucceeded
    );
  };
}

export function removeImage(cesta) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pz.removeImage(cesta),
      PZ_ACTION_TYPES.REMOVING_IMAGE,
      PZ_ACTION_TYPES.REMOVE_IMAGE_FAILED,
      PZ_ACTION_TYPES.REMOVE_IMAGE_SUCCEEDED
    );
  };
}

export function updateImageOrder(data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.pz.updateImageOrder(data),
      PZ_ACTION_TYPES.UPDATING_IMAGE_ORDER,
      PZ_ACTION_TYPES.UPDATE_IMAGE_ORDER_FAILED,
      PZ_ACTION_TYPES.UPDATE_IMAGE_ORDER_SUCCEEDED
    );
  };
}
