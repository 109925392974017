export const DATA_STATE = {
  NOT_INITIALIZED: "NOT_INITIALIZED",
  IN_PROGRESS: "IN_PROGRESS",
  FAILED: "FAILED",
  INITIALIZED: "INITIALIZED",
  UPDATE_IN_PROGRESS: "UPDATE_IN_PROGRESS",
  UPDATE_SUCCEEDED: "UPDATE_SUCCEEDED",
  REMOVE_SUCCEEDED: "REMOVE_SUCCEEDED",
  UPLOAD_SUCCEEDED: "UPLOAD_SUCCEEDED",
  COPY_SUCCEEDED: "COPY_SUCCEEDED",
};

export const ERROR = {
  UNAUTHORIZED: "UNAUTHORIZED",
  UNKNOWN: "UNKNOWN",
  SERVER_ERROR: "SERVER_ERROR",
  NOT_PERMITTED: "NOT_PERMITTED",
  WARNING: "WARNING",
  INFO: "INFO",
};

export const SERVER_URL = "https://komin.vvchr.cz/";
