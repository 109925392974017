import { savePaginationAction, addTRowSucceeded, T_ACTION_TYPES } from "./TList.actions.js";
import { callApiFromThunks } from "../../../utils/defaults.js";

export function getAll(params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.t.getPage(params),
      T_ACTION_TYPES.FETCHING_LIST,
      T_ACTION_TYPES.FETCH_LIST_FAILED,
      T_ACTION_TYPES.FETCH_LIST_SUCCEEDED
    );
  };
}

export function getOne(id, params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.t.getOne(id, params),
      T_ACTION_TYPES.FETCHING,
      T_ACTION_TYPES.FETCH_FAILED,
      T_ACTION_TYPES.FETCH_SUCCEEDED
    );
  };
}

export function update(id, data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.t.update(id, data),
      T_ACTION_TYPES.UPDATING,
      T_ACTION_TYPES.UPDATE_FAILED,
      T_ACTION_TYPES.UPDATE_SUCCEEDED
    );
  };
}

export function remove(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.t.remove(id),
      T_ACTION_TYPES.REMOVING,
      T_ACTION_TYPES.REMOVE_FAILED,
      T_ACTION_TYPES.REMOVE_SUCCEEDED
    );
  };
}

export function copy(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.t.copy(id),
      T_ACTION_TYPES.COPYING,
      T_ACTION_TYPES.COPY_FAILED,
      T_ACTION_TYPES.COPY_SUCCEEDED
    );
  };
}

export function savePagination(data) {
  return async (dispatch) => {
    dispatch(savePaginationAction(data.page, data.searchTerm, data.sort, data.sortOrder));
  };
}

export function addRow(id, data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.t.addRow(id, data),
      T_ACTION_TYPES.ADDING_ROW,
      T_ACTION_TYPES.ADD_ROW_FAILED,
      addTRowSucceeded
    );
  };
}

export function removeRow(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.t.removeRow(id),
      T_ACTION_TYPES.REMOVING_ROW,
      T_ACTION_TYPES.REMOVE_ROW_FAILED,
      T_ACTION_TYPES.REMOVE_ROW_SUCCEEDED
    );
  };
}

export function updateRowOrder(data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.t.updateRowOrder(data),
      T_ACTION_TYPES.UPDATING_ROW_ORDER,
      T_ACTION_TYPES.UPDATE_ROW_ORDER_FAILED,
      T_ACTION_TYPES.UPDATE_ROW_ORDER_SUCCEEDED
    );
  };
}
