import axios from "axios";

export function getPage(params) {
  return axios.get("revizni/index.php", { params: params });
}

export function getOne(id, params) {
  params = params || {};
  params.ID = id;
  return axios.get("revizni/index.php", { params: params });
}

export function getMinYear() {
  return axios.get("revizni/years.php");
}

export function update(id, data) {
  let params = { ID: id };
  return axios.post("revizni/index.php", data, { params: params });
}

export function remove(id) {
  let params = { ID: id };
  return axios.delete("revizni/index.php", { params: params });
}

export function copy(id) {
  let params = { ID: id };
  return axios.post("revizni/copy.php", null, { params: params });
}

export function addImage(id, data) {
  return axios.post("revizni/images.php", data, { params: { ID: id } });
}

export function removeImage(cesta) {
  return axios.delete("revizni/images.php", { params: { cesta: cesta } });
}

export function updateImageOrder(data) {
  return axios.patch("revizni/images.php", data);
}
