import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {} from "@material-ui/core";

import Header from "../../../components/Headers/Header.js";
import Footer from "../../../components/Footers/Footer.js";
import { print as printStyles } from "../../../constants/commonStyles.js";
import { SERVER_URL } from "../../../services/redux/constants.js";
import { getImagesSettingsFromState } from "../../../services/redux/rootReducer.js";

const styles = {
  root: printStyles.root,
  page: printStyles.page,
  headerH1: printStyles.headerH1,
  headerH2: printStyles.headerH2,
  headerH3: printStyles.headerH3,
  gap: printStyles.gap,
  smallGap: printStyles.smallGap,
  table: printStyles.table,
  label: printStyles.label,
  value: printStyles.value,
  alignBottom: printStyles.alignBottom,
  signature: printStyles.signature,
  image: printStyles.image,
  TZ: {
    whiteSpace: "pre-wrap",
    fontSize: "90%",
    textAlign: "justify",
  },
};

class RZViewPrint extends Component {
  render() {
    const { classes, data } = this.props;

    const imgProp = {};
    if (this.props.ImagesSettings.height !== "0") {
      imgProp.height = this.props.ImagesSettings.height;
    }
    if (this.props.ImagesSettings.width !== "0") {
      imgProp.width = this.props.ImagesSettings.width;
    }

    return (
      <React.Fragment>
        {data && (
          <div className={classes.root}>
            <div className={classes.page}>
              <Header />
              <h1 className={classes.headerH1}>ZPRÁVA</h1>
              <h2 className={classes.headerH2}>o revizi spalinové cesty</h2>
              <div className={classes.gap} />
              <table cellPadding="0px" cellSpacing="0px" className={classes.table}>
                <tbody>
                  <tr>
                    <td className={classes.label}>Číslo revizní zprávy:</td>
                    <td className={classes.value}>
                      {data.cislo_jednaci}/{data.rok}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.label}>Datum vystavení revizní zprávy: </td>
                    <td className={classes.value}>{new Date(data.datum_v).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <td className={classes.label}>Datum provedení revize spalinové cesty: </td>
                    <td className={classes.value}>{new Date(data.datum).toLocaleDateString()}</td>
                  </tr>

                  <tr>
                    <td className={classes.smallGap}>&nbsp;</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>Jméno a příjmení způsobilé osoby/název firmy:</td>
                    <td className={classes.value}>{data.technik}</td>
                  </tr>
                  <tr>
                    <td className={classes.label}>Číslo osvědčení odborně způsobilé osoby:</td>
                    <td className={classes.value}>{data.osvc}</td>
                  </tr>
                  <tr>
                    <td className={classes.label}>IČ odborně způsobilé osoby, podnikatele:</td>
                    <td className={classes.value}>{data.IC}</td>
                  </tr>

                  <tr>
                    <td className={classes.smallGap}>&nbsp;</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>
                      Název, sídlo a IČ, případně jméno a příjmení fyzické osoby, u které byla provedena revize
                      spalinové cesty:
                    </td>
                    <td className={classes.alignBottom}>{data.adresa_misto}</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>
                      Adresa objektu, ve kterém byla provedena revize spalinové cesty:&nbsp;
                    </td>
                    <td className={classes.value}>
                      {data.adresa_ulice ? `${data.adresa_ulice}, ${data.adresa_obec}` : data.adresa_obec}
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.smallGap}>&nbsp;</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>Základní údaje o spotřebiči paliv: </td>
                    <td className={classes.value}>{data.spotrebic}</td>
                  </tr>
                  <tr>
                    <td className={classes.label}>Umístění spotřebiče v objektu včetně podlaží: </td>
                    <td className={classes.value}>{data.umisteni}</td>
                  </tr>

                  <tr>
                    <td className={classes.smallGap}>&nbsp;</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>Specifikace spalinové cesty, u které byla provedena revize: </td>
                    <td className={classes.value}>{data.spec_cesty}</td>
                  </tr>

                  <tr>
                    <td className={classes.smallGap}>&nbsp;</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>
                      Určení výrobce komínových vložek, systémového komínu nebo komponentů pro individuální komín včetně
                      IČ:
                    </td>
                    <td className={classes.alignBottom}>{data.vyrobce}</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>
                      Prohlášení o vlastnostech systémového komínu, komponentů individuálního komínu nebo komínových
                      vložek:{" "}
                    </td>
                    <td className={classes.value}>{data.shoda_vyrobku}</td>
                  </tr>

                  <tr>
                    <td className={classes.smallGap}>&nbsp;</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>Zjištěné nedostatky, které byly odstraněny na místě: </td>
                    <td className={classes.value}>{data.nedostatky_o}</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>Zjištěné nedostatky, které nebyly odstraněny na místě: </td>
                    <td className={classes.value}>{data.nedostatky_n}</td>
                  </tr>

                  <tr>
                    <td className={classes.label}>Termín odstranění nedostatků: </td>
                    <td className={classes.value}>{data.termin_o}</td>
                  </tr>
                </tbody>
              </table>

              {data.vyhovuje !== "3" && (
                <div>
                  <div className={classes.gap} />
                  <h3 className={classes.headerH3}>ZÁVĚR</h3>
                  <h2 className={classes.headerH2}>Spalinová cesta z hlediska bezpečného a spolehlivého provozu</h2>
                  <h3 className={classes.headerH3}>
                    {data.vyhovuje === "1" && "vyhovuje"}
                    {data.vyhovuje === "2" && "vyhovuje po odstranění zjištěných nedostatků"}
                    {data.vyhovuje === "0" && "nevyhovuje"}
                  </h3>
                </div>
              )}

              <div className={classes.gap} />

              <div>
                Nedílnou součástí této zprávy o revizi spalinové cesty je technický protokol revize spalinové cesty.
              </div>

              <div className={classes.gap} />

              <div className={classes.signature}>Podpis a razítko revizního technika spalinových cest</div>
              {this.props.footer && <Footer />}
            </div>
            <div className={classes.page}>
              <div>
                TP č. {data.cislo_jednaci}/{data.rok}
              </div>
              <h3 className={classes.headerH3}>Technický protokol revize spalinové cesty</h3>

              <div className={classes.gap} />
              <div className={classes.TZ} dangerouslySetInnerHTML={{ __html: data.technicka_zpr }} />

              <div className={classes.gap} />
              <div>Vypracoval: {data.technik}</div>

              <div className={classes.gap} />
              {this.props.ImagesSettings &&
                this.props.ImagesSettings.imgadding === "1" && (
                  <div>
                    {data.images &&
                      data.images.map((item) => (
                        <img
                          src={SERVER_URL + this.props.ImagesSettings.cesta + item.cesta}
                          key={item.cesta}
                          alt={item.nazev}
                          {...imgProp}
                          className={classes.image}
                        />
                      ))}
                  </div>
                )}

              {this.props.footer && <Footer />}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ImagesSettings: getImagesSettingsFromState(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(RZViewPrint));
