import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core/";
import { MoreVert as MoreIcon } from "@material-ui/icons";

const styles = (theme) => ({
  sectionDesktop: {
    display: "flex",
  },
});

class ButtonsOrMenu extends Component {
  state = {
    mobileMoreAnchorEl: null,
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { classes } = this.props;

    const buttons = this.props.buttons || [];

    const shouldShowAll =
      (isWidthUp(this.props.up || "sm", this.props.width) || buttons.length <= 1) &&
      (!this.props.maxShow || buttons.length <= this.props.maxShow);

    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

    const buttonsDesktop = buttons
      .map((buttonInfo, idx) => {
        if (buttonInfo.button !== undefined) {
          return buttonInfo.button;
        }
        return (
          <React.Fragment key={idx}>
            <IconButton color="inherit" onClick={buttonInfo.onClick}>
              <Tooltip disableTouchListener title={buttonInfo.label} placement="top-start">
                {buttonInfo.icon}
              </Tooltip>
            </IconButton>
          </React.Fragment>
        );
      })
      .filter((item) => !!item);

    const mobileMenuItems = (this.props.buttons || [])
      .map((buttonInfo, idx) => {
        if (buttonInfo.buttonMenu !== undefined) {
          return buttonInfo.buttonMenu;
        }
        return (
          <MenuItem onClick={buttonInfo.onClick} key={idx}>
            {buttonInfo.icon}
            <p>{buttonInfo.label}</p>
          </MenuItem>
        );
      })
      .filter((item) => !!item);

    const extras = (this.props.buttons || [])
      .map((buttonInfo, idx) => {
        return <React.Fragment key={idx}>{buttonInfo.extra}</React.Fragment>;
      })
      .filter((item) => !!item);

    return (
      <div className={this.props.className}>
        {shouldShowAll ? (
          <div className={classes.sectionDesktop}>{buttonsDesktop}</div>
        ) : mobileMenuItems.length ? (
          <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
            <MoreIcon />
          </IconButton>
        ) : null}
        <Menu
          anchorEl={this.state.mobileMoreAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
        >
          {mobileMenuItems}
        </Menu>
        {extras}
      </div>
    );
  }
}

ButtonsOrMenu.propTypes = {
  buttons: PropTypes.array,
  up: PropTypes.string,
  maxShow: PropTypes.number,
};

export default withWidth()(withStyles(styles)(ButtonsOrMenu));
