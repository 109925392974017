export function getCurrentYear() {
  return new Date().getFullYear();
}

export function getSQLFormatDate() {
  let d = new Date();
  return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
}

export function getDurationFromSec(sec) {
  let s, m, h, d;

  s = parseInt(sec % 60);
  sec = parseInt(sec / 60);
  m = sec % 60;
  sec = parseInt(sec / 60);
  h = sec % 24;
  sec = parseInt(sec / 24);
  d = sec;

  return (d ? d + "d " : "") + (h ? h + "h " : "") + (m ? m + "m " : "") + (s ? s + "s " : "");
}
