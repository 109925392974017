import { savePaginationAction, addRZImageSucceeded, RZ_ACTION_TYPES } from "./RZList.actions.js";
import { callApiFromThunks } from "../../../utils/defaults.js";

export function getAll(params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.rz.getPage(params),
      RZ_ACTION_TYPES.FETCHING_LIST,
      RZ_ACTION_TYPES.FETCH_LIST_FAILED,
      RZ_ACTION_TYPES.FETCH_LIST_SUCCEEDED
    );
  };
}

export function getOne(id, params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.rz.getOne(id, params),
      RZ_ACTION_TYPES.FETCHING,
      RZ_ACTION_TYPES.FETCH_FAILED,
      RZ_ACTION_TYPES.FETCH_SUCCEEDED
    );
  };
}

export function update(id, data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.rz.update(id, data),
      RZ_ACTION_TYPES.UPDATING,
      RZ_ACTION_TYPES.UPDATE_FAILED,
      RZ_ACTION_TYPES.UPDATE_SUCCEEDED
    );
  };
}

export function remove(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.rz.remove(id),
      RZ_ACTION_TYPES.REMOVING,
      RZ_ACTION_TYPES.REMOVE_FAILED,
      RZ_ACTION_TYPES.REMOVE_SUCCEEDED
    );
  };
}

export function copy(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.rz.copy(id),
      RZ_ACTION_TYPES.COPYING,
      RZ_ACTION_TYPES.COPY_FAILED,
      RZ_ACTION_TYPES.COPY_SUCCEEDED
    );
  };
}

export function savePagination(data) {
  return async (dispatch) => {
    dispatch(savePaginationAction(data.page, data.searchTerm, data.year, data.sort, data.sortOrder));
  };
}

export function addImage(id, data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.rz.addImage(id, data),
      RZ_ACTION_TYPES.ADDING_IMAGE,
      RZ_ACTION_TYPES.ADD_IMAGE_FAILED,
      addRZImageSucceeded
    );
  };
}

export function removeImage(cesta) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.rz.removeImage(cesta),
      RZ_ACTION_TYPES.REMOVING_IMAGE,
      RZ_ACTION_TYPES.REMOVE_IMAGE_FAILED,
      RZ_ACTION_TYPES.REMOVE_IMAGE_SUCCEEDED
    );
  };
}

export function updateImageOrder(data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.rz.updateImageOrder(data),
      RZ_ACTION_TYPES.UPDATING_IMAGE_ORDER,
      RZ_ACTION_TYPES.UPDATE_IMAGE_ORDER_FAILED,
      RZ_ACTION_TYPES.UPDATE_IMAGE_ORDER_SUCCEEDED
    );
  };
}
