import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ButtonsOrMenu from "../../components/Buttons/ButtonsOrMenu.js";
import { setAppBarForList, getIfUnd, getIfUndOrNull } from "../../utils/defaults.js";

import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
  Button,
  Zoom,
  TableSortLabel,
  Grid,
} from "@material-ui/core";
import { Add as AddIcon, Edit as EditIcon, FileCopyOutlined as CopyIcon } from "@material-ui/icons";

import { getAll, savePagination, copy as copyZZ } from "../../services/redux/ZZList/ZZList.thunks.js";
import { saveStrankovani } from "../../services/redux/Settings/Settings.thunks.js";
import {
  getAllZZFromState,
  getAllZZStateFromState,
  getAllZZErrorFromState,
  getAllZZPaginationFromState,
  getStrankovaniFromState,
  getOneZZCopiedFromState,
  getOneZZFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";
import TablePaginationActions from "../../components/Layout/TablePaginationActions.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    //minWidth: 700,
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  gridContainer: {
    paddingTop: theme.spacing.unit / 2,
  },
  grid: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
  endPadding: {
    height: "100px",
  },
  floatRight: {
    float: "right",
  },
  selectedRow: {
    backgroundColor: "rgba(0,0,100,0.1) !important",
  },
});

const headerConfig = [
  {
    id: "rok",
    label: "Rok",
  },
  {
    id: "provozovatel",
    label: "Provozovatel",
  },
  {
    id: "adresa_ulice",
    label: "Ulice",
  },
  {
    id: "adresa",
    label: "Adresa",
  },
];

class ZZList extends Component {
  constructor(props) {
    super(props);
    this.rowRefs = {};
  }

  async componentDidMount() {
    await this.loadData();
    setAppBarForList(this.props);

    if (this.props.OneData && this.rowRefs[this.props.OneData.ID]) {
      this.rowRefs[this.props.OneData.ID].scrollIntoView({ behavior: "smooth" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.Pagination.page !== this.props.Pagination.page ||
      nextProps.Pagination.searchTerm !== this.props.Pagination.searchTerm ||
      nextProps.Strankovani !== this.props.Strankovani ||
      nextProps.Pagination._sort !== this.props.Pagination._sort
    ) {
      this.loadData({
        ...nextProps.Pagination,
        pageSize: nextProps.Strankovani,
      });
      setAppBarForList(nextProps);
    }
    if (nextProps.AllDataState === DATA_STATE.COPY_SUCCEEDED && nextProps.CopiedData.newId) {
      this.goDetail(nextProps.CopiedData.newId)();
    }
  }

  loadData = async (pagination) => {
    pagination = pagination || {};
    let params = {
      page: getIfUnd(pagination.page, this.props.Pagination.page),
      pageSize: getIfUnd(pagination.pageSize, this.props.Strankovani),
      order: getIfUnd(pagination._sort, this.props.Pagination._sort),
    };

    if (getIfUndOrNull(pagination.searchTerm, this.props.Pagination.searchTerm)) {
      params["searchTerm"] = pagination.searchTerm || this.props.Pagination.searchTerm;
    }

    await this.props.getAll(params);
  };

  handleChangePage = (event, page) => {
    this.props.savePagination({ page: page });
  };

  handleChangeRowsPerPage = (event) => {
    this.props.saveStrankovani(event.target.value);
  };

  createSortHandler = (property) => (event) => {
    this.props.savePagination({
      sort: property,
      sortOrder:
        this.props.Pagination.sort === property ? (this.props.Pagination.sortOrder === "asc" ? "desc" : "asc") : "asc",
    });
  };

  goDetail = (id) => () => {
    this.props.history.push("/zz/detail/" + id);
  };

  copy = (id) => () => {
    this.props.copyZZ(id);
  };

  goView = (id) => () => {
    this.props.history.push("/zz/view/" + id);
  };

  addNew = () => {
    this.props.history.push("/zz/detail/new");
  };

  generateButtons = (id) => [
    {
      icon: <EditIcon />,
      onClick: this.goDetail(id),
      label: "Uprav",
    },
    {
      icon: <CopyIcon />,
      onClick: this.copy(id),
      label: "Namnož",
    },
  ];

  render() {
    const { classes, theme } = this.props;

    const isXsUp = isWidthUp("sm", this.props.width);

    const header = headerConfig.map((item, idx) => {
      return (
        <TableCell key={idx}>
          <TableSortLabel
            active={this.props.Pagination.sort === item.id}
            direction={this.props.Pagination.sortOrder}
            onClick={this.createSortHandler(item.id)}
          >
            {item.label}
          </TableSortLabel>
        </TableCell>
      );
    });

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          {this.props.AllDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <Table className={classes.table} padding="dense">
            <TableHead>
              <TableRow>
                {this.props.AllData &&
                  this.props.Strankovani && (
                    <TablePagination
                      colSpan={4}
                      count={this.props.AllData.totalCount}
                      rowsPerPage={this.props.Strankovani}
                      page={this.props.Pagination.page}
                      rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
              </TableRow>
              {isXsUp && (
                <TableRow>
                  {header}
                  <TableCell />
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {isXsUp &&
                this.props.AllData &&
                (this.props.AllData.items || []).map((i) => {
                  return (
                    <TableRow
                      key={i.ID}
                      hover
                      selected={this.props.OneData && this.props.OneData.ID === i.ID}
                      classes={{ selected: classes.selectedRow }}
                    >
                      <TableCell onClick={this.goView(i.ID)}>{i.rok}</TableCell>
                      <TableCell onClick={this.goView(i.ID)}>{i.provozovatel}</TableCell>
                      <TableCell onClick={this.goView(i.ID)}>{i.adresa_ulice}</TableCell>
                      <TableCell onClick={this.goView(i.ID)}>{i.adresa_obec}</TableCell>

                      <TableCell>
                        <span ref={(r) => (this.rowRefs[i.ID] = r)} />
                        <ButtonsOrMenu buttons={this.generateButtons(i.ID)} up="md" className={classes.floatRight} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!isXsUp &&
                this.props.AllData &&
                (this.props.AllData.items || []).map((i) => {
                  return (
                    <TableRow key={i.ID} hover>
                      <TableCell>
                        <Grid container direction="row" className={classes.gridContainer}>
                          <Grid item xs={5} className={classes.grid} onClick={this.goView(i.ID)}>
                            Provozovatel
                          </Grid>
                          <Grid item xs={7} className={classes.grid} onClick={this.goView(i.ID)}>
                            {i.provozovatel}
                          </Grid>
                          <Grid item xs={5} className={classes.grid} onClick={this.goView(i.ID)}>
                            Adresa
                          </Grid>
                          <Grid item xs={7} className={classes.grid} onClick={this.goView(i.ID)}>
                            {i.adresa_ulice}, {i.adresa_obec}
                          </Grid>
                          <Grid item xs={5} className={classes.grid} onClick={this.goView(i.ID)}>
                            Rok
                          </Grid>
                          <Grid item xs={7} className={classes.grid} onClick={this.goView(i.ID)}>
                            {i.rok}
                          </Grid>
                          <Grid item xs={5} />
                          <Grid item xs={7}>
                            <ButtonsOrMenu buttons={this.generateButtons(i.ID)} up="xs" />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {null}
            </TableBody>
            <TableFooter>
              <TableRow>
                {this.props.AllData &&
                  this.props.Strankovani && (
                    <TablePagination
                      colSpan={4}
                      count={this.props.AllData.totalCount}
                      rowsPerPage={this.props.Strankovani}
                      page={this.props.Pagination.page}
                      rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  )}
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
        <div className={classes.endPadding} />
        <Zoom in={true} timeout={theme.transitions.duration.enteringScreen} unmountOnExit>
          <Button variant="fab" className={classes.fab} color="secondary" onClick={this.addNew}>
            <AddIcon />
          </Button>
        </Zoom>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  AllData: getAllZZFromState(state),
  AllDataState: getAllZZStateFromState(state),
  AllDataError: getAllZZErrorFromState(state),
  Pagination: getAllZZPaginationFromState(state),
  Strankovani: getStrankovaniFromState(state),
  CopiedData: getOneZZCopiedFromState(state),
  OneData: getOneZZFromState(state),
});

const actionCreators = {
  getAll,
  savePagination,
  saveStrankovani,
  copyZZ,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withWidth()(withStyles(styles, { withTheme: true })(ZZList))));
