import axios from "axios";

export function getStrankovani() {
  return axios.get("settings/strankovani.php");
}

export function savePageSize(data) {
  return axios.put("settings/strankovani.php", data);
}

// vyrobce
export function getVyrobce() {
  return axios.get("settings/vyrobce.php");
}

export function updateVyrobce(vyrobceData) {
  return axios.post("settings/vyrobce.php", vyrobceData);
}

export function removeVyrobce(ID) {
  let params = { ID: ID };
  return axios.delete("settings/vyrobce.php", { params: params });
}

// shoda vyrobku
export function getShodaVyrobku() {
  return axios.get("settings/shoda-vyrobku.php");
}

export function updateShodaVyrobku(shodaData) {
  return axios.post("settings/shoda-vyrobku.php", shodaData);
}

export function removeShodaVyrobku(ID) {
  let params = { ID: ID };
  return axios.delete("settings/shoda-vyrobku.php", { params: params });
}

// images settings
export function getImages() {
  return axios.get("settings/images.php");
}

export function updateImages(imagesData) {
  return axios.post("settings/images.php", imagesData);
}

// tz default value
export function getTZDefault() {
  return axios.get("settings/tz-default.php");
}

export function updateTZDefault(data) {
  return axios.post("settings/tz-default.php", data);
}

// users
export function getUsers() {
  return axios.get("settings/uzivatel.php");
}

export function updateUser(userData) {
  return axios.post("settings/uzivatel.php", userData);
}

export function removeUser(IC) {
  let params = { IC: IC };
  return axios.delete("settings/uzivatel.php", { params: params });
}

// verze
export function getVerze() {
  return axios.get("settings/verze.php");
}

export function updateVerze(verzeData) {
  return axios.post("settings/verze.php", verzeData);
}

//stats
export function getStats() {
  return axios.get("settings/stats.php");
}

export function updateStats() {
  return axios.post("settings/stats.php");
}
