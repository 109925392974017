import React, { Component } from "react";

class HeaderCech extends Component {
  render() {
    return (
      <div
        id="hlavicka"
        style={{
          font: "13.5pt/1.5 'arial'",
          borderBottom: "2pt solid black",
          textAlign: "center",
          marginTop: "20pt",
        }}
      >
        Miroslav Čech - kominické služby, Jančí 12, 747 44 Březová, mob. 605 25 15 25
      </div>
    );
  }
}

export default HeaderCech;
