import { savePaginationAction, A_ACTION_TYPES } from "./AList.actions.js";
import { callApiFromThunks } from "../../../utils/defaults.js";

export function getAll(params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.a.getPage(params),
      A_ACTION_TYPES.FETCHING_LIST,
      A_ACTION_TYPES.FETCH_LIST_FAILED,
      A_ACTION_TYPES.FETCH_LIST_SUCCEEDED
    );
  };
}

export function getOne(id, params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.a.getOne(id, params),
      A_ACTION_TYPES.FETCHING,
      A_ACTION_TYPES.FETCH_FAILED,
      A_ACTION_TYPES.FETCH_SUCCEEDED
    );
  };
}

export function update(id, data) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.a.update(id, data),
      A_ACTION_TYPES.UPDATING,
      A_ACTION_TYPES.UPDATE_FAILED,
      A_ACTION_TYPES.UPDATE_SUCCEEDED
    );
  };
}

export function remove(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.a.remove(id),
      A_ACTION_TYPES.REMOVING,
      A_ACTION_TYPES.REMOVE_FAILED,
      A_ACTION_TYPES.REMOVE_SUCCEEDED
    );
  };
}

export function copy(id) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.a.copy(id),
      A_ACTION_TYPES.COPYING,
      A_ACTION_TYPES.COPY_FAILED,
      A_ACTION_TYPES.COPY_SUCCEEDED
    );
  };
}

export function savePagination(data) {
  return async (dispatch) => {
    dispatch(savePaginationAction(data.page, data.searchTerm, data.sort, data.sortOrder));
  };
}
