import { logError as logErrorAction, LOGGING_ACTION_TYPES, savePaginationAction } from "./Logging.actions.js";
import { ERROR } from "../constants.js";
import { callApiFromThunks } from "../../../utils/defaults.js";

export function logError(error, response) {
  return async (dispatch, getState, { api }) => {
    error.timestamp = new Date();

    if (error.errorType === ERROR.SERVER_ERROR || error.errorType === ERROR.UNKNOWN) {
      let request = response && {
        data: response.data,
        request: {
          url: response.config.url,
          method: response.config.method,
          data: response.config.data,
          params: response.config.params,
        },
        status: response.status,
      };

      let apiResponse;
      try {
        apiResponse = await api.logs.logError({
          timestamp: error.timestamp,
          ewhen: error.errorWhen,
          type: error.errorType,
          errors: error.errors.join("\n"),
          request: request,
          state: getState(),
        });
        error.ID = apiResponse.data.newId;
      } catch (e) {
        error.ID = -1;
      }
    }

    dispatch(logErrorAction(error));
  };
}

export function getAll(params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.logs.getPage(params),
      LOGGING_ACTION_TYPES.FETCHING_LIST,
      LOGGING_ACTION_TYPES.FETCH_LIST_FAILED,
      LOGGING_ACTION_TYPES.FETCH_LIST_SUCCEEDED
    );
  };
}

export function getOne(id, params) {
  return async (dispatch, getState, { api }) => {
    return await callApiFromThunks(
      dispatch,
      () => api.logs.getOne(id, params),
      LOGGING_ACTION_TYPES.FETCHING,
      LOGGING_ACTION_TYPES.FETCH_FAILED,
      LOGGING_ACTION_TYPES.FETCH_SUCCEEDED
    );
  };
}

export function savePagination(data) {
  return async (dispatch) => {
    dispatch(savePaginationAction(data.page, data.searchTerm, data.sort, data.sortOrder));
  };
}
