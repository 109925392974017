import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import DeleteConfirm from "../../components/Dialogs/DeleteConfirm.js";

import { withStyles } from "@material-ui/core/styles";
import { Paper, LinearProgress, Grid, Typography } from "@material-ui/core";

import {
  updateRowOrder as updateRowOrderT,
  addRow as addRowT,
  removeRow as removeRowT,
} from "../../services/redux/TList/TList.thunks.js";
import { addRow as addRowS, removeRow as removeRowS } from "../../services/redux/SList/SList.thunks.js";
import {
  getOneTFromState,
  getTRowStateFromState,
  getOneSFromState,
  getSRowStateFromState,
} from "../../services/redux/rootReducer.js";

import { DATA_STATE } from "../../services/redux/constants.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  headerMargin: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
});

class Rows extends Component {
  state = {
    rows: [],
    removeRadekConfirmOpenIdx: false,
  };

  componentDidMount() {
    this.setState({ rows: this.props.rows.concat([null]) });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rows && nextProps.rows.length !== (this.props.rows || []).length) {
      let rows = nextProps.rows;
      if (rows.length === 0 || (rows[rows.length - 1] && rows[rows.length - 1].ID)) {
        rows.push(null);
      }
      this.setState({ rows: rows });
    }
  }

  handleChangeRow = (idx) => (row) => {
    let newData = this.state.rows;
    newData[idx] = row;
    this.setState({ rows: newData }, () => this.props.handleChangeRows(this.state.rows));
  };

  onOrderChanged = (idx) => (dir) => () => {
    if (idx + dir < 0 || idx + dir >= this.state.rows.length) {
      return;
    }
    let rows = this.state.rows;
    rows.splice(idx + dir, 0, rows.splice(idx, 1)[0]);

    this.setState({ rows: rows }, () => {
      var data = {};
      this.state.rows.forEach((item, idx) => {
        if (item && item.ID) {
          data[item.ID] = idx + 1;
        }
      });

      this.props.updateRowOrder(data);
    });
  };

  handleAddRowClick = async () => {
    let resp = await this.props.addRow(this.props.IDhl, this.state.rows[this.state.rows.length - 1]);
    if (resp.newId) {
      let rows = this.state.rows;
      rows[rows.length - 1].ID = resp.newId;
      rows[rows.length - 1].poradi = rows.length;
      rows.push(null);
      this.setState({ rows: rows });
    }
  };

  handleRowRemoveClick = (rowIdx) => () => {
    this.setState({ removeRadekConfirmOpenIdx: rowIdx });
  };
  removeRow = (shouldRemove) => {
    let rows = this.state.rows;
    if (shouldRemove) {
      let removed = rows.splice(this.state.removeRadekConfirmOpenIdx, 1);
      this.props.removeRow(removed[0].ID);
    }
    this.setState({ removeRadekConfirmOpenIdx: false, rows: rows });
  };

  render() {
    const { classes } = this.props;
    const RowItem = this.props.rowItem;

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          {this.props.RowDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          {this.props.IDhl === "new" ? (
            <Typography className={classes.headerMargin}>Nejdříve je třeba uložit zprávu</Typography>
          ) : (
            <div className={classes.container}>
              <Grid container direction="row">
                {(this.state.rows || []).map((i, idx) => {
                  return (
                    <RowItem
                      key={idx}
                      row={i}
                      IDhl={this.props.IDhl}
                      onOrderChanged={this.onOrderChanged(idx)}
                      handleChangeRow={this.handleChangeRow(idx)}
                      handleRowRemoveClick={this.handleRowRemoveClick(idx)}
                      handleAddRowClick={this.handleAddRowClick}
                    />
                  );
                })}
              </Grid>
            </div>
          )}
        </Paper>

        <DeleteConfirm
          open={this.state.removeRadekConfirmOpenIdx !== false}
          onClose={this.removeRow}
          label="Opravdu chcete smazat tento řádek?"
        />
      </React.Fragment>
    );
  }
}

Rows.propTypes = {
  rows: PropTypes.array.isRequired,
  IDhl: PropTypes.string.isRequired,
  handleChangeRows: PropTypes.func.isRequired,
  rowItem: PropTypes.func.isRequired,
};

const mapStateToPropsT = (state) => ({
  RowDataState: getTRowStateFromState(state),
  OneData: getOneTFromState(state),
});

const actionCreatorsT = {
  updateRowOrder: updateRowOrderT,
  addRow: addRowT,
  removeRow: removeRowT,
};

const TRows = connect(
  mapStateToPropsT,
  actionCreatorsT
)(withStyles(styles, { withTheme: true })(Rows));

const mapStateToPropsS = (state) => ({
  RowDataState: getSRowStateFromState(state),
  OneData: getOneSFromState(state),
});

const actionCreatorsS = {
  addRow: addRowS,
  removeRow: removeRowS,
};

const SRows = connect(
  mapStateToPropsS,
  actionCreatorsS
)(withStyles(styles, { withTheme: true })(Rows));

export { TRows, SRows };
