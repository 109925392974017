export const A_ACTION_TYPES = {
  FETCHING_LIST: "USER/A/FETCHING_LIST",
  FETCH_LIST_SUCCEEDED: "USER/A/FETCH_SUCCEEDED_LIST",
  FETCH_LIST_FAILED: "USER/A/FETCH_FAILED_LIST",
  FETCHING: "USER/A/FETCHING",
  FETCH_SUCCEEDED: "USER/A/FETCH_SUCCEEDED",
  FETCH_FAILED: "USER/A/FETCH_FAILED",
  UPDATING: "USER/A/UPDATING",
  UPDATE_SUCCEEDED: "USER/A/UPDATE_SUCCEEDED",
  UPDATE_FAILED: "USER/A/UPDATE_FAILED",
  REMOVING: "USER/A/REMOVING",
  REMOVE_SUCCEEDED: "USER/A/REMOVE_SUCCEEDED",
  REMOVE_FAILED: "USER/A/REMOVE_FAILED",
  COPYING: "USER/A/COPYING",
  COPY_SUCCEEDED: "USER/A/COPY_SUCCEEDED",
  COPY_FAILED: "USER/A/COPY_FAILED",

  PAGINATION: "USER/A/PAGINATION",
};

export function savePaginationAction(page, searchTerm, sort, sortOrder) {
  return {
    type: A_ACTION_TYPES.PAGINATION,
    page,
    searchTerm,
    sort,
    sortOrder,
  };
}
