export const ZZ_ACTION_TYPES = {
  FETCHING_LIST: "USER/ZZ/FETCHING_LIST",
  FETCH_LIST_SUCCEEDED: "USER/ZZ/FETCH_SUCCEEDED_LIST",
  FETCH_LIST_FAILED: "USER/ZZ/FETCH_FAILED_LIST",
  FETCHING: "USER/ZZ/FETCHING",
  FETCH_SUCCEEDED: "USER/ZZ/FETCH_SUCCEEDED",
  FETCH_FAILED: "USER/ZZ/FETCH_FAILED",
  UPDATING: "USER/ZZ/UPDATING",
  UPDATE_SUCCEEDED: "USER/ZZ/UPDATE_SUCCEEDED",
  UPDATE_FAILED: "USER/ZZ/UPDATE_FAILED",
  REMOVING: "USER/ZZ/REMOVING",
  REMOVE_SUCCEEDED: "USER/ZZ/REMOVE_SUCCEEDED",
  REMOVE_FAILED: "USER/ZZ/REMOVE_FAILED",
  COPYING: "USER/ZZ/COPYING",
  COPY_SUCCEEDED: "USER/ZZ/COPY_SUCCEEDED",
  COPY_FAILED: "USER/ZZ/COPY_FAILED",

  PAGINATION: "USER/ZZ/PAGINATION",
};

export function savePaginationAction(page, searchTerm, sort, sortOrder) {
  return {
    type: ZZ_ACTION_TYPES.PAGINATION,
    page,
    searchTerm,
    sort,
    sortOrder,
  };
}
