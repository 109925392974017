export const LOGIN_ACTION_TYPES = {
  LOGGING_IN: "USER/LOGIN/LOGGING_IN",
  LOGGED_IN: "USER/LOGIN/LOGGED_IN",
  LOGIN_FAILED: "USER/LOGIN/LOGIN_FAILED",
  FETCHING_USER: "USER/LOGIN/FETCHING_USER",
  USER_FETCHED: "USER/LOGIN/USER_FETCHED",
  USER_FETCH_FAILED: "USER/LOGIN/USER_FETCH_FAILED",
};

export function loggingIn() {
  return {
    type: LOGIN_ACTION_TYPES.LOGGING_IN,
  };
}

export function loggedIn(data) {
  return {
    type: LOGIN_ACTION_TYPES.LOGGED_IN,
    data,
  };
}

export function loginFailed(errorType) {
  return {
    type: LOGIN_ACTION_TYPES.LOGIN_FAILED,
    errorType,
  };
}

export function fetchingUser() {
  return {
    type: LOGIN_ACTION_TYPES.FETCHING_USER,
  };
}

export function userFetched(data) {
  return {
    type: LOGIN_ACTION_TYPES.USER_FETCHED,
    data,
  };
}

export function userFetchFailed(errorType) {
  return {
    type: LOGIN_ACTION_TYPES.USER_FETCH_FAILED,
    errorType,
  };
}
