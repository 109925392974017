import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {} from "@material-ui/core";

import { print as printStyles } from "../../../constants/commonStyles.js";
import { getUserNameFromState } from "../../../services/redux/rootReducer.js";
import Header from "../../../components/Headers/Header.js";

const styles = {
  root: printStyles.root,
  page: printStyles.page,
  headerH1: printStyles.headerH1,
  headerH2: printStyles.headerH2,
  headerH3: printStyles.headerH3,
  gap: printStyles.gap,
  table: printStyles.tableWithPadding,
  value: printStyles.value,
  valueRight: printStyles.valueRight270,
  label: printStyles.label100,
  pcenter: {
    textAlign: "center",
  },
};

const mereniRows = [
  {
    label: "Teplota vody",
    value: "°C",
  },
  {
    label: "Teplota spalin",
    value: "°C",
  },
  {
    label: (
      <span>
        0<sub>2</sub>
      </span>
    ),
    value: "%",
  },
  {
    label: "CO",
    value: (
      <span>
        mg.m
        <sup>-3</sup>
      </span>
    ),
  },
  {
    label: "NO",
    value: (
      <span>
        mg.m
        <sup>-3</sup>
      </span>
    ),
  },
  {
    label: "Teplota okolí",
    value: "°C",
  },
  {
    label: (
      <span>
        C0
        <sub>2</sub>
      </span>
    ),
    value: "%",
  },
  {
    label: "Účinnost",
    value: "%",
  },
  {
    label: "Komínové ztráty",
    value: "%",
  },
  {
    label: "Přebytek vzduchu",
  },
  {
    label: "Tah",
    value: "Pa",
  },
  {
    label: (
      <span>
        CO 3% O<sub>2</sub>
      </span>
    ),
    value: (
      <span>
        mg.m
        <sup>-3</sup>
      </span>
    ),
  },
  {
    label: (
      <span>
        NO 3% O<sub>2</sub>
      </span>
    ),
    value: (
      <span>
        mg.m
        <sup>-3</sup>
      </span>
    ),
  },
];

class PRViewPrint extends Component {
  render() {
    const { classes, data } = this.props;

    return (
      <React.Fragment>
        {data && (
          <div className={classes.root}>
            <div className={classes.page}>
              <Header />
              <table cellPadding="0px" cellSpacing="0px" className={classes.table}>
                <tbody>
                  <tr>
                    <td className={classes.label}>Jméno:</td>
                    <td className={classes.value}>{data.jmeno}</td>
                  </tr>
                  <tr>
                    <td className={classes.label}>Adresa:</td>
                    <td className={classes.value}>{data.adresa}</td>
                  </tr>
                </tbody>
              </table>

              <div className={classes.gap} />

              <table cellPadding="0px" cellSpacing="0px" className={classes.table}>
                <tbody>
                  <tr>
                    <td className={classes.label}>Typ kotle</td>
                    <td className={classes.valueRight}>{data.typ_kotle}</td>
                    <td />
                    <td className={classes.label}>Štítk. výkon</td>
                    <td className={classes.valueRight}>{data.adresa}</td>
                  </tr>
                  <tr>
                    <td className={classes.label}>Druh hořáku</td>
                    <td className={classes.valueRight}>{data.horak}</td>
                    <td />
                    <td className={classes.label}>Rok výroby</td>
                    <td className={classes.valueRight}>{data.rok_vyroby}</td>
                  </tr>
                  <tr>
                    <td className={classes.label}>Druh paliva</td>
                    <td className={classes.valueRight}>{data.palivo}</td>
                    <td />
                    <td className={classes.label}>Výrobní číslo</td>
                    <td className={classes.valueRight}>{data.vyrobni_cislo}</td>
                  </tr>
                  <tr>
                    <td className={classes.label} />
                    <td className={classes.valueRight} />
                    <td />
                    <td className={classes.label}>Místní číslo</td>
                    <td className={classes.valueRight}>{data.mistni_cislo}</td>
                  </tr>
                </tbody>
              </table>

              <div className={classes.gap} />

              <h1 className={classes.headerH1}>PROTOKOL</h1>
              <h2 className={classes.headerH2}>o výsledku měření komínové ztráty a komínového tahu</h2>

              <div className={classes.gap} />

              <table style={{ fontSize: "100%", margin: "auto" }}>
                <tbody>
                  {mereniRows.map((i, idx) => {
                    if (idx === 11) {
                      idx = 13;
                    }
                    return (
                      <tr key={idx}>
                        <td>{i.label}</td>
                        <td style={{ textAlign: "right", width: "2cm" }}>{data["mereni" + idx]}</td>
                        <td>{i.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <p className={classes.table}>
                Změřené a přepočtené hodnoty spalovacího procesu jsou vyhodnoceny elektrochemickým analyzátorem spalin
                ECOM-CD. Koncentrace CO a NO uvedené na 3% obj. O<sub>2</sub> ve spalinách jsou přepočtené na suchý plyn
                při 101,32 kPa a 0 °C.
              </p>

              <div className={classes.gap} />

              <p className={classes.table} style={{ height: "2cm" }}>
                Poznámka: {data.poznamka}
              </p>

              <div className={classes.gap} />

              <table className={classes.table}>
                <tbody>
                  <tr>
                    <td style={{ width: "3.5cm" }}>Měření provedl:</td>
                    <td>{data.osoba}</td>
                  </tr>
                  <tr>
                    <td>V Opavě dne:</td>
                    <td>{new Date(data.datum).toLocaleDateString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  User: getUserNameFromState(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(PRViewPrint));
