import axios from "axios";

export function getPage(params) {
  return axios.get("tabulky/index.php", { params: params });
}

export function getOne(id, params) {
  params = params || {};
  params.ID = id;
  return axios.get("tabulky/index.php", { params: params });
}

export function update(id, data) {
  let params = { ID: id };
  return axios.post("tabulky/index.php", data, { params: params });
}

export function remove(id) {
  let params = { ID: id };
  return axios.delete("tabulky/index.php", { params: params });
}

export function copy(id) {
  let params = { ID: id };
  return axios.post("tabulky/copy.php", null, { params: params });
}

export function addRow(id, data) {
  return axios.post("tabulky/radky.php", data, { params: { ID: id } });
}

export function removeRow(id) {
  return axios.delete("tabulky/radky.php", { params: { ID: id } });
}

export function updateRowOrder(data) {
  return axios.patch("tabulky/radky.php", data);
}
