import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {} from "@material-ui/core";

import { print as printStyles } from "../../../constants/commonStyles.js";
import { getUserNameFromState } from "../../../services/redux/rootReducer.js";

const styles = {
  root: printStyles.root,
  page: printStyles.breakablePage,
  headerH1: printStyles.headerH1,
  headerH2: printStyles.headerH2,
  headerH3: printStyles.headerH3,
  gap: printStyles.gap,
  table: printStyles.tableWithBorders,
  splitTableRows: {
    pageBreakInside: "avoid",
    pageBreakAfter: "auto",
  },
};

class TViewPrint extends Component {
  render() {
    const { classes, data } = this.props;

    const hasTuha = data && data.radky.some((row) => row && !!row.tuha);

    return (
      <React.Fragment>
        {data && (
          <div className={classes.root}>
            <div className={classes.page}>
              <h1 className={classes.headerH1}>ZÁZNAM</h1>
              <h2 className={classes.headerH2}>o provedené kontrole spalinových cest v objektu</h2>
              <div className={classes.gap} />
              <h1 className={classes.headerH1}>{data.adresa}</h1>
              <div className={classes.gap} />
              Seznam nájemníků s přehledem spotřebičů
              <table cellPadding="0px" cellSpacing="0px" className={classes.table}>
                <thead>
                  <tr>
                    <td colSpan="3">&nbsp;</td>
                    <td>P</td>
                    <td>měření CO</td>
                    {hasTuha && <td>T</td>}
                    <td>datum</td>
                    <td>rok {data.rok}</td>
                  </tr>
                </thead>
                <tbody>
                  {(data.radky || []).map((rowData, idx) => {
                    if (!rowData) return null;
                    return (
                      <tr key={idx} className={classes.splitTableRows}>
                        <td style={{ width: "4.6%" }}>
                          {rowData.poschodi}
                          <br />
                          <br />
                          {idx + 1}
                        </td>
                        <td style={{ width: "17.2%" }}>{rowData.jmeno}</td>
                        <td style={{ width: "35.8%" }}>{rowData.kotel}</td>
                        <td style={{ width: "1.3%" }}>{rowData.plyn}</td>
                        <td style={{ width: "10.5%" }} />
                        {hasTuha && <td style={{ width: "1.3%" }}>{rowData.tuha}</td>}
                        <td style={{ width: "9.3%" }} />
                        <td style={{ width: "20%" }} />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  User: getUserNameFromState(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(TViewPrint));
