export const LOGGING_ACTION_TYPES = {
  LOG_ERROR: "USER/LOGGING/LOG_ERROR",

  FETCHING_LIST: "USER/LOGGING/FETCHING_LIST",
  FETCH_LIST_SUCCEEDED: "USER/LOGGING/FETCH_SUCCEEDED_LIST",
  FETCH_LIST_FAILED: "USER/LOGGING/FETCH_FAILED_LIST",
  FETCHING: "USER/LOGGING/FETCHING",
  FETCH_SUCCEEDED: "USER/LOGGING/FETCH_SUCCEEDED",
  FETCH_FAILED: "USER/LOGGING/FETCH_FAILED",

  PAGINATION: "USER/RZ/PAGINATION",
};

export function logError(data) {
  return {
    type: LOGGING_ACTION_TYPES.LOG_ERROR,
    data,
  };
}

export function savePaginationAction(page, searchTerm, sort, sortOrder) {
  return {
    type: LOGGING_ACTION_TYPES.PAGINATION,
    page,
    searchTerm,
    sort,
    sortOrder,
  };
}
