import axios from "axios";

export function getPage(params) {
  return axios.get("protokoly/index.php", { params: params });
}

export function getOne(id, params) {
  params = params || {};
  params.ID = id;
  return axios.get("protokoly/index.php", { params: params });
}

export function update(id, data) {
  let params = { ID: id };
  return axios.post("protokoly/index.php", data, { params: params });
}

export function remove(id) {
  let params = { ID: id };
  return axios.delete("protokoly/index.php", { params: params });
}

export function copy(id) {
  let params = { ID: id };
  return axios.post("protokoly/copy.php", null, { params: params });
}
