import { isDevelopment } from "./utils/environment";
import { applyMiddleware, compose, createStore } from "redux";
import rootReducer, { createStateFromPersistedValues, getPersistedValuesFromState } from "./services/redux/rootReducer";
import throttle from "lodash/throttle.js";
import { loadState, saveState } from "./services/persistence/persistence";
import thunk from "redux-thunk";
import api from "./services/api/api";

const enhancers = [];
const middleware = [thunk.withExtraArgument({ api })];

if (isDevelopment()) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const persistedState = createStateFromPersistedValues(loadState());
const store = createStore(rootReducer, persistedState, composedEnhancers);
store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveState(getPersistedValuesFromState(state));
  }, 1000)
);

export default store;
