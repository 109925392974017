export const S_ACTION_TYPES = {
  FETCHING_LIST: "USER/S/FETCHING_LIST",
  FETCH_LIST_SUCCEEDED: "USER/S/FETCH_SUCCEEDED_LIST",
  FETCH_LIST_FAILED: "USER/S/FETCH_FAILED_LIST",
  FETCHING: "USER/S/FETCHING",
  FETCH_SUCCEEDED: "USER/S/FETCH_SUCCEEDED",
  FETCH_FAILED: "USER/S/FETCH_FAILED",
  UPDATING: "USER/S/UPDATING",
  UPDATE_SUCCEEDED: "USER/S/UPDATE_SUCCEEDED",
  UPDATE_FAILED: "USER/S/UPDATE_FAILED",
  REMOVING: "USER/S/REMOVING",
  REMOVE_SUCCEEDED: "USER/S/REMOVE_SUCCEEDED",
  REMOVE_FAILED: "USER/S/REMOVE_FAILED",
  COPYING: "USER/S/COPYING",
  COPY_SUCCEEDED: "USER/S/COPY_SUCCEEDED",
  COPY_FAILED: "USER/S/COPY_FAILED",

  PAGINATION: "USER/S/PAGINATION",

  ADDING_ROW: "USER/S/ADDING_ROW",
  ADD_ROW_SUCCEEDED: "USER/S/ADD_ROW_SUCCEEDED",
  ADD_ROW_FAILED: "USER/S/ADD_ROW_FAILED",
  REMOVING_ROW: "USER/S/REMOVING_ROW",
  REMOVE_ROW_SUCCEEDED: "USER/S/REMOVE_ROW_SUCCEEDED",
  REMOVE_ROW_FAILED: "USER/S/REMOVE_ROW_FAILED",
};

export function savePaginationAction(page, searchTerm, sort, sortOrder) {
  return {
    type: S_ACTION_TYPES.PAGINATION,
    page,
    searchTerm,
    sort,
    sortOrder,
  };
}
export function addSRowSucceeded(data) {
  return {
    type: S_ACTION_TYPES.ADD_ROW_SUCCEEDED,
    data: data.data,
  };
}
