import * as login from "./endpoints/login";
import * as rz from "./endpoints/rz";
import * as pz from "./endpoints/pz";
import * as t from "./endpoints/t";
import * as s from "./endpoints/s";
import * as pr from "./endpoints/pr";
import * as a from "./endpoints/a";
import * as zz from "./endpoints/zz";
import * as logs from "./endpoints/logs";
import * as settings from "./endpoints/settings";

export default {
  login,
  rz,
  pz,
  t,
  s,
  pr,
  a,
  zz,
  logs,
  settings,
};
