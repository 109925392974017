import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getIfUnd, getIfUndOrNull, setAppBarForList } from "../../utils/defaults.js";

import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  LinearProgress,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import {} from "@material-ui/icons";

import { getAll, savePagination, getOne } from "../../services/redux/Logging/Logging.thunks.js";
import { saveStrankovani } from "../../services/redux/Settings/Settings.thunks.js";
import {
  getStrankovaniFromState,
  getAllLogsFromState,
  getLogsStateFromState,
  getLogsErrorFromState,
  getLogsPaginationFromState,
  getOneLogsFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  wrap: {
    whiteSpace: "pre-wrap",
    fontSize: theme.typography.fontSize,
  },
});

const headerConfig = [
  {
    id: "timestamp",
    label: "Time",
  },
  {
    id: "ewhen",
    label: "When",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "errors",
    label: "Errors",
  },
];

class LogsList extends Component {
  state = {
    showLogDetail: false,
  };

  componentDidMount() {
    this.loadData();

    setAppBarForList(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.Pagination.page !== this.props.Pagination.page ||
      nextProps.Pagination.searchTerm !== this.props.Pagination.searchTerm ||
      nextProps.Strankovani !== this.props.Strankovani ||
      nextProps.Pagination._sort !== this.props.Pagination._sort
    ) {
      this.loadData({
        ...nextProps.Pagination,
        pageSize: nextProps.Strankovani,
      });
      setAppBarForList(nextProps);
    }
    if (nextProps.AllDataState === DATA_STATE.COPY_SUCCEEDED && nextProps.CopiedData.newId) {
      this.goDetail(nextProps.CopiedData.newId)();
    }
  }

  loadData = (pagination) => {
    pagination = pagination || {};
    let params = {
      page: getIfUnd(pagination.page, this.props.Pagination.page),
      pageSize: getIfUnd(pagination.pageSize, this.props.Strankovani),
      order: getIfUnd(pagination._sort, this.props.Pagination._sort),
    };

    if (getIfUndOrNull(pagination.searchTerm, this.props.Pagination.searchTerm)) {
      params["searchTerm"] = pagination.searchTerm || this.props.Pagination.searchTerm;
    }

    this.props.getAll(params);
  };

  handleChangePage = (event, page) => {
    this.props.savePagination({ page: page });
  };

  handleChangeRowsPerPage = (event) => {
    this.props.saveStrankovani(event.target.value);
  };

  createSortHandler = (property) => (event) => {
    this.props.savePagination({
      sort: property,
      sortOrder:
        this.props.Pagination.sort === property ? (this.props.Pagination.sortOrder === "asc" ? "desc" : "asc") : "asc",
    });
  };

  goView = (id) => async () => {
    await this.props.getOne(id);
    this.setState({ showLogDetail: true });
  };

  onDetailClose = () => {
    this.setState({ showLogDetail: false });
  };

  render() {
    const { classes } = this.props;

    const header = headerConfig.map((item, idx) => {
      return (
        <TableCell key={idx}>
          <TableSortLabel
            active={this.props.Pagination.sort === item.id}
            direction={this.props.Pagination.sortOrder}
            onClick={this.createSortHandler(item.id)}
          >
            {item.label}
          </TableSortLabel>
        </TableCell>
      );
    });

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          {this.props.DataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <Table className={classes.table} padding="dense">
            <TableHead>
              <TableRow>{header}</TableRow>
            </TableHead>
            <TableBody>
              {this.props.AllData &&
                (this.props.AllData.items || []).map((i) => {
                  return (
                    <TableRow key={i.ID} hover>
                      <TableCell onClick={this.goView(i.ID)}>
                        {new Date(i.timestamp).toLocaleDateString()} {new Date(i.timestamp).toLocaleTimeString()}
                      </TableCell>

                      <TableCell onClick={this.goView(i.ID)}>{i.ewhen}</TableCell>
                      <TableCell onClick={this.goView(i.ID)}>{i.type}</TableCell>
                      <TableCell onClick={this.goView(i.ID)} className={classes.wrap}>
                        {i.errors}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {null}
            </TableBody>
            <TableFooter>
              <TableRow>
                {this.props.AllData &&
                  this.props.Strankovani && (
                    <TablePagination
                      colSpan={7}
                      count={this.props.AllData.totalCount}
                      rowsPerPage={this.props.Strankovani}
                      page={this.props.Pagination.page}
                      rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  )}
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
        <Dialog
          maxWidth="lg"
          aria-labelledby="confirmation-dialog-title"
          onClose={this.onDetailClose}
          open={this.state.showLogDetail}
        >
          <DialogTitle id="confirmation-dialog-title">Log</DialogTitle>
          <DialogContent>
            Request
            {this.props.OneData && <pre>{JSON.stringify(this.props.OneData.request, null, 2)}</pre>}
            Redux state
            {this.props.OneData && <pre>{JSON.stringify(this.props.OneData.state, null, 2)}</pre>}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  AllData: getAllLogsFromState(state),
  OneData: getOneLogsFromState(state),
  DataState: getLogsStateFromState(state),
  DataError: getLogsErrorFromState(state),
  Pagination: getLogsPaginationFromState(state),
  Strankovani: getStrankovaniFromState(state),
});

const actionCreators = {
  getAll,
  getOne,
  savePagination,
  saveStrankovani,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles, { withTheme: true })(LogsList)));
