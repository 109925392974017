import React, { Component } from "react";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

class DeleteConfirm extends Component {
  handleCancel = () => {
    this.props.onClose(false, this.props.extraData);
  };

  handleOk = () => {
    this.props.onClose(true, this.props.extraData);
  };

  render() {
    const { yesMessage, noMessage, extraData, ...config } = this.props;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        {...config}
      >
        <DialogTitle id="confirmation-dialog-title">{this.props.label}</DialogTitle>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            {noMessage || "Ne"}
          </Button>
          <Button onClick={this.handleOk} color="primary">
            {yesMessage || "Ano"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteConfirm.propTypes = {
  onClose: PropTypes.func,
  label: PropTypes.string.isRequired,
  yesMessage: PropTypes.string,
  noMessage: PropTypes.string,
  extraData: PropTypes.any,
};

export default DeleteConfirm;
