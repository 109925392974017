import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentYear } from "../../utils/date.js";

import DeleteConfirm from "../../components/Dialogs/DeleteConfirm.js";

import { withStyles } from "@material-ui/core/styles";
import { Paper, CircularProgress, LinearProgress, Typography, Grid, TextField, Button, Zoom } from "@material-ui/core";
import { Save as SaveIcon, Check as CheckIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { getOne, update, remove, savePagination } from "../../services/redux/TList/TList.thunks.js";
import {
  getOneTFromState,
  getOneTUpdatedFromState,
  getOneTStateFromState,
  getAllTPaginationFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";
import { TRows } from "../../components/Inputs/Rows.js";
import TDetailRow from "./components/TDetailRow.js";
import { setAppBarForDetail } from "../../utils/defaults.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  gridCell: {},
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
});

class TDetail extends Component {
  state = {
    data: {
      adresa: "",
      rok: getCurrentYear(),
      radky: [],
    },
    saved: true,
    removeConfirmOpen: false,
  };

  componentDidMount() {
    this.props.match.params.id !== "new" && this.props.getOne(this.props.match.params.id);

    setAppBarForDetail(this.props, "/t/list", {
      icon: <DeleteIcon />,
      label: "Smaž",
      onClick: this.handleRemoveClick,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.OneDataState === DATA_STATE.REMOVE_SUCCEEDED) {
      this.props.history.push("/t/list");
    }
    // set data to state after fetch
    if (this.props.OneData && this.props.OneData !== prevProps.OneData) {
      this.setState({ data: this.props.OneData });
    }
    // set saved flag after save and reload after new has been added
    if (
      this.props.OneDataState === DATA_STATE.UPDATE_SUCCEEDED &&
      prevProps.OneDataState !== DATA_STATE.UPDATE_SUCCEEDED
    ) {
      this.setState({ saved: true });

      if (this.props.askWhenNavigatingAway && this.props.setAskWhenNavigatingAway) {
        this.props.setAskWhenNavigatingAway(false);
      }
      if (this.props.match.params.id === "new" && this.props.UpdatedData) {
        this.props.history.push(`/t/detail/${this.props.UpdatedData.newId}`);
        this.props.getOne(this.props.UpdatedData.newId);
      }
    }
  }

  handleChange = (name) => (event) => {
    let newData = this.state.data;
    newData[name] = event.target.value;
    this.setState({ data: newData, saved: false });

    if (this.props.setAskWhenNavigatingAway) {
      this.props.setAskWhenNavigatingAway(true);
    }
  };

  handleChangeRows = (rows) => {
    let newData = this.state.data;
    newData.radky = rows;
    this.setState({ data: newData, saved: false });

    if (this.props.setAskWhenNavigatingAway) {
      this.props.setAskWhenNavigatingAway(true);
    }
  };

  save = () => {
    if (this.state.saved) {
      this.props.history.push("/t/view/" + this.props.match.params.id);
    } else if (this.props.OneDataState !== DATA_STATE.UPDATE_IN_PROGRESS) {
      this.props.update(this.props.match.params.id, this.state.data);
    }
  };

  handleRemoveClick = () => {
    this.setState({ removeConfirmOpen: true });
  };
  remove = (shouldRemove) => {
    if (shouldRemove) {
      this.props.remove(this.props.match.params.id);
    }
    this.setState({ removeConfirmOpen: false });
  };

  render() {
    const { classes, theme } = this.props;
    const data = this.props.OneData;
    const ID = this.props.match.params.id;

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          {data && ID !== "new" && `${data.adresa}, ${data.rok}`}
          {ID === "new" && "Nový"}
        </Typography>
        <Paper className={classes.root}>
          {this.props.OneDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container direction="row">
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="adresa"
                  label="Adresa"
                  margin="normal"
                  fullWidth
                  value={this.state.data.adresa}
                  onChange={this.handleChange("adresa")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="rok"
                  label="Rok"
                  margin="normal"
                  fullWidth
                  value={this.state.data.rok}
                  onChange={this.handleChange("rok")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
        <br />
        <TRows rows={this.state.data.radky} IDhl={ID} handleChangeRows={this.handleChangeRows} rowItem={TDetailRow} />

        <div className={classes.fab}>
          <Zoom in={true} timeout={theme.transitions.duration.enteringScreen} unmountOnExit>
            <Button variant="fab" color="secondary" onClick={this.save}>
              {this.state.saved ? <CheckIcon /> : <SaveIcon />}
            </Button>
          </Zoom>
          {this.props.OneDataState === DATA_STATE.UPDATE_IN_PROGRESS && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
        <DeleteConfirm
          open={this.state.removeConfirmOpen}
          onClose={this.remove}
          label="Opravdu chcete smazat tuto zprávu?"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  OneData: getOneTFromState(state),
  UpdatedData: getOneTUpdatedFromState(state),
  OneDataState: getOneTStateFromState(state),
  Pagination: getAllTPaginationFromState(state),
});

const actionCreators = {
  getOne,
  update,
  remove,
  savePagination,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles, { withTheme: true })(TDetail)));
