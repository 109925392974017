import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import {} from "@material-ui/core";

import { print as printStyles } from "../../../constants/commonStyles.js";
import { getUserNameFromState } from "../../../services/redux/rootReducer.js";

const styles = {
  root: printStyles.root,
  page: printStyles.page,
  headerH1: printStyles.headerH1,
  headerH2: printStyles.headerH2,
  headerH3: printStyles.headerH3,
  gap: printStyles.gap,
  table: printStyles.tableWithPadding,
  value: printStyles.value,
  valueRight: printStyles.valueRight270,
  pcenter: {
    textAlign: "center",
  },
  labelShort: {
    width: "70px",
    display: "inline-block",
    lineHeight: "1.5em",
  },
  label: {
    width: "110px",
    display: "inline-block",
    lineHeight: "1.5em",
  },
  labelLong: {
    width: "140px",
    display: "inline-block",
    lineHeight: "1.5em",
  },
  head1: {
    border: "3px solid black",
    margin: "0 -10px",
    padding: "10px",
  },
  h2: {
    textAlign: "center",
    textDecoration: "underline",
    marginTop: 0,
  },
  head2: {
    borderBottom: "1px solid black",
    margin: "0 -10px",
    padding: "20px 10px",
  },
  innerFlex: {
    display: "flex",
    flexDirection: "row",
  },
  flexLeft: {
    width: "70%",
  },
  textRight: {
    textAlign: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  flexItemGrow: {
    flexGrow: 1,
  },
  tableRow: {
    fontSize: "90%",
    lineHeight: "1.3em",
  },
  topMargin: {
    marginTop: "30px",
  },
  table2Col1: {
    width: "22%",
    lineHeight: "1.5em",
  },
  table2Col2: {
    width: "13%",
    lineHeight: "1.5em",
  },
};

const months = [
  "Leden",
  "Únor",
  "Březen",
  "Duben",
  "Květen",
  "Červen",
  "Červenec",
  "Srpen",
  "Září",
  "Říjen",
  "Listopad",
  "Prosinec",
];

function roundAndRound(number, decimalPlaces = 4) {
  const exp = Math.pow(10, decimalPlaces);
  let newNumber = Math.round(number * exp) / exp + "";

  let decimalPointIdx = newNumber.indexOf(".");
  if (decimalPointIdx === -1) {
    decimalPointIdx = newNumber.length;
    newNumber += ",";
  } else {
    newNumber = newNumber.replace(".", ",");
  }
  while (decimalPointIdx + decimalPlaces >= newNumber.length) {
    newNumber += "0";
  }

  return newNumber;
}

class ZZViewPrint extends Component {
  render() {
    const { classes, data } = this.props;

    if (!data) return <></>;

    const celkem_mn = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce((sum, i) => {
      let id = ("0" + i).substr(-2);
      return sum + parseFloat(data["mn" + id]);
    }, 0);

    const celkem_h = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce((sum, i) => {
      let id = ("0" + i).substr(-2);
      return sum + parseInt(data["h" + id]);
    }, 0);

    return (
      <React.Fragment>
        {data && (
          <div className={classes.root}>
            <div className={classes.page}>
              <div className={classes.head1}>
                <h2 className={classes.headerH2}>SOUHRNNÉ INFORMACE O ZDROJÍCH ZNEČIŠŤOVÁNÍ</h2>
                <br />
                <b>
                  <span className={classes.label}>Provozovatel:</span>
                  <span>{data.provozovatel}</span>
                </b>
                <div className={classes.innerFlex}>
                  <div className={classes.flexLeft}>
                    <span className={classes.label}>Ulice, č.p./č.o.:</span>
                    <span>{data.adresa_ulice}</span>
                    <br />
                    <span className={classes.label}>Obec:</span>
                    <span>{data.adresa_obec}</span>
                    <br />
                    <span className={classes.labelLong}>Počet provozoven:</span>
                    <span>1</span>
                  </div>

                  <div>
                    <span className={classes.labelShort}>IČ:</span>
                    <span>{data.ICO}</span>
                    <br />
                    <span className={classes.labelShort}>M.část:</span>
                    <span>{data.adresa_cast}</span>
                    <br />
                    <span className={classes.labelShort}>PSČ:</span>
                    <span>{data.PSC}</span>
                  </div>
                </div>
              </div>

              <div className={classes.head2}>
                <div className={classes.innerFlex}>
                  <div className={classes.flexLeft}>
                    <span className={classes.labelLong}>
                      <b>1. provozovna:</b>
                    </span>
                    <span>{data.provozovna}</span>
                    <br />
                    <span className={classes.labelLong}>Ulice, č.p./č.o.</span>
                    <span>{data.pr_adresa_ulice}</span>
                    <br />
                    <span className={classes.labelLong}>Obec:</span>
                    <span>{data.pr_adresa_obec}</span>
                    <br />
                    <span className={classes.labelLong}>Počet zařízení:</span>
                    <span>1</span>
                  </div>
                  <div>
                    <span className={classes.labelShort}>IČP:</span>
                    <span>{data.pr_ICZ}</span>
                    <br />
                    <span className={classes.labelShort}>M.část:</span>
                    <span>{data.pr_adresa_cast}</span>
                    <br />
                    <span className={classes.labelShort}>PSČ:</span>
                    <span>{data.pr_PSC}</span>
                  </div>
                </div>
              </div>

              <div className={classes.head2}>
                <div className={classes.innerFlex}>
                  <div className={classes.flexLeft}>
                    <span className={classes.labelLong}>
                      <b>1. zařízení:</b>
                    </span>
                    <span>{data.zarizeni}</span>
                    <br />
                    <span className={classes.labelLong}>Tech. specifikace:</span>
                    <span>{data.za_specifikace}</span>
                    <br />
                    <span className={classes.labelLong}>Počet emisí:</span>
                    <span>1</span>
                  </div>
                  <div>
                    <br />
                    <span className={classes.labelLong}>Jm. výkon [MW]:</span>
                    <span>{roundAndRound(data.za_vykon, 3)}</span>
                  </div>
                </div>
              </div>

              <div className={classes.head2}>
                <span className={classes.labelLong}>
                  <b>1. palivo:</b>
                </span>
                <span>zemní plyn</span>
                <br />
                <span className={classes.labelLong}>Spotřeba:</span>
                <span>
                  {roundAndRound(celkem_mn, 3)} tis m<sup>3</sup>
                </span>
                <br />
                <span className={classes.labelLong}>Provozní hodiny:</span>
                <span>{celkem_h}</span>
                <br />
              </div>

              <div className={classes.head2}>
                <div className={classes.innerFlex}>
                  <div className={classes.flexItemGrow}>
                    <span>
                      <br />
                      Znečišťující látka
                    </span>
                    <br />
                    <br />
                    <span className={classes.tableRow}>tuhé znečišťující látky (TLZ)</span>
                    <br />
                    <span className={classes.tableRow}>oxid siřičitý</span>
                    <br />
                    <span className={classes.tableRow}>oxidy dusíku</span>
                    <br />
                    <span className={classes.tableRow}>oxid uhelnatý (CO)</span>
                    <br />
                    <span className={classes.tableRow}>organické látky (OC)</span>
                    <br />
                  </div>

                  <div className={classNames(classes.flexItemGrow, classes.textRight)}>
                    <span>
                      Emise
                      <br />
                      [kg/t, kg/mil m3, kg/mj]
                    </span>
                    <br />
                    <br />
                    <span className={classes.tableRow}>20,0000</span>
                    <br />
                    <span className={classes.tableRow}>2,0000</span>
                    <br />
                    <span className={classes.tableRow}>1920,0000</span>
                    <br />
                    <span className={classes.tableRow}>320,0000</span>
                    <br />
                    <span className={classes.tableRow}>64,0000</span>
                    <br />
                  </div>

                  <div className={classNames(classes.flexItemGrow, classes.textRight)}>
                    <span>
                      <br />
                      Hm.tok [kg/h]
                    </span>
                    <br />
                    <br />
                    <span className={classes.tableRow} />
                    <br />
                    <span className={classes.tableRow} />
                    <br />
                    <span className={classes.tableRow} />
                    <br />
                    <span className={classes.tableRow} />
                    <br />
                    <span className={classes.tableRow} />
                    <br />
                  </div>

                  <div className={classNames(classes.flexItemGrow, classes.textRight)}>
                    <span>
                      <br />
                      Emise celkem [t]
                    </span>
                    <br />
                    <br />
                    <span className={classes.tableRow}>{roundAndRound((celkem_mn * 20) / 1000000)}</span>
                    <br />
                    <span className={classes.tableRow}>{roundAndRound((celkem_mn * 2) / 1000000)}</span>
                    <br />
                    <span className={classes.tableRow}>{roundAndRound((celkem_mn * 1920) / 1000000)}</span>
                    <br />
                    <span className={classes.tableRow}>{roundAndRound((celkem_mn * 320) / 1000000)}</span>
                    <br />
                    <span className={classes.tableRow}>{roundAndRound((celkem_mn * 64) / 1000000)}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.page}>
              <div className={classes.head1}>
                <h2 className={classes.headerH2}>Proměnné měsíční údaje</h2>
                <br />
                <b>
                  <span className={classes.label}>Provozovatel:</span>
                  <span>{data.provozovatel}</span>
                </b>
                <div className={classes.innerFlex}>
                  <div className={classes.flexLeft}>
                    <span className={classes.label}>Provozovna:</span>
                    <span>
                      {data.provozovna}, {data.pr_adresa_ulice ? data.pr_adresa_ulice + ", " : ""}
                      {data.pr_adresa_obec}
                    </span>
                    <br />
                    <span className={classes.label}>Zařízení:</span>
                    <span>{data.zarizeni}</span>
                    <br />
                    <span className={classes.label}>Palivo:</span>
                    <span>Zemní plyn</span>
                    <br />
                    <span className={classes.label}>Qr:</span>
                    <span>
                      34,000 MJ/kg, m<sup>3</sup>
                    </span>
                  </div>

                  <div>
                    <span className={classes.label}>Ev. rok:</span>
                    <span>{data.rok}</span>
                    <br />
                    <span className={classes.label}>Množství m.j.:</span>
                    <span>
                      {roundAndRound(celkem_mn, 3)} tis m<sup>3</sup>
                    </span>
                    <br />
                    <span className={classes.label}>Provoz. hodiny:</span>
                    <span>{celkem_h}</span>
                  </div>
                </div>
              </div>
              <br />
              <div className={classes.head2}>
                <h2 className={classes.headerH2}>Období pro výpočet: 1. - 12. měsíc</h2>

                {[[0, 1, 2, 3, 4, 5, 6], [0, 7, 8, 9, 10, 11, 12]].map((arr, idx) => (
                  <div className={classNames(classes.innerFlex, classes.topMargin)} key={idx}>
                    {arr.map((i) => {
                      let id = ("0" + i).substr(-2);
                      return (
                        <React.Fragment key={id}>
                          {i === 0 ? (
                            <div className={classes.table2Col1}>
                              <div>
                                &nbsp;
                                <br />
                                &nbsp;
                              </div>
                              <div>Mnozštví m.j.</div>
                              <div>Provozní hodiny</div>
                            </div>
                          ) : (
                            <div className={classes.table2Col2}>
                              <div className={classes.textCenter}>
                                {i + "."}
                                <br />
                                {months[i - 1]}
                              </div>
                              <div className={classes.textCenter}>{roundAndRound(data["mn" + id], 3)}</div>
                              <div className={classes.textCenter}>{data["h" + id]}</div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  User: getUserNameFromState(state),
});

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(ZZViewPrint));
