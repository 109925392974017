import { LOGGING_ACTION_TYPES } from "./Logging.actions.js";
import { DATA_STATE } from "../constants.js";
import { getFailStateForReducer } from "../../../utils/defaults.js";

export const getData = (state) => state.data;
export const getListData = (state) => state.dataList;
export const getDataState = (state) => state.dataState;
export const getDataError = (state) => {
  return { errorType: state.errorType, errors: state.errors, errorWhen: state.errorWhen };
};

export const getErrors = (state) => state.errors;
export const getLastMessage = (state) => {
  if (state.errors.length > 0) {
    return state.errors[state.errors.length - 1];
  } else {
    return {};
  }
};

export const getPagination = (state) => state.pagination;

const INITIAL_STATE = {
  errors: [],
  dataState: DATA_STATE.NOT_INITIALIZED,
  pagination: {
    page: 0,
    sort: "timestamp",
    sortOrder: "desc",
    _sort: ["-timestamp"],
  },
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGGING_ACTION_TYPES.LOG_ERROR:
      return {
        ...state,
        errors: state.errors.concat([action.data]),
      };

    case LOGGING_ACTION_TYPES.FETCHING_LIST:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case LOGGING_ACTION_TYPES.FETCH_LIST_FAILED:
      return getFailStateForReducer(state, action, LOGGING_ACTION_TYPES.FETCHING_LIST);

    case LOGGING_ACTION_TYPES.FETCH_LIST_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.INITIALIZED,
        dataList: action.data,
      };
    case LOGGING_ACTION_TYPES.FETCHING:
      return {
        ...state,
        dataState: DATA_STATE.IN_PROGRESS,
      };
    case LOGGING_ACTION_TYPES.FETCH_FAILED:
      return getFailStateForReducer(state, action, LOGGING_ACTION_TYPES.FETCHING);

    case LOGGING_ACTION_TYPES.FETCH_SUCCEEDED:
      return {
        ...state,
        dataState: DATA_STATE.INITIALIZED,
        data: action.data,
      };

    case LOGGING_ACTION_TYPES.PAGINATION:
      let newPagination = {
        page: action.page !== undefined ? action.page : (state.pagination || {}).page || 0,
        searchTerm: action.searchTerm !== undefined ? action.searchTerm : (state.pagination || {}).searchTerm,
        sort: action.sort !== undefined ? action.sort : (state.pagination || {}).sort,
        sortOrder: action.sortOrder !== undefined ? action.sortOrder : (state.pagination || {}).sortOrder,
      };
      newPagination._sort = (newPagination.sortOrder === "desc" ? "-" : "") + newPagination.sort;

      return {
        ...state,
        pagination: newPagination,
      };

    default:
      return state;
  }
}
