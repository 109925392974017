import axios from "axios";

export function logError(data) {
  return axios.post("logs/index.php", data);
}

export function getPage(params) {
  return axios.get("logs/index.php", { params: params });
}

export function getOne(id, params) {
  params = params || {};
  params.ID = id;
  return axios.get("logs/index.php", { params: params });
}
