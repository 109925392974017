import axios from "axios";

export function login(userName, userPasswd) {
  const params = new URLSearchParams();
  params.append("userName", userName);
  params.append("userPasswd", userPasswd);
  return axios.post("login.php", params);
}

export function logout() {
  return axios.delete("login.php");
}

export function getUserName() {
  return axios.get("login.php");
}
