import React, { Component } from "react";

import FooterImage from "./footer1.png";

class FooterValch extends Component {
  render() {
    return (
      <img
        src={FooterImage}
        alt="Podpis"
        style={{
          height: "6.5cm",
        }}
      />
    );
  }
}

export default FooterValch;
