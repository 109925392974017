import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DeleteConfirm from "../../components/Dialogs/DeleteConfirm.js";

import { withStyles } from "@material-ui/core/styles";
import { Paper, CircularProgress, LinearProgress, Typography, Grid, TextField, Button, Zoom } from "@material-ui/core";
import { Save as SaveIcon, Check as CheckIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { getOne, update, remove, savePagination } from "../../services/redux/ZZList/ZZList.thunks.js";
import {
  getOneZZFromState,
  getOneZZUpdatedFromState,
  getOneZZStateFromState,
  getAllZZPaginationFromState,
  getUsersFromState,
} from "../../services/redux/rootReducer.js";
import { DATA_STATE } from "../../services/redux/constants.js";
import { setAppBarForDetail } from "../../utils/defaults.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  progress: {
    marginRight: -theme.spacing.unit * 2,
    marginLeft: -theme.spacing.unit * 2,
    marginBottom: "-5px",
  },
  gridCell: {},
  dateField: {
    width: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
});

class ZZDetail extends Component {
  state = {
    data: {
      rok: new Date().getFullYear() - 1,
      provozovatel: "",
      adresa_obec: "",
      adresa_ulice: "",
      adresa_cast: "",
      PSC: "",
      ICO: "",
      provozovna: "kotelna",
      pr_adresa_obec: "",
      pr_adresa_ulice: "",
      pr_adresa_cast: "",
      pr_PSC: "",
      pr_ICZ: "",
      zarizeni: "",
      za_specifikace: "",
      za_vykon: 0,
      mn01: 0,
      mn02: 0,
      mn03: 0,
      mn04: 0,
      mn05: 0,
      mn06: 0,
      mn07: 0,
      mn08: 0,
      mn09: 0,
      mn10: 0,
      mn11: 0,
      mn12: 0,
      h01: 0,
      h02: 0,
      h03: 0,
      h04: 0,
      h05: 0,
      h06: 0,
      h07: 0,
      h08: 0,
      h09: 0,
      h10: 0,
      h11: 0,
      h12: 0,
    },
    saved: true,
    removeConfirmOpen: false,
  };

  componentDidMount() {
    this.props.match.params.id !== "new" && this.props.getOne(this.props.match.params.id);

    setAppBarForDetail(this.props, "/zz/list", {
      icon: <DeleteIcon />,
      label: "Smaž",
      onClick: this.handleRemoveClick,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.OneDataState === DATA_STATE.REMOVE_SUCCEEDED) {
      this.props.history.push("/zz/list");
    }
    // set data to state after fetch
    if (this.props.OneData && this.props.OneData !== prevProps.OneData) {
      let data = this.props.OneData;
      this.setState({ data: data });
    }
    // set saved flag after save and reload after new has been added
    if (
      this.props.OneDataState === DATA_STATE.UPDATE_SUCCEEDED &&
      prevProps.OneDataState !== DATA_STATE.UPDATE_SUCCEEDED
    ) {
      this.setState({ saved: true });

      if (this.props.askWhenNavigatingAway && this.props.setAskWhenNavigatingAway) {
        this.props.setAskWhenNavigatingAway(false);
      }
      if (this.props.match.params.id === "new" && this.props.UpdatedData) {
        this.props.history.push(`/zz/detail/${this.props.UpdatedData.newId}`);
        this.props.getOne(this.props.UpdatedData.newId);
      }
    }
  }

  handleChange = (name) => (event) => {
    let newData = this.state.data;
    newData[name] = event.target.value;
    this.setState({ data: newData, saved: false });

    if (this.props.setAskWhenNavigatingAway) {
      this.props.setAskWhenNavigatingAway(true);
    }
  };

  save = () => {
    if (this.state.saved) {
      this.props.history.push("/zz/view/" + this.props.match.params.id);
    } else if (this.props.OneDataState !== DATA_STATE.UPDATE_IN_PROGRESS) {
      this.props.update(this.props.match.params.id, this.state.data);
    }
  };

  handleRemoveClick = () => {
    this.setState({ removeConfirmOpen: true });
  };
  remove = (shouldRemove) => {
    if (shouldRemove) {
      this.props.remove(this.props.match.params.id);
    }
    this.setState({ removeConfirmOpen: false });
  };

  render() {
    const { classes, theme } = this.props;
    const data = this.props.OneData;
    const ID = this.props.match.params.id;

    return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom>
          {data && ID !== "new" && `${data.adresa_ulice}, ${data.adresa_obec}`}
          {ID === "new" && "Nový"}
        </Typography>
        <Paper className={classes.root}>
          {this.props.OneDataState === DATA_STATE.IN_PROGRESS && <LinearProgress className={classes.progress} />}
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container direction="row">
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="rok"
                  label="Rok"
                  type="number"
                  margin="normal"
                  value={this.state.data.rok}
                  onChange={this.handleChange("rok")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "1" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="provozovatel"
                  label="Provozovatel"
                  margin="normal"
                  fullWidth
                  value={this.state.data.provozovatel}
                  onChange={this.handleChange("provozovatel")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "2" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="adresa_ulice"
                  label="Ulice"
                  margin="normal"
                  fullWidth
                  value={this.state.data.adresa_ulice}
                  onChange={this.handleChange("adresa_ulice")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "3" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="adresa_obec"
                  label="Obec"
                  margin="normal"
                  fullWidth
                  value={this.state.data.adresa_obec}
                  onChange={this.handleChange("adresa_obec")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "4" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="adresa_cast"
                  label="M. Část"
                  margin="normal"
                  fullWidth
                  value={this.state.data.adresa_cast}
                  onChange={this.handleChange("adresa_cast")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "5" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="PSC"
                  label="PSČ"
                  margin="normal"
                  fullWidth
                  value={this.state.data.PSC}
                  onChange={this.handleChange("PSC")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "6" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="ICO"
                  label="IČ"
                  margin="normal"
                  fullWidth
                  value={this.state.data.ICO}
                  onChange={this.handleChange("ICO")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "7" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                &nbsp;
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="provozovna"
                  label="Provozovna"
                  margin="normal"
                  fullWidth
                  value={this.state.data.provozovna}
                  onChange={this.handleChange("provozovna")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "8" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="pr_adresa_ulice"
                  label="Ulice"
                  margin="normal"
                  fullWidth
                  value={this.state.data.pr_adresa_ulice}
                  onChange={this.handleChange("pr_adresa_ulice")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "9" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="pr_adresa_obec"
                  label="Obec"
                  margin="normal"
                  fullWidth
                  value={this.state.data.pr_adresa_obec}
                  onChange={this.handleChange("pr_adresa_obec")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "10" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="pr_adresa_cast"
                  label="M. Část"
                  margin="normal"
                  fullWidth
                  value={this.state.data.pr_adresa_cast}
                  onChange={this.handleChange("pr_adresa_cast")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "11" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="pr_PSC"
                  label="PSČ"
                  margin="normal"
                  fullWidth
                  value={this.state.data.pr_PSC}
                  onChange={this.handleChange("pr_PSC")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "12" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="pr_ICZ"
                  label="IČP"
                  margin="normal"
                  fullWidth
                  value={this.state.data.pr_ICZ}
                  onChange={this.handleChange("pr_ICZ")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "13" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                &nbsp;
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="zarizeni"
                  label="Zařízení"
                  margin="normal"
                  fullWidth
                  value={this.state.data.zarizeni}
                  onChange={this.handleChange("zarizeni")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "14" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="za_specifikace"
                  label="Tech. specifikace"
                  margin="normal"
                  fullWidth
                  value={this.state.data.za_specifikace}
                  onChange={this.handleChange("za_specifikace")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "15" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                <TextField
                  id="za_vykon"
                  label="Jm. výkon"
                  type="number"
                  margin="normal"
                  fullWidth
                  value={this.state.data.za_vykon}
                  onChange={this.handleChange("za_vykon")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ tabIndex: "16" }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridCell}>
                &nbsp;
              </Grid>
              {["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((i, idx) => {
                let date = new Date();
                date.setMonth(idx);
                const monthName = date.toLocaleString("default", { month: "long" });
                return (
                  <React.Fragment key={i}>
                    <Grid item xs={12} sm={6} className={classes.gridCell} key={"mn" + i}>
                      <TextField
                        id={"mn" + i}
                        label={"Množství m.j. " + (idx + 1) + " " + monthName}
                        margin="normal"
                        type="number"
                        className={classes.dateField}
                        value={this.state.data["mn" + i]}
                        onChange={this.handleChange("mn" + i)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ tabIndex: 17 + idx }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.gridCell} key={"h" + i}>
                      <TextField
                        id={"h" + i}
                        label={"Provozní hodiny " + (idx + 1) + " " + monthName}
                        margin="normal"
                        type="number"
                        className={classes.dateField}
                        value={this.state.data["h" + i]}
                        onChange={this.handleChange("h" + i)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ tabIndex: 17 + 12 + idx }}
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}
              <Grid item xs={12} className={classes.gridCell}>
                &nbsp;
              </Grid>
              <Grid item xs={6} className={classes.gridCell}>
                {Math.round(
                  ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].reduce((sum, i) => {
                    return sum + parseFloat(this.state.data["mn" + i]);
                  }, 0) * 1000
                ) / 1000}
              </Grid>
              <Grid item xs={6} className={classes.gridCell}>
                {["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].reduce((sum, i) => {
                  return sum + parseInt(this.state.data["h" + i]);
                }, 0)}
              </Grid>
            </Grid>
          </form>
        </Paper>

        <div className={classes.fab}>
          <Zoom in={true} timeout={theme.transitions.duration.enteringScreen} unmountOnExit>
            <Button variant="fab" color="secondary" onClick={this.save}>
              {this.state.saved ? <CheckIcon /> : <SaveIcon />}
            </Button>
          </Zoom>
          {this.props.OneDataState === DATA_STATE.UPDATE_IN_PROGRESS && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
        <DeleteConfirm
          open={this.state.removeConfirmOpen}
          onClose={this.remove}
          label="Opravdu chcete smazat tuto zprávu?"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  OneData: getOneZZFromState(state),
  UpdatedData: getOneZZUpdatedFromState(state),
  OneDataState: getOneZZStateFromState(state),
  Pagination: getAllZZPaginationFromState(state),
  Users: getUsersFromState(state),
});

const actionCreators = {
  getOne,
  update,
  remove,
  savePagination,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(withStyles(styles, { withTheme: true })(ZZDetail)));
